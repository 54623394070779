import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ShareService} from '../../../base/share.service';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.css']
})
export class PasswordComponent {

    @Input() form: FormGroup;
    showUnlockSecret = false;

    constructor(private shareService: ShareService) {}

    generateRandomPassword() {
        this.form.get('password').setValue(Utils.generateRandomString(8));
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    toggleUnlockSecret(event: MouseEvent) {
        event.preventDefault();
        this.showUnlockSecret = !this.showUnlockSecret;
    }

    updateUnlockSecret() {
        const uuid = this.form.get('uuid').value;
        this.shareService.regenerateUnlockSecret(uuid).subscribe(
            response => {
                this.shareService.get(uuid).subscribe(
                    share => {
                        this.form.get('unlockSecret').setValue(share.unlockSecret);
                    }
                );
            }
        );
    }
}
