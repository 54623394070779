import {Component, Input} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService, SamlDomain} from '../../../../app.service';
import {ConfluenceService} from '../../../../confluence.service';
import {LoaderService} from '../../../../loader.service';
import CustomValidators from '../../../../utils/custom-validators';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'app-saml-domains',
    templateUrl: './saml-domains.component.html',
    styleUrls: ['./saml-domains.component.css']
})
export class SamlDomainsComponent {
    form: FormGroup = this.fb.group({
        domain: ['', [Validators.required, CustomValidators.domain]]
    });

    @Input() domains: SamlDomain[];
    @Input() parentForm: Form;

    constructor(private fb: FormBuilder,
                private confluence: ConfluenceService,
                private loader: LoaderService,
                private app: AppService) {
    }

    addDomain(button: HTMLButtonElement): void {
        if (this.form.valid) {
            button.disabled = true;
            this.app.addSamlDomain(this.form.get('domain').value).subscribe((domains) => {
                this.domains = domains;
                button.disabled = false;
                this.form.reset();
            }, () => {
                button.disabled = false;
            });
        }
    }

    verify(uuid: string) {
        this.app.verifySamlDomain(uuid).subscribe((domains) => {
            this.domains = domains;
        });
    }

    confirmDeletion(uuid: string) {
        this.confluence.showDialog({
                key: 'confirm-delete',
                customData: {
                    title: 'Delete selected Domain?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);
                    this.app.deleteSamlDomain(uuid)
                        .subscribe((domains) => {
                            this.domains = domains;
                            document.getElementById(uuid).remove();
                            this.loader.fullscreen(false);
                        });
                }
            }
        );
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    isDomainEmpty(): boolean {
        return !this.form.get('domain').value;
    }
}
