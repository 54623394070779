import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-group-form',
    templateUrl: './group-form.component.html',
    styleUrls: ['./group-form.component.css']
})
export class GroupFormComponent implements OnInit {
    form: FormGroup;
    @Input() showEmptyCheckbox = false;
    @Input() initialGroupName: string | null = null;
    @Input() isEditing = false;
    @Output() save = new EventEmitter<{ newGroupName: string }>();
    @Output() cancel = new EventEmitter();

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            newGroupName: ['', [Validators.maxLength(80)]],
            allowEmptyGroupNameCheckbox: [false]
        });
    }

    ngOnInit(): void {
        if (this.initialGroupName) {
            this.form.patchValue({
                newGroupName: this.initialGroupName
            });
        }

        this.form.get('allowEmptyGroupNameCheckbox')?.valueChanges.subscribe(isChecked => {
            this.toggleGroupNameValidation(isChecked);
        });

        this.toggleGroupNameValidation(this.form.get('allowEmptyGroupNameCheckbox')?.value);
    }

    toggleGroupNameValidation(isChecked: boolean) {
        const control = this.form.get('newGroupName');
        if (isChecked) {
            control?.setValidators([Validators.maxLength(80)]);
        } else {
            control?.setValidators([Validators.required, Validators.maxLength(80)]);
        }
        control?.updateValueAndValidity();
    }

    isInvalid(controlName: string): boolean {
        const control = this.form.get(controlName);
        return this.isTouchedOrDirty(control) && control?.invalid;
    }

    private isTouchedOrDirty(control: AbstractControl | null): boolean {
        return control?.dirty || control?.touched;
    }

    saveGroup() {
        if (this.form.valid) {
            this.save.emit(this.form.value);
            this.form.reset();
        }
    }

    cancelGroup() {
        this.cancel.emit();
        this.form.reset();
    }
}
