import {Claims} from './claims';

export async function initJwt(production: boolean = true) {

    if (window.getToken) {
        return;
    }

    let jwtToken;
    try {
        jwtToken = await fetchJwt();
    } catch (e) {
        jwtToken = {token: null};
    }

    if (!production && !jwtToken.token) {
        const sessionToken = sessionStorage.getItem('jwt-token');
        jwtToken = sessionToken != null ? JSON.parse(sessionToken) : null;
    }

    if (!production) {
        if (jwtToken.token) {
            sessionStorage.setItem('jwt-token', JSON.stringify(jwtToken));
        }
    }

    const claims = parseClaims(jwtToken.token);

    window.getToken = () => {
        return {token: jwtToken.token, features: jwtToken.features, claims};
    };

    async function fetchJwt() {
        const customData = await getCustomData();
        if (customData && customData.jwtToken) {
            return {token: customData.jwtToken, features: customData.features};
        }

        const startUrl = window.location.href;
        const body = JSON.stringify({url: startUrl});

        const response = await fetch('/api/atlassian-connect-app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body
        });

        return await response.json();
    }

    async function renewJwt(oldJwt) {
        const body = JSON.stringify({jwt: oldJwt});

        const response = await fetch('/api/atlassianConnectToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body
        });

        return await response.json();
    }

    async function getCustomData(): Promise<any> {
        return new Promise((resolve, reject) => {
            AP.dialog.getCustomData(data => {
                resolve(data);
            });
        });
    }

    function parseClaims(token): Claims {
        return parseJwt(token);

        function parseJwt(str) {
            try {
                const base64Url = str.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map((c) => {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        })
                        .join('')
                );

                return JSON.parse(jsonPayload);
            } catch (e) {
                return null;
            }
        }
    }
}
