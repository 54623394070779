import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">\n' +
    '  <defs>\n' +
    '    <style>\n' +
    '      .cls-1 {\n' +
    '        fill-rule: evenodd;\n' +
    '      }\n' +
    '    </style>\n' +
    '  </defs>\n' +
    // tslint:disable-next-line:max-line-length
    '     <path class="cls-1" d="M38.55951,11.92405v-1.9566c-.01062-1.06233-.87484-1.9179-1.93722-1.91785-1.0771,0-1.93722.86013-1.93722,1.91785v1.9566h-1.93722c-1.0699,0-1.93722.86732-1.93722,1.93722s.86732,1.93722,1.93722,1.93722h1.93722v1.9566c.01062,1.06233.87484,1.9179,1.93722,1.91785,1.0771,0,1.93722-.86013,1.93722-1.91785v-1.9566h1.93722c1.0699,0,1.93722-.86732,1.93722-1.93722s-.86733-1.93722-1.93722-1.93722h-1.93722Z" fill="currentColor" fill-rule="evenodd"/>\n' +
    '  <g>\n' +
    // tslint:disable-next-line:max-line-length
    '    <path class="cls-1" d="M21.79648,39.40487l1.63738-1.60767c.69342-.67125.7114-1.77754.04014-2.47097-.01316-.0136-.02655-.02698-.04014-.04015-.71061-.69301-1.84419-.69301-2.5548,0l-1.72126,1.68805c-1.6898,1.6601-4.44207,1.98338-6.29439.50327-1.92463-1.51821-2.2541-4.30918-.73589-6.2338.11783-.14937.24514-.29102.38115-.42405l5.0729-4.98378c1.79613-1.75276,4.66252-1.75276,6.45865,0l1.93969,1.90474,2.5548-2.51286-1.9362-1.90299c-3.18389-3.12448-8.39135-3.12448-11.57174,0l-5.0764,4.98378c-3.13868,3.08209-3.18455,8.12502-.10245,11.2637.22848.23268.47104.4511.7263.65404,3.30796,2.65091,8.20437,2.1424,11.22225-.82131" fill="currentColor" fill-rule="evenodd"/>\n' +
    // tslint:disable-next-line:max-line-length
    '    <path class="cls-1" d="M36.28273,23.66807c-.69528-.69531-1.82102-.70032-2.52246-.01122l-4.75684,4.70224c-1.79611,1.7528-4.66241,1.7528-6.45851,0l-1.93967-1.90468-2.55486,2.51284,1.93625,1.90298c3.18062,3.12596,8.38659,3.1273,11.56883.00299l4.71555-4.65984c.71065-.69771.71591-1.84106.01171-2.54529h0Z" fill="currentColor" fill-rule="evenodd"/>\n' +
    // tslint:disable-next-line:max-line-length
    '    <path class="cls-1" d="M23.17107,18.9124c.69644.6861,1.81438.68705,2.51199.00213l1.08873-1.0559c.71065-.69771.71591-1.84106.01171-2.54529h0c-.69528-.69531-1.82102-.70032-2.52246-.01123l-1.08819,1.05645c-.71388.70132-.71468,1.85151-.00178,2.55383h0Z" fill="currentColor" fill-rule="evenodd"/>\n' +
    '  </g>' +
    '</svg>';

@Directive({
    selector: '[appAddLinkIcon]'
})
export class AddLinkIconDirective implements OnInit {
    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
