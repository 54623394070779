import {PeriodEnum} from './period';
import {Config} from './config';
import {SelectedUsersConfig} from './selected-users-config';
import {FilterCriteria} from '../components/app-table.component';

export class Share {
    id?: number;
    contentId?: number;
    spaceId?: number;
    spaceKey?: string;
    uuid?: string;
    title?: string;
    name?: string;
    description?: string;
    status?: 'DISABLED' | 'ACTIVE' | 'EXPIRED';
    password?: string;
    expiration?: string;
    samlLoginRequired: boolean;
    unlockSecret: string;

    namedShareLink?: boolean;
    linkName?: string;

    editContent?: boolean;

    showComments?: boolean;
    allowAddComment?: boolean;
    showAttachments?: boolean;
    allowAddAttachment?: boolean;

    allowShareChildPages?: boolean;
    restrictChildPagesWithLabels?: string;
    restrictChildPagesWithoutLabels?: string;

    showLabels?: boolean;
    allowRemoveUnsupportedMacros?: boolean;
    showMacrosPlaceholder?: boolean;

    showPageHistory?: boolean;
    allowPageHistoryDelete?: boolean;
    allowPageHistoryRestore?: boolean;

    showPageStatus?: boolean;
    showPageTitle?: boolean;
    showPageAuthor?: boolean;
    showUpdateDate?: boolean;
    subscribeButtonPlaceholder?: string;
    showAuthorAvatar?: boolean;

    selectedUsersConfig?: SelectedUsersConfig;

    created?: string;
    createdBy?: string;
    updated?: string;
    updatedBy?: string;
    opened?: number;
    spaceEnabled: boolean;
    pageName?: string;
    mailSendOption: string;

    passwordRequired?: boolean;
    expirationRequired?: boolean;
    expirationUnit?: PeriodEnum;
    expirationCount?: number;

    contentUrl: string;

    parent: Config;
}

export class ViewShare {
    id?: number;
    contentId?: number;
    spaceId?: number;
    spaceKey?: string;
    uuid?: string;
    name?: string;
    description?: string;
    status?: 'DISABLED' | 'ACTIVE' | 'EXPIRED';
    hasPassword: boolean;
    password?: string;
    samlLoginRequired: boolean;
    unlockSecret: string;

    namedShareLink?: boolean;
    linkName?: string;

    expirationEnabled: boolean;
    expirationType: 'DATE_TIME' | 'SELECTABLE';
    expirationPeriod: { count: number, unit: PeriodEnum };
    expirationDate: any;

    editContent?: boolean;

    showComments?: boolean;
    allowAddComment?: boolean;
    showAttachments?: boolean;
    allowAddAttachment?: boolean;

    allowShareChildPages?: boolean;
    restrictChildPagesWithLabels?: string;
    restrictChildPagesWithoutLabels?: string;

    showLabels?: boolean;
    allowRemoveUnsupportedMacros?: boolean;
    showMacrosPlaceholder?: boolean;

    showPageHistory?: boolean;
    allowPageHistoryDelete?: boolean;
    allowPageHistoryRestore?: boolean;

    showPageStatus?: boolean;
    showPageTitle?: boolean;
    showPageAuthor?: boolean;
    showUpdateDate?: boolean;
    subscribeButtonPlaceholder?: string;
    showAuthorAvatar?: boolean;

    allowSelectedUsers?: boolean;
    allowSelectedUsersNotification?: boolean;
    selectedUsers?: [];
    selectedUsersEmailDomains?: string;

    selectedUsersConfig?: SelectedUsersConfig;

    created?: string;
    createdBy?: string;
    updated?: string;
    updatedBy?: string;
    opened?: number;
    spaceEnabled: boolean;

    mailSendOption: string;

    parent: Config;
}

export class ShareFilterCriteria implements FilterCriteria {
    spaces?: string[];
    contents?: string[];
    status?: string;
    createdBy?: string[];
    updatedBy?: string[];
    selectedUsersQuery?: string;
    userId?: string;
    searchByUuid?: string;
    searchByLinkName?: string;
}
