import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24">' +
    '   <g fill="currentColor" fill-rule="evenodd">' +
    '       <rect x="10" y="15" width="8" height="2" rx="1"/>' +
    '       <rect x="6" y="15" width="2" height="2" rx="1"/>' +
    '       <rect x="10" y="11" width="8" height="2" rx="1"/>' +
    '       <rect x="6" y="11" width="2" height="2" rx="1"/>' +
    '       <rect x="10" y="7" width="8" height="2" rx="1"/>' +
    '       <rect x="6" y="7" width="2" height="2" rx="1"/>' +
    '   </g>' +
    '</svg>';

@Directive({
    selector: '[appBulletListIcon]'
})
export class BulletListIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
