import {Recipient} from './recipient';

export class EmailContent {
    uuid: string;
    recipients: Recipient[];
    subject: string;
    content: string;
}

export class EmailSendResponse {
    status: SendStatus;
}

export enum SendStatus {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    CONTENT_EXPIRED = 'CONTENT_EXPIRED',
    SPACE_DISABLED = 'SPACE_DISABLED'
}
