import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchFunction} from '../../components/better-select/better-select.component';
import {ConfluenceService} from '../../confluence.service';
import {map, mergeAll} from 'rxjs/operators';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-associated-spaces',
    templateUrl: './associated-spaces.component.html',
    styleUrls: ['./associated-spaces.component.css']
})
export class AssociatedSpacesComponent implements OnInit {
    editAssociatedSpaces = false;
    @Input() form: FormGroup;
    @Input() spacePickForm: FormGroup = new FormGroup({
        spacePick: new FormControl('')
    });

    spacePick: SearchFunction;

    constructor(private confluence: ConfluenceService) {
        this.spacePick = this.spacePickFoo.bind(this);
    }

    ngOnInit(): void {
    }

    spacePickFoo: SearchFunction = (term) => {
        return this.confluence.searchForSpaces(term).pipe(
            map(results => {
                const keys = results.map(key => key.key);
                return this.confluence.getSpacesByKey(keys).pipe(
                    map(space => {
                        const mappedResults = space.map(s => ({id: s.id, text: s.name}));
                        return {
                            results: mappedResults,
                            pagination: {more: false}
                        };
                    })
                );
            }),
            mergeAll()
        );
    }

    add() {
        const space = this.spacePickForm.get('spacePick');
        const value = space.value;
        if (Array.isArray(value)) {
            const existing = this.associatedSpacesValue;
            const cache = {};
            for (const el of existing) {
                cache[el.id] = el;
            }

            const newValues = existing;
            for (const val of value) {
                if (!cache[val.id]) {
                    newValues.push({id: val.id, name: val.text});
                }
            }
            this.associatedSpacesValue = newValues;
            space.setValue([]);
        }
    }

    hasElements(value: any) {
        return Utils.isNotEmptyArray(value);
    }

    delete(toDelete: any) {
        const existing = this.associatedSpacesValue;
        const newValues = [];
        for (const el of existing) {
            if (el.id !== toDelete.id) {
                newValues.push(el);
            }
        }
        this.associatedSpacesValue = newValues;
    }

    get associatedSpacesControl() {
        return this.form.get('associatedSpaces');
    }

    get associatedSpacesValue() {
        return this.associatedSpacesControl.value;
    }

    set associatedSpacesValue(val: any) {
        this.associatedSpacesControl.setValue(val);
    }
}
