import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Utils from '../utils/utils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfluenceService} from '../confluence.service';

interface GlobalViewComponentConfig {
    title: string;
    isLite: boolean;
    isAppsView: boolean;
    isAdminView: boolean;
}

@Component({
    selector: 'app-global-view',
    templateUrl: './global-view.component.html',
    styleUrls: ['./global-view.component.css']
})
export class GlobalViewComponent implements OnInit {

    private title = 'External Share for Confluence';

    config$: Observable<GlobalViewComponentConfig>;

    constructor(private route: ActivatedRoute,
                private confluence: ConfluenceService) {
    }

    ngOnInit() {
        const input = this.route.queryParamMap;

        this.config$ = input.pipe(map((queryParam) => {
            const isLite = Utils.isLiteApp(queryParam);
            const title = isLite ? this.title + ' - Lite' : this.title;
            const isAppsView = queryParam.get('appsView') === 'true' || false;
            const isAdminView = queryParam.get('user_is_admin') === 'true' || false;

            return {
                isLite,
                title,
                isAppsView,
                isAdminView
            };
        }));

        this.confluence.resize('es-expand');
    }
}
