import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestrictionsResolver implements Resolve<boolean> {

    constructor(private http: HttpClient) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return of(route.paramMap).pipe(
            map(it => {
                return {contentId: it.get('contentId')};
            }),
            switchMap(({contentId}) => {
                if (environment.server) {
                    return of(false);
                }
                return this.http.get<boolean>(`/api/share/restriction/${contentId}`);
            })
        );
    }
}
