import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PeriodEnum, PERIODS_FOR_SELECT} from '../../base/period';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface PeriodValue {
    unit: PeriodEnum;
    count: number;
}

@Component({
    selector: 'app-period',
    templateUrl: './period.component.html',
    styleUrls: ['./period.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PeriodComponent),
            multi: true
        },
    ]
})
export class PeriodComponent implements OnInit, ControlValueAccessor {

    periods = PERIODS_FOR_SELECT;
    @Input() disabled = false;

    @ViewChild('countElement') countElement: ElementRef;

    public value: PeriodValue = {
        unit: PeriodEnum.DAY,
        count: 1
    };

    /**
     * @description
     * The registered callback function called when a change event occurs on the input element.
     */
    onChange = (_: any) => {
    }

    /**
     * @description
     * The registered callback function called when a blur event occurs on the input element.
     */
    onTouched = ($event) => {
    }

    ngOnInit() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onCountChange(event: Event) {
        const count = (event.target as HTMLInputElement).value;
        let countValue = typeof count === 'string' ? parseInt(count, 10) : count;
        if (isNaN(countValue) || countValue < 1) {
            countValue = 1;
        } else if (countValue > 999) {
            countValue = 999;
        }
        this.countElement.nativeElement.value = countValue;

        this.value = Object.assign({}, this.value, {count: countValue});
        this.onChange(this.value);
    }

    onPeriodChange(event: any) {
        this.value = Object.assign({}, this.value, {unit: event});
        this.onChange(this.value);
    }
}
