import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import CustomValidators from '../../utils/custom-validators';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-application-logs',
    templateUrl: './application-logs.component.html',
    styleUrls: ['./application-logs.component.css']
})
export class ApplicationLogsComponent {

    form = new FormGroup({
        fromDays: new FormControl(1, [Validators.required, Validators.min(1), CustomValidators.integer])
    });

    constructor() {
    }

    get fromDays(): any {
        return this.form.get('fromDays');
    }

    get href(): string {
        return environment.host + `/rest/external-share/1.0/report-error?fromDays=${this.form.get('fromDays').value}`;
    }
}
