import {isObservable, Observable, of, zip} from 'rxjs';
import {map} from 'rxjs/operators';

/*
* zipIntoObject(of({a: 1, b: 2}), of({c: 3}))
* -> result: {a: 1, b: 2, c: 3}
*/
export function zipIntoObject(...arr: any[]) {
    const observables = arr.map(it => isObservable(it) ? it : of(it));
    return zip(...observables)
        .pipe(map(arr2 => {
            return Object.assign({}, ...arr2);
        }));
}

export function namedZip<T>(obj: object): Observable<T> {
    return zip(...Object.entries(obj).map(
        ([key, value]) => value.pipe(named(key))
    )).pipe(map(arr => {
        return Object.assign({}, ...arr);
    })) as Observable<any>;
}

export function named(key: string) {
    return <T>(source: Observable<T>) =>
        source.pipe(map(it => {
                return {[key]: it};
            })
        );
}
