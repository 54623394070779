import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Config} from '../../share-form-config';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-saml',
    templateUrl: './saml.component.html',
    styleUrls: ['./saml.component.css']
})
export class SamlComponent {

    @Input() form: FormGroup;
    @Input() config: Config;

    constructor() {
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
