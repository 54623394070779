import {AppState} from '../base/share.service';
import {ParamMap} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import Utils from './utils';
import PermissionUtils from './permission-utils';

export default class AppUtils {

    static getAppState(params: ParamMap): Observable<AppState> {
        const isLite = Utils.isLiteApp(params);
        const lic = environment.production ? (params.get('lic') === 'active' ? 'active' : 'none') : 'active';
        const permissions = PermissionUtils.getPermissionsObj(params);
        return of({
            isLite,
            lic,
            permissions,
            hasCreatePermission: permissions.canCreate,
            hasEditPermission: permissions.canEdit,
            hasSendEmailPermission: permissions.canSendEmail,
            hasDeletePermission: permissions.canDelete
        });
    }
}
