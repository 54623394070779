import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {merge, Observable, of, Subscription} from 'rxjs';
import {AppState} from '../../base/share.service';
import {ActivatedRoute} from '@angular/router';
import {LoaderService} from '../../loader.service';
import {ServerDataSource} from '../../components/app-table.component';
import {AppService} from '../../app.service';
import {ConfluenceService} from '../../confluence.service';
import {GlobalConfig, GlobalConfigView, GlobalConfigViewFilterCriteria} from '../../base/config';
import {HttpHeaders} from '@angular/common/http';
import Utils from '../../utils/utils';
import AppUtils from '../../utils/app-utils';

@Component({
    selector: 'app-space-schema-settings',
    templateUrl: './space-schema-settings.component.html',
    styleUrls: ['./space-schema-settings.component.css']
})
export class SpaceSchemaSettingsComponent implements OnInit, OnDestroy {

    private refreshEventName = 'config-update';
    private subscription: Subscription;

    config$: Observable<AppState>;
    deleteSelected = false;

    moreThanOneItemIsSelected = false;

    users = new Map();

    constructor(private route: ActivatedRoute,
                private confluence: ConfluenceService,
                private loader: LoaderService,
                private source: ServerDataSource<GlobalConfigView, GlobalConfigViewFilterCriteria>,
                private app: AppService) {
    }

    ngOnInit(): void {
        const refresh = this.confluence.observeEvent(this.refreshEventName);

        refresh.subscribe(() => {
            this.source.refresh();
        });

        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(() => Utils.combineParams(this.route.parent)),
            switchMap((params) => AppUtils.getAppState(params)),
            tap((params) => {
                if (!this.subscription) {
                    this.source.reload({
                        endPoint: `/api/config/global`,
                        defaultSort: 'created',
                        defaultSortOrder: 'desc',
                        defaultLimit: 20,
                        headers: new HttpHeaders({
                            'X-Operation-Name': 'Fetching schemas',
                        })
                    });

                    this.subscription = this.source.get().pipe(
                        map((configView) => {
                            this.confluence.fetchUsers(
                                new Set(configView.map(el => el.item.config.audit.createdBy)
                                    .concat(configView.map(el => el.item.config.audit.updatedBy))),
                                this.users
                            );
                        })
                    ).subscribe();
                }
            })
        );
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it.item.config._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el.item.config._delete);
        this.deleteSelected = elements.every(el => el.item.config._delete);
    }

    doDeleteSelected(elements: GlobalConfigView[]) {
        const toDelete = elements.filter((it: any) => it.item.config._delete)
            .map(it => it.item) as GlobalConfig[];
        if (toDelete.length > 0) {
            this.confirmDeletion(toDelete);
        }
    }

    editDefault() {
        this.confluence.showDialog({
            key: 'schema-edition-popup',
            customData: {
                type: 'DEFAULT'
            }
        });
    }

    createNew() {
        this.confluence.showDialog({
            key: 'schema-edition-popup',
            customData: {
                isNew: true,
                type: 'GLOBAL'
            }
        });
    }

    edit(el: GlobalConfig) {
        this.confluence.showDialog({
            key: 'schema-edition-popup',
            customData: {
                id: el.id,
                type: 'GLOBAL'
            }
        });
    }

    confirmDeletion(el: GlobalConfig | GlobalConfig[]) {
        this.confluence.showDialog({
            key: 'confirm-delete',
            customData: {
                title: 'Delete selected Space Config?',
            }
        }, (result) => {
            if (result.deleted) {
                this.loader.fullscreen(true);

                const ids = Array.isArray(el) ? el.map(it => it.id) : [el.id];
                this.app.deleteGlobalConfig(ids)
                    .pipe(finalize(() => this.loader.fullscreen(false)))
                    .subscribe(() => {
                        this.confluence.emitEvent(this.refreshEventName);
                    });
                this.moreThanOneItemIsSelected = false;
                this.deleteSelected = false;
            }
        });
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }
}
