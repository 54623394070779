import {AfterContentChecked, ContentChildren, Directive, forwardRef, Input, QueryList} from '@angular/core';

import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppRadioComponent} from './app-radio.component';

@Directive({
    selector: '[appRadioGroup]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppRadioGroupDirective),
            multi: true
        }
    ]
})
export class AppRadioGroupDirective implements ControlValueAccessor, AfterContentChecked {

    @Input() disabled = false;

    value: any;

    private onChangeFn: any;
    private onTouchedFn: any;

    /** Child radio buttons. */
    @ContentChildren(forwardRef(() => AppRadioComponent), {descendants: true})
    public radios: QueryList<AppRadioComponent>;

    ngAfterContentChecked(): void {
        if (this.radios) {
            this.radios.forEach(radio => {

                radio.setValue = (value: any) => {
                    this.value = value;
                    this.onChangeFn(value);
                    this.updateChildValues();
                };

                radio.setDisabledState(this.disabled);
            });
        }
        this.writeValue(this.value);
    }

    writeValue(value: any) {
        this.value = value;
        this.updateChildValues();
    }

    updateChildValues() {
        if (this.radios) {
            this.radios.forEach(radio => {
                radio.update(this.value);
            });
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.radios) {
            this.radios.forEach(radio => radio.setDisabledState(this.disabled));
        }
    }
}
