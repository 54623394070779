import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpaceAccessGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const userIsAdmin = next.queryParams.user_is_admin === 'true';
        const userIsSpaceAdmin = (next.queryParams.user_is_space_admin === 'true') || userIsAdmin;
        const permissionsEnabled = next.queryParams.permissionsEnabled === 'true';
        const hasViewSharesFromSpacePermission = next.queryParams.hasViewSharesFromSpacePermission === 'true';

        if (userIsSpaceAdmin || !permissionsEnabled || (permissionsEnabled && hasViewSharesFromSpacePermission)) {
            return of(true);
        } else {
            const reason = 'You do not have permission to view all External Shares from space';
            return of(this.router.parseUrl('/no-access-content-view?reason=' + reason));
        }
    }
}
