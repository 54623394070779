import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-custom-csp',
    templateUrl: './custom-csp.component.html',
    styleUrls: ['./custom-csp.component.css']
})
export class CustomCspComponent implements OnInit {
    @Input() form: FormGroup;

    sameSiteCookieNames = [
        {text: 'Lax', value: 'LAX'},
        {text: 'None', value: 'NONE'}
    ];

    currentProvider = 'LAX';

    ngOnInit(): void {
        const option = this.getSameSiteCookieRestrictionValue();
        if (option) {
            this.currentProvider = this.sameSiteCookieNames[option];
        } else {
            this.currentProvider = 'LAX';
        }
    }

    private getSameSiteCookieRestrictionValue(): string {
        return this.form.get('sameSiteCookieRestriction').value;
    }
}
