import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ConfluenceService, Space} from './confluence.service';
import {namedZip} from './utils/rxjs.util';

@Injectable({
    providedIn: 'root'
})
export class SpaceResolver implements Resolve<Space> {

    constructor(private confluence: ConfluenceService) {
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Space> | Promise<Space> | Space {
        const spaceKey = route.paramMap.get('spaceKey');
        return this.confluence.getSpace(spaceKey);
    }
}
