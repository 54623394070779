import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ServerDataSource} from '../../components/app-table.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, mergeAll, switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {Share, ShareFilterCriteria} from '../../base/share';
import {ShareActionService} from '../../share-action.service';
import {ConfluenceService} from '../../confluence.service';
import {ActivatedRoute} from '@angular/router';
import {Constants} from '../../utils/constants';
import AppUtils from '../../utils/app-utils';
import {AppState} from '../../base/share.service';
import {SearchFunction} from '../../components/better-select/better-select.component';

@Component({
    selector: 'app-user-share-list',
    templateUrl: './user-share-list.component.html',
    styleUrls: ['./user-share-list.component.css']
})
export class UserShareListComponent implements OnInit, OnDestroy {

    private subscription: Subscription;

    filterForm = new FormGroup({
        spaces: new FormControl(''),
        contents: new FormControl(''),
        createdBy: new FormControl(''),
        updatedBy: new FormControl(''),
        linkStatus: new FormControl('all')
    });

    deleteSelected = false;

    features = window.getToken().features;

    config$: Observable<AppState>;

    source = new ServerDataSource<Share, ShareFilterCriteria>(this.http);

    moreThanOneItemIsSelected = false;

    titles = new Map();
    users = new Map();

    statuses = Constants.SHARE_STATUSES;

    spacePick: SearchFunction;

    userId: string;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private linkActions: ShareActionService,
                private confluence: ConfluenceService) {
        this.spacePick = this.spacePickFoo.bind(this);
    }

    ngOnInit() {
        document.body.classList.add('global-view');
        this.source.reportError = true;

        this.config$ = Utils.combineParams(this.route).pipe(
            switchMap((params) => AppUtils.getAppState(params))
        );

        this.confluence.getUser().subscribe(user => {
            this.userId = user.accountId;
            this.setupDatasource();
        });

        const refresh = this.confluence.observeEvent('link-update');
        refresh.subscribe(() => {
            this.source.refresh();
        });

        this.filterForm.valueChanges.subscribe(it => {
            const spaces = (it.spaces || []).map(space => space.id);
            this.source.updateFilter({
                contents: it.contents || [],
                spaces,
                status: it.linkStatus,
                createdBy: it.createdBy,
                updatedBy: it.updatedBy,
                userId: this.userId
            });
        });

        this.filterForm.patchValue({
            linkStatus: 'active'
        });
    }

    spacePickFoo: SearchFunction = (term) => {
        return this.confluence.searchForSpaces(term).pipe(
            map(results => {
                const keys = results.map(key => key.key);
                return this.confluence.getSpacesByKey(keys).pipe(
                    map(space => {
                        const mappedResults = space.map(s => ({id: s.id, text: s.name}));

                        return {
                            results: mappedResults,
                            pagination: {more: false}
                        };
                    })
                );
            }),
            mergeAll()
        );
    }

    private setupDatasource() {
        this.subscription = this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 20,
            filter: {
                userId: this.userId,
                status: 'active'
            },
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares'
            })
        }).pipe(switchMap((list) => {
                this.confluence.fetchUsers(
                    new Set(list.map(el => el.createdBy).concat(list.map(el => el.updatedBy))),
                    this.users
                );

                const contentIds = [...new Set(list.map(el => el.contentId))].join(', ');
                if (!Utils.isEmpty(contentIds)) {
                    return this.fetchPageTitles(contentIds);
                }
                return of({results: []});
            })
        ).subscribe((title) => {
            title.results.forEach((el => {
                this.titles.set('' + el.content.id, el.content.title);
            }));
        });
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    edit(link: Share) {
        this.confluence.showDialog({
                key: 'share-edition-popup',
                customData: {
                    uuid: link.uuid,
                    contentId: link.contentId
                }
            }
        );
    }

    searchValue(data) {
        return data.id;
    }

    spaceTemplateResult(item) {
        return item.name;
    }

    contentsSearch = (term) => {
        if (!term) {
            return of();
        }

        const query = `title~%27${term}*%27`;

        return this.confluence.searchForContent(query || '').pipe(
            map(it => it.results || []),
            map(it => {
                const results = it
                    .filter(i => i.content && i.content.type === 'page')
                    .map(i => ({
                        text: Utils.replaceSearchText(i.content.title),
                        title: Utils.replaceSearchTitle(i.content.title),
                        id: i.content.id
                    }));

                return {results};
            })
        );
    }

    templateResult(data) {
        if (data.iconUrl) {
            return $(
                '<span class="avatar-container">' +
                '<img src="' + data.iconUrl + '" class="avatar" /><span>' + Utils.sanitize(data.title) + '</span>' +
                '</span>'
            );
        }
        return data.title;
    }

    userSearch = (term) => {
        return this.confluence.searchUsers(term || '_');
    }

    getTitle(contentId) {
        return this.titles.has('' + contentId) ? this.titles.get('' + contentId) : ' ';
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el._delete);
        this.deleteSelected = elements.every(el => el._delete);
    }

    doDeleteSelected(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Share[];
        this.linkActions.deleteMultiple(toDelete).then((result: { deleted: boolean }) => {
            this.moreThanOneItemIsSelected = !result.deleted;
        });
    }

    doEditSelected(elements: any[]) {
        const toEdit = elements.filter(it => it._delete) as Share[];
        this.linkActions.bulkEdit(toEdit, this.source.filter);
    }

    private fetchPageTitles(contentIds) {
        const query = `id%20in%20(${contentIds})`;
        return this.confluence.searchForContent(query || '');
    }
}
