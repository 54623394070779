import {AfterContentInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import tippy from 'tippy.js';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipDirective implements AfterContentInit, OnDestroy, OnChanges {
    @Input() appTooltip: string;
    @Input() appTooltipOn = true;

    el: any;

    constructor(private ref: ElementRef) {
    }

    ngAfterContentInit(): void {
        this.initializeTooltip();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appTooltip && !changes.appTooltip.firstChange && this.el) {
            this.el.setContent(changes.appTooltip.currentValue);
        }
    }

    initializeTooltip(): void {
        if (this.appTooltipOn && !!this.appTooltip) {
            this.ref.nativeElement.addEventListener('mouseenter', this.mouseEnter(this.ref.nativeElement, () => {
                if (!this.el) {
                    this.el = tippy(this.ref.nativeElement, {
                        animation: 'scale-subtle',
                        arrow: true,
                        content: this.appTooltip
                    });
                }
                this.el.show();
            }), true);

            this.ref.nativeElement.addEventListener('mouseout', this.mouseEnter(this.ref.nativeElement, () => {
                if (this.el) {
                    this.el.hide();
                }
            }), true);
        }
    }

    ngOnDestroy(): void {
        if (this.el) {
            this.el.destroy();
            this.el = null;
        }
    }

    mouseEnter(element, fn) {
        return (evt) => {
            const relTarget = evt.relatedTarget;
            if (element === relTarget || this.isAChildOf(element, relTarget)) {
                return;
            }
            fn.call(evt);
        };
    }

    isAChildOf(parent, child): boolean {
        if (parent === child) {
            return false;
        }
        while (child && child !== parent) {
            child = child.parentNode;
        }
        return child === parent;
    }
}
