import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {addToDate, Period, PeriodEnum} from '../base/period';
import Utils from './utils';
import {Constants} from './constants';

export default class CustomValidators {

    static ipSetRegexp = new RegExp('[,;\s]+');
    static ipRegexp = new RegExp('([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(\\d{1,3}\\.){3}\\d{1,3}');

    public static minDateNow(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            const now = new Date();
            const toValidate = new Date(currVal);

            return now <= toValidate ? null : {
                minDate: {
                    minDate: now,
                    actual: currVal
                }
            };
        };
    }

    public static maxPeriod(max: Period): ValidatorFn {
        max = Object.assign({}, max);
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            const {unit: currentUnit, count: currentCount} = currVal as Period;

            const now = new Date();

            const currentDate = addToDate(now, currentCount, currentUnit);
            const maxDate = addToDate(now, max.count, max.unit);

            return currentDate <= maxDate ? null : {
                period: {
                    max: maxDate,
                    actual: currentDate
                }
            };
        };
    }

    public static maxDateFromPeriod(max: { unit: PeriodEnum, count: number }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            let currDate: Date = currVal;
            if (typeof currVal === 'string') {
                currDate = new Date(currVal);
            }

            const now = new Date();
            const actualDate = currDate;
            const maxDate = addToDate(now, max.count, max.unit);

            return actualDate <= maxDate ? null : {
                period: {
                    max: maxDate,
                    actual: actualDate
                }
            };
        };
    }

    static integer(control) {
        if (Utils.isEmpty(control.value)) {
            return null;
        }
        const value = parseInt(control.value, 0);
        return isNaN(value) ? {integer: {actual: control.value}} : null;
    }

    static unitPositiveNumber(control) {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        const value = Utils.isEmpty(control.value.count) ? undefined : parseInt(control.value.count, 10);
        return isNaN(value) || value <= 0 ? {unitPositiveNumber: {actual: control.value.count}} : null;
    }

    static email(control) {
        if (Utils.isEmpty(control.value)) {
            return null;
        }
        return Utils.splitEmails(control.value)
            .every(isValidEmail) ? null : {email: {actual: control.value}};
    }

    static emailDomains(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (Utils.isEmpty(control.value)) {
                return null;
            }
            return Utils.splitEmailDomains(control.value)
                .every(isValidEmailDomain) ? null : {emailDomains: {actual: control.value}};
        };
    }

    static domain(control) {
        if (Utils.isEmpty(control.value)) {
            return null;
        }
        return isValidEmailDomain(control.value) ? null : {domain: {invalid: true}};
    }

    public static matchesEmailOrEmailDomain(allowedEmails: [],
                                            allowedDomains: []): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (Utils.isEmpty(currVal)) {
                return null;
            }

            let matches = allowedEmails.some(v => v === currVal);
            if (!matches && currVal.includes('@')) {
                matches = allowedDomains.some(v => v === currVal.split('@')[1]);
            }

            return matches ? null : {
                matchesEmailOrEmailDomain: {
                    actual: currVal
                }
            };
        };
    }

    public static matchesEmailDomain(allowedDomains: []): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (Utils.isEmpty(currVal) || !allowedDomains || Utils.isEmptyArray(allowedDomains)) {
                return null;
            }

            const current: [] = currVal.split(Constants.COMMA);
            const matches = current.every(r => allowedDomains.includes(r));

            return matches ? null : {
                matchesEmailDomain: {
                    actual: currVal
                }
            };
        };
    }

    static invalid(control) {
        return {invalid: true};
    }

    static error(control) {
        return {error: true};
    }

    public static validateIP(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            const value = control.value;
            if (value === null || value === '') {
                return null;
            }

            return this.areAllIPsCorrect(value) ? null : {ipError: true};
        };
    }

    private static areAllIPsCorrect(ips): boolean {
        const ipStrings = this.ipSetRegexp[Symbol.split](ips);

        let correct = true;

        ipStrings.forEach(ip => {
            const trimmedIp = ip.trim();
            if (trimmedIp !== '0.0.0.0/0' && trimmedIp !== '::/0') {
                if (!this.ipRegexp.test(ip)) {
                    correct = false;
                }
            }
        });
        return correct;
    }

    static requiredFileType(types: string[]): ValidatorFn {
        return (control: FormControl) => {
            if (!control.value) {
                return null;
            }
            const file = control.value;
            if (file) {
                const fileType = file.type.toLowerCase();
                if (types.filter(t => t.toLowerCase() === fileType || 'image/' + t.toLowerCase() === fileType).length === 0) {
                    return {
                        wrongFileType: true
                    };
                }
            }
            return null;
        };
    }

    static maximumFileSize(maxSize: number): ValidatorFn {
        return (control: FormControl) => {
            const file = control.value;
            if (file) {
                if (file.size > maxSize) {
                    return {
                        wrongSize: true
                    };
                }
            }
            return null;
        };
    }
}

function isValidEmail(email) {
    const regexp = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    return regexp.test(email);
}

function isEmptyInputValue(value) {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

function isValidEmailDomain(emailDomain) {
    const regexp = new RegExp(/^([A-Za-z0-9\-]+)\.([A-Za-z0-9\-]{2,})(\.([A-Za-z0-9\-]{2,}))?$/);
    return regexp.test(emailDomain);
}
export function urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (Validators.required(control)) {
            return null;
        }

        const value = control.value;
        const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

        if (!urlPattern.test(value)) {
            return { invalidUrl: true };
        }

        return null;
    };
}
