import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Recipient, RecipientChangeEvent, RecipientType} from '../recipient';
import {EmailComponent} from '../email/email.component';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-email-group',
    templateUrl: './email-group.component.html',
    styleUrls: ['./email-group.component.css']
})
export class EmailGroupComponent implements OnInit {

    @Input() type: RecipientType;
    @Input() label: string;
    @Input() focused = true;
    @Input() readOnly = false;
    @Input() disabled = true;
    @Input() invalid = false;
    @Input() copyAllowed = false;
    @Input() maxHeight: number;
    @Input() allowRemoveAll: boolean;

    @Input() allowedEmails: [];
    @Input() allowedDomains: [];

    inputVisible = true;

    @Input() recipients: Recipient[];

    @Output() refreshEvent: EventEmitter<Recipient[]> = new EventEmitter();
    @Output() removeEvent: EventEmitter<Recipient> = new EventEmitter();
    @Output() focusEvent: EventEmitter<RecipientType> = new EventEmitter();
    @Output() blur = new EventEmitter<void>();

    @ViewChild('mailGroupContainer') mailGroupContainer: ElementRef;
    @ViewChild('addEmailComponent') addEmailComponent: EmailComponent;

    style = '';
    containerStyle = '';

    ngOnInit(): void {
        if (this.maxHeight) {
            this.style = 'max-height: ' + this.maxHeight + 'px;overflow-x: hidden;';
        }
    }

    byType() {
        return this.recipients.filter(item => item.type === this.type);
    }

    remove(recipient: Recipient) {
        this.removeEvent.emit(recipient);
        this.updateContainerStyle();
    }

    removeAll() {
        this.byType().forEach(recipient => {
            this.removeEvent.emit(recipient);
        });
        this.updateContainerStyle();
    }

    change(changeEvent: RecipientChangeEvent) {
        const recipient = changeEvent.item;

        if (changeEvent.editedRecord) {
            this.editItem(changeEvent.editedRecord, recipient);
        } else if (this.anyNotExists(recipient)) {
            this.addItems(this.type, recipient.email);
            this.refreshEvent.emit(this.recipients);
        }
        this.updateContainerStyle();
    }

    private editItem(editedRecord: Recipient,
                     recipient: Recipient) {
        const type: RecipientType = editedRecord.type;
        const existing: Recipient[] = this.recipients.filter(item => {
            return this.isEqual(item, type, editedRecord.email);
        });

        if (Array.isArray(existing) && existing.length === 1) {
            const emails = this.findNotExisting(Utils.splitEmails(recipient.email), type);

            for (let i = 0; i < emails.length; i++) {
                if (i === 0) {
                    this.updateItem(existing[0], type, emails[0]);
                } else {
                    this.addItem(type, emails[i]);
                }
            }
        } else {
            this.addItems(this.type, recipient.email);
        }
        this.refreshEvent.emit(this.recipients);
    }

    anyNotExists(recipient: Recipient) {
        const emails = Utils.splitEmails(recipient.email);
        return this.findNotExisting(emails, recipient.type).length > 0;
    }

    findNotExisting(emails: string[],
                    type: RecipientType): string[] {
        const result: string[] = [];
        for (const email of emails) {
            if (!this.recipients.find(recipient => recipient.email === email && recipient.type === type)) {
                result.push(email);
            }
        }
        return result;
    }

    isEqual(item: Recipient,
            type: RecipientType,
            email: string) {
        return item.type === type && item.email === email;
    }

    addItems(type: RecipientType,
             emailString: string) {
        this.findNotExisting(Utils.splitEmails(emailString), type)
            .sort((a, b) => a.localeCompare(b))
            .forEach(email => {
                this.addItem(type, email);
            });
    }

    addItem(type: RecipientType,
            email: string) {
        this.recipients.push({
            type,
            email
        });
        this.addEmailComponent.clearValue();
    }

    updateItem(toUpdate: Recipient,
               type: RecipientType,
               email: string) {
        toUpdate.type = type;
        toUpdate.email = email;
    }

    markAsEdited(edition: boolean) {
        this.inputVisible = !edition;
    }

    markUsFocused() {
        this.focusEvent.emit(this.type);
    }

    isNotEmpty(): boolean {
        return this.recipients.length > 0;
    }

    isActiveInputInvalid(): boolean {
        return !!this.addEmailComponent && this.addEmailComponent.isInputAddressInvalid();
    }

    copy() {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (this.recipients.map(v => v.email).join(',')));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    }

    getContainerStyle(): string {
        return this.containerStyle;
    }

    private updateContainerStyle() {
        setTimeout(() => {
            this.containerStyle = this.style + 'overflow-y:' + (this.shouldHideScroll() ? 'hidden' : 'auto');
        }, 1);
    }

    private shouldHideScroll(): boolean {
        let result = true;
        if (this.mailGroupContainer) {
            const height = this.mailGroupContainer.nativeElement.offsetHeight;
            result = height < 300;
        }
        return result;
    }
}
