import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24"  focusable="false" role="presentation">' +
    '   <rect x="5" y="11" width="14" height="2" rx="1" fill="currentColor" fill-rule="evenodd"></rect>' +
    '</svg>';

@Directive({
    selector: '[appMinusIcon]'
})
export class MinusIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
