import {Component, Input} from '@angular/core';
import {AtlassianUser} from '../base/atlassian-user';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-link-to-user',
    template: '<a [href]="href" (click)="onClick($event)">{{currentUser ? currentUser.displayName : \'Loading\'}}</a>'
})
export class LinkToUserComponent {

    public currentUser: AtlassianUser;
    public href = '';

    constructor() {
    }

    @Input()
    public set user(user: AtlassianUser) {
        if (user) {
            this.currentUser = user;
            const url = environment.server ? '/display/~' + user.username : '/people/' + user.accountId;
            this.href = environment.host + url;
        }
    }

    onClick(e) {
        if (this.currentUser) {
            AP.navigator.go('userProfile', {userAccountId: `${this.currentUser.accountId}`, username: `${this.currentUser.username}`});
        }
    }
}
