import {Injectable, NgZone} from '@angular/core';
import {from, merge, Observable, of, Subject} from 'rxjs';
import {AtlassianUser} from './base/atlassian-user';
import {bufferCount, catchError, filter, map, mergeMap, reduce, switchMap, tap} from 'rxjs/operators';
import {Content} from './base/content';
import {environment} from '../environments/environment';
import {AppService} from './app.service';
import APDialogOptions = AP.APDialogOptions;

export interface Permissions {
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canSendEmail: boolean;
}

export interface PermissionSettings {
    enabled: boolean;
    viewOwnSharesOnPage: string[];
    viewAllSharesOnPage: string[];
    createNewShare: string[];
    editShare: string[];
    emailShare: string[];
    deleteShare: string[];
    viewSharesFromSpace: string[];
}

export interface Space {
    id?: number;
    key: string;
    name?: string;
}

export interface RequestOpts {
    url?: string;
    type?: 'GET' | 'PUT' | 'POST' | 'DELETE';
    cache?: boolean;
    data?: string | {};
    contentType?: string;
    headers?: {};
    success?: (data: any) => void;
    error?: (xhr: XMLHttpRequest, statusText: string, errorThrown: any) => void;
    experimental?: boolean;
}

export interface SpaceSearchResponse {
    id: string;
    key: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class ConfluenceService {
    static readonly EXPORT_CSV_CHUNK_SIZE = 100;

    constructor(private zone: NgZone,
                private app: AppService) {
    }

    getContext(): Observable<ConfluenceContext> {
        return from(new Promise<ConfluenceContext>((resolve, reject) => {
            AP.context.getContext()
                .then(
                    (it: ConfluenceContext) => {
                        this.zone.run(() => resolve(it));
                    },
                    it => {
                        atlas.log('Context Error', it);
                        this.zone.run(() => reject(it));
                    },
                );
        }));
    }

    getLocation(): Observable<URL> {
        return from(new Promise<URL>((resolve) => {
            AP.getLocation(it => this.zone.run(() => {
                return resolve(new URL(it));
            }));
        }));
    }

    searchUsers(query: string) {
        return this.searchForUser(query).pipe(
            map(it => ({results: it.results || [], baseUrl: it._links.base.replace('/wiki', '')})),
            map(it => {
                const filtered = it.results.filter(i => i.user);
                filtered.forEach(i => {
                    i.text = i.user.displayName;
                    i.title = i.user.displayName;
                    i.iconUrl = it.baseUrl + i.user.profilePicture.path;
                    i.id = environment.server ? i.user.userKey : i.user.accountId;
                });
                return {results: filtered};
            })
        );
    }

    searchForUser(query: string) {
        const q = `cql=user.fullname~%27${query}%27`;

        let url = '/rest/api/search';
        if (!environment.server) {
            url += '/user';
        }

        return this.apRequest(url + '?' + q)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    getSpacesByKey(spaceKey: string[]): Observable<SpaceSearchResponse[]> {
        const query = spaceKey && spaceKey.length > 0 ? 'spaceKey=' + spaceKey.join('&spaceKey=') + '&' : '';
        return this.concatenateAllSpaces(query, 0, []);
    }

    searchForSpace(query: string, start: number, limit: number) {
        return this.apRequest(`/rest/api/space?${query}start=${start}&limit=${limit}`)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    concatenateAllSpaces(query: string, deep: number, aggregated: any): Observable<SpaceSearchResponse[]> {
        return this.searchForSpace(query, deep, 100)
            .pipe(
                switchMap(it => {
                    const res = it.results || [];
                    if (it.size === it.limit) {
                        return this.concatenateAllSpaces(query, deep + 100, aggregated.concat(res));
                    }
                    return of(aggregated.concat(res));
                })
            );
    }

    searchForSpaces(query: string): Observable<SpaceSearchResponse[]> {
        return this.concatenateSearchSpacesByTitle(query, 0, []);
    }

    concatenateSearchSpacesByTitle(title: string, deep: number, aggregated: any): Observable<SpaceSearchResponse[]> {
        return this.searchSpacesByTitle(title, deep, 100)
            .pipe(
                switchMap(it => {
                    const res = it.results || [];
                    const result = res.map(
                        item => ({
                            id: item.space.key,
                            key: item.space.key,
                            name: item.space.name
                        })
                    );

                    if (it.size > 0 && it.size === it.limit) {
                        return this.concatenateSearchSpacesByTitle(title, deep + 100, aggregated.concat(result));

                    }
                    return of(aggregated.concat(result));
                })
            );
    }

    searchSpacesByTitle(title: string, start: number, limit: number) {
        const cql = `type=space%20AND%20title~"${title}*"`;
        const query = `cql=${cql}&start=${start}&limit=${limit}`;

        return this.searchForContentByQuery(query);
    }

    searchForContentByQuery(query: string) {
        return this.apRequest('/rest/api/search?' + query)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    searchForContent(query: string, spaceKey?: string):
        Observable<{ results: { content: { id: string, title: string, type: string } } [] }> {
        const q = spaceKey ? `cql=${query}%20and%20space=%27${spaceKey}%27` : `cql=${query}`;

        return this.apRequest('/rest/api/search?limit=100&' + q )
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    searchForGroup(query: string) {
        const q = `query=${query}`;
        return this.apRequest('/rest/api/group/picker?' + q)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    getContent(contentId: any): Observable<Content> {
        return this.apRequest(`/rest/api/content/${contentId}?expand=body.view,container`)
            .pipe(map(it => JSON.parse(it)));
    }

    getCustomData<T>(): Observable<T> {
        if (window.__cache_custom_data) {
            return of(window.__cache_custom_data);
        }

        return from(new Promise<T>((resolve) => {
            AP.dialog.getCustomData(it => this.zone.run(() => {
                window.__cache_custom_data = it;
                return resolve(it as T);
            }));
        }));
    }

    setCustomData(data: any) {
        window.__cache_custom_data = data;
    }

    getSpace(spaceKey: string): Observable<Space> {
        return this.apRequest(`/rest/api/space/${spaceKey}`).pipe(
            map(it => JSON.parse(it))
        );
    }

    getUser(): Observable<AtlassianUser> {
        return this.getCurrentUser()
            .pipe(
                switchMap(it => {
                    return this.requestUser(it.atlassianAccountId);
                })
            );
    }

    getLocale(): Observable<string> {
        return from(new Promise<string>((resolve, reject) => {
            AP.user.getLocale((locale) => {
                    resolve(locale);
                }
            );
        }));
    }

    getCurrentUser(): Observable<UserContext> {
        return from(new Promise<UserContext>((resolve, reject) => {
            AP.user.getCurrentUser((user) => {
                    resolve(user);
                }
            );
        }));
    }

    fetchUsers(userIds,
               users: Map<string, AtlassianUser>) {
        mergeMaps(users, this.fetchUsersMap(userIds)).subscribe();

        function mergeMaps(originalMap: Map<string, AtlassianUser>,
                           update$: Observable<Map<string, AtlassianUser>>): Observable<Map<string, AtlassianUser>> {
            return update$.pipe(
                tap(updatedMap => {
                    updatedMap.forEach((value, key) => {
                        originalMap.set(key, value);
                    });
                }),
                map(() => originalMap)
            );
        }
    }

    fetchUsersMap(userIds: Set<string>) {
        return from(userIds).pipe(
            filter(userId => this.notEmpty(userId)),
            bufferCount(100),
            mergeMap(batch => from(this.requestUsers(batch))),
            map(users => {
                const userMap = new Map();
                users.forEach((atlassianUser: AtlassianUser) => {
                    userMap.set(atlassianUser.accountId, atlassianUser);
                });
                return userMap;
            }),
        );
    }

    notEmpty<T>(value: T | null | undefined): value is T {
        return value !== null && value !== undefined;
    }

    requestUser(atlassianAccountId: string): Observable<AtlassianUser> {
        if (window['__cache_user_' + atlassianAccountId]) {
            return of(window['__cache_user_' + atlassianAccountId]);
        }
        return this.apRequest('/rest/api/user?accountId=' + atlassianAccountId)
            .pipe(
                map(it => JSON.parse(it)),
                catchError(it => {
                    if (it && it.xhr && it.xhr.status === 404) {
                        return of({
                            displayName: 'unknown',
                            accountId: atlassianAccountId,
                            username: 'unknown'
                        });
                    }
                    throw it;
                }),
                tap(it => {
                    window['__cache_user_' + it.accountId] = it;
                })
            );
    }

    requestUsers(atlassianAccountIds: string[]): Observable<AtlassianUser[]> {
        const result = new Set<AtlassianUser>();
        const accountIds = new Set<string>();

        atlassianAccountIds.forEach(atlassianAccountId => {
            if (window['__cache_user_' + atlassianAccountId]) {
                result.add(window['__cache_user_' + atlassianAccountId]);
            } else {
                accountIds.add(atlassianAccountId);
            }
        });

        const resultArray = [...result];
        if (accountIds.size === 0) {
            return of(resultArray);
        }

        const queryString = Array.from(accountIds)
            .map(accountId => `accountId=${encodeURIComponent(accountId)}`)
            .join('&');

        const fetchedUsers: Observable<AtlassianUser[]> = this.apRequest('/rest/api/user/bulk?' + queryString)
            .pipe(
                map(it => JSON.parse(it).results),
                tap(it => {
                    it.forEach((user: AtlassianUser) => window['__cache_user_' + user.accountId] = user);
                })
            );

        return merge(of(resultArray), fetchedUsers).pipe(
            reduce((acc, value) => acc.concat(value), [] as AtlassianUser[])
        );
    }

    showDialog(parameters: APDialogOptions,
               callback?: (result?: any) => void) {
        parameters.customData = parameters.customData || {};
        parameters.customData.jwtToken = window.getToken().token;
        parameters.customData.features = window.getToken().features;
        parameters.customData.closeEventId = 'dialog-id-' + this.id();
        AP.events.once(parameters.customData.closeEventId, (result) => {
            if (callback) {
                callback(result ? JSON.parse(result) : result);
            }
        });
        AP.dialog.create(parameters);
    }

    closeDialog(data?: any) {
        this.getCustomData<any>().subscribe(it => {
            if (it) {
                if (it.closeEventId) {
                    AP.events.emit(it.closeEventId, JSON.stringify(data));
                }
            }
            AP.dialog.close();
        });
    }

    emitEvent(name: string,
              ...args: string[]) {
        AP.events.emit(name, ...args);
    }

    onEvent(eventName: string, listener: (...data: any[]) => void) {
        const zone = this.zone;
        AP.events.on(eventName, function eventZone() {
            const args = arguments;
            zone.run(() => {
                listener(...Array.from(args));
            });
        });
    }

    observeEvent(eventName: string): Observable<any> {
        const subject = new Subject();
        this.onEvent(eventName, (data) => {
            subject.next(data);
        });
        return subject;
    }

    resize(elementId: string): void {
        sizeWatcher(elementId);
    }

    private apRequest(url: string, opts: RequestOpts = {}): Observable<any> {
        return from(new Promise((resolve, reject) => {

            const success = (response: string) => {
                this.zone.run(() => resolve(response));
            };

            const error = (xhr: XMLHttpRequest, statusText: string, errorThrown: any) => {
                this.zone.run(() => reject({xhr, statusText, errorThrown}));
            };

            AP.request({
                url, success, error, ...opts
            });
        }));
    }

    id() {
        return Math.random().toString(36).substr(2, 9);
    }

    getAddonProperty(propertyKey: string, defaultValue?: any) {
        const applicationKey = this.app.claims().iss;
        return this.apRequest(`/rest/atlassian-connect/1/addons/${applicationKey}/properties/${propertyKey}`, {
                type: 'GET'
            }
        ).pipe(
            map(it => it ? JSON.parse(it) : {}),
            map(it => it.value),
            catchError(it => {
                if (it && it.xhr && it.xhr.status === 404) {
                    return of({key: propertyKey, value: defaultValue});
                }
                throw it;
            }),
        );
    }

    setAddonProperty(propertyKey: string, data: any) {
        const applicationKey = this.app.claims().iss;
        return this.apRequest(`/rest/atlassian-connect/1/addons/${applicationKey}/properties/${propertyKey}`, {
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(data)
            }
        ).pipe(
            map(it => JSON.parse(it)),
        );
    }

    fetchPageTitlesForExport(contentIds: Set<string>, spaceKey?) {
        return from(contentIds).pipe(
            bufferCount(ConfluenceService.EXPORT_CSV_CHUNK_SIZE),
            mergeMap(batch => {
                const query = `id%20in%20(${batch.join(', ')})`;
                return this.searchForContent(query || '', spaceKey);
            }),
            reduce((acc, result) => {
                if (result && result.results) {
                    return acc.concat(result.results);
                }
                return acc;
            }, [] as Array<{ content: { id: string, title: string } }>),
            map(it => {
                    const resultMap = new Map();
                    it.forEach(page => {
                        resultMap.set(page.content.id, page.content.title);
                    });
                    return resultMap;
                }
            )
        );
    }
}

let cachedSensor: ResizeSensor = null;

function sizeWatcher(elementId: string) {
    if (cachedSensor) {
        cachedSensor.detach();
        cachedSensor = null;
    }
    const element = document.getElementById(elementId);
    return cachedSensor = new ResizeSensor(element, () => {
        const style = getComputedStyle(element);
        const offsetHeight = element.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
        AP.resize(undefined, offsetHeight + 'px');
    });
}
