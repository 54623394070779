import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-data-options',
    templateUrl: './data-options.component.html',
    styleUrls: ['./data-options.component.css']
})
export class DataOptionsComponent implements OnInit {
    @Input()
    set editable(value: boolean) {
        this.isEditable = value;
        this.setReadOnly(!this.isEditable);
    }
    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    private isEditable: boolean;

    delete = [];
    create = [];
    update = [];
    list = [];
    get = [];

    ngOnInit(): void {
        const opt = this.enabled.dataOptions;

        this.delete = [
            ['off', 'Disallow', null, opt.delete.off],
            ['on', 'Allow', 'API Key allows to delete External Shares',
                opt.delete.on],
        ];

        this.create = [
            ['off', 'Disallow', null, opt.create.off],
            ['on', 'Allow', 'API Key allows to create External Shares',
                opt.create.on],
        ];

        this.update = [
            ['off', 'Disallow', null, opt.update.off],
            ['on', 'Allow', 'API Key allows to update External Shares', opt.update.on],
        ];

        this.list = [
            ['off', 'Disallow', null, opt.list.off],
            ['on', 'Allow', 'API Key allows to list all External Shares', opt.list.on],
        ];

        this.get = [
            ['off', 'Disallow', null, opt.get.off],
            ['on', 'Allow', 'API Key allows to get External Share', opt.get.on],
        ];
        this.setReadOnly(!this.isEditable);
    }

    setReadOnly(isReadOnly: boolean) {
        if (isReadOnly) {
            this.form.get('delete').disable();
            this.form.get('create').disable();
            this.form.get('update').disable();
            this.form.get('list').disable();
            this.form.get('get').disable();
        } else {
            this.form.get('delete').enable();
            this.form.get('create').enable();
            this.form.get('update').enable();
            this.form.get('list').enable();
            this.form.get('get').enable();
        }
    }
}
