import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {PageCustomizationComponent} from '../page-customization.component';
import {Constants} from '../../../../utils/constants';

@Component({
    selector: 'app-page-customization-password-page',
    templateUrl: './page-customization-password-page.component.html',
    styleUrls: ['./page-customization-password-page.component.css']
})
export class PageCustomizationPasswordPageComponent implements OnInit {
    @Input() form: FormGroup;

    boxShadow = '';
    boxShadow2 = '';
    boxShadow3 = '';
    boxShadow4 = '';

    selectOptions = [
        {text: 'Pixels', value: 'pixels'},
        {text: 'Auto', value: 'auto'}
    ];

    availableFonts = Constants.AVAILABLE_FONTS;

    genericFontFamily = Constants.GENERIC_FONT_FAMILY;
    mainFrameBorderStyles = Constants.MAIN_FRAME_BORDER_STYLES;
    selectedFonts = [];
    sides = ['Top', 'Right', 'Bottom', 'Left'];

    passwordPageForm = new FormGroup({
        marginTop: new FormControl(''),
        marginRight: new FormControl(''),
        marginBottom: new FormControl(''),
        marginLeft: new FormControl(''),
        marginTypeTop: new FormControl('pixels'),
        marginTypeRight: new FormControl('pixels'),
        marginTypeBottom: new FormControl('pixels'),
        marginTypeLeft: new FormControl('pixels'),
        paddingTop: new FormControl(''),
        paddingRight: new FormControl(''),
        paddingBottom: new FormControl(''),
        paddingLeft: new FormControl(''),
        paddingTypeTop: new FormControl('pixels'),
        paddingTypeRight: new FormControl('pixels'),
        paddingTypeBottom: new FormControl('pixels'),
        paddingTypeLeft: new FormControl('pixels')
    });

    ngOnInit(): void {
        this.initializeBoxShadowValues();
        const initialValue = this.form.get('passwordPage.fontFamily')?.value.split(', ');
        this.selectedFonts = initialValue.filter(font => this.availableFonts.includes(font));
        this.availableFonts = this.availableFonts.filter(font => !initialValue.includes(font));

        this.initializePaddingValues();
        this.initializeMarginValues();

        this.sides.forEach(side => {
            this.passwordPageForm.get('marginType' + side).valueChanges.subscribe(() => this.onMarginTypeChange(side));
            this.passwordPageForm.get('margin' + side).valueChanges.subscribe(() => this.onMarginChange(side));
        });

        this.sides.forEach(side => {
            this.passwordPageForm.get('paddingType' + side).valueChanges.subscribe(() => this.onPaddingTypeChange(side));
            this.passwordPageForm.get('padding' + side).valueChanges.subscribe(() => this.onPaddingChange(side));
        });
    }

    removeImageUrl() {
        this.form.patchValue({
            customBody: {
                backgroundImage: null
            }
        });
    }

    removeImageUrlDarkMode() {
        this.form.patchValue({
            customBody: {
                backgroundImageDarkMode: null
            }
        });
    }

    updateMainFrameBoxShadow() {
        const mainFrameBoxShadow = [this.boxShadow, this.boxShadow2, this.boxShadow3, this.boxShadow4]
            .map(v => (!isNaN(parseFloat(v)) && isFinite(Number(v))) ? `${v}px` : v)
            .join(' ');

        this.form.get('mainFrame.boxShadow').setValue(mainFrameBoxShadow);
    }

    initializeBoxShadowValues() {
        const boxShadow = this.form.get('mainFrame.boxShadow').value;

        if (boxShadow) {
            const parts = boxShadow.split(' ');

            if (parts.length >= 4) {
                this.boxShadow = parts[0].replace('px', '');
                this.boxShadow2 = parts[1].replace('px', '');
                this.boxShadow3 = parts[2].replace('px', '');
                this.boxShadow4 = parts[3].replace('px', '');
            }
        }
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
        this.updateFormValue();
    }

    private updateFormValue() {

        const selectedFontsOrder = this.selectedFonts;
        this.form.get('passwordPage.fontFamily')?.setValue(selectedFontsOrder.join(', '));

    }

    initializePaddingValues() {
        PageCustomizationComponent.initializeCssValues(
            this.sides,
            'padding',
            this.form.get('mainFrame.padding'),
            this.passwordPageForm);
    }

    updateHeaderPadding() {
        const paddingValues = PageCustomizationComponent.computeCssValues(
            this.sides,
            this.passwordPageForm,
            'padding'
        );
        PageCustomizationComponent.applyCssShorthandRules(paddingValues);
        this.form.get('mainFrame.padding').setValue(paddingValues.join(' '));
    }

    initializeMarginValues() {
        PageCustomizationComponent.initializeCssValues(
            this.sides,
            'margin',
            this.form.get('mainFrame.margin'),
            this.passwordPageForm);
    }

    updateHeaderMargin() {
        const marginValues = PageCustomizationComponent.computeCssValues(
            this.sides,
            this.passwordPageForm,
            'margin'
        );
        PageCustomizationComponent.applyCssShorthandRules(marginValues);

        this.form.get('mainFrame.margin').setValue(marginValues.join(' '));
    }

    onPaddingTypeChange(side: string) {
        const paddingTypeControl = this.passwordPageForm.get('paddingType' + side);
        const paddingControl = this.passwordPageForm.get('padding' + side);
        const value = paddingTypeControl.value;
        if (value === 'auto') {
            paddingControl.setValue('auto', {emitEvent: false});
        } else {
            paddingControl.setValue('', {emitEvent: false});
        }

        this.updateHeaderPadding();
    }

    onPaddingChange(side: string) {
        const paddingTypeControl = this.passwordPageForm.get('paddingType' + side);

        if (paddingTypeControl.value === 'auto') {
            paddingTypeControl.setValue('pixels', {emitEvent: false});
        }

        this.updateHeaderPadding();
    }

    onMarginTypeChange(side: string) {
        const marginTypeControl = this.passwordPageForm.get('marginType' + side);
        const marginControl = this.passwordPageForm.get('margin' + side);
        const value = marginTypeControl.value;
        if (value === 'auto') {
            marginControl.setValue('auto', {emitEvent: false});
        } else {
            marginControl.setValue('', {emitEvent: false});
        }

        this.updateHeaderMargin();
    }

    onMarginChange(side: string) {
        const marginTypeControl = this.passwordPageForm.get('marginType' + side);

        if (marginTypeControl.value === 'auto') {
            marginTypeControl.setValue('pixels', {emitEvent: false});
        }

        this.updateHeaderMargin();
    }
}
