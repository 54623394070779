import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent {

    @Input() form: FormGroup;

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
