import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent {

    @Input() form: FormGroup;

    constructor() {
    }
}
