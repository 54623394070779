/* tslint:disable:max-line-length */
import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '<svg viewBox="0 0 1000 1000" width="250" height="250">\n' +
    '\t<title>Search Logo</title>\n' +
    '\t<style>\n' +
    '\t\t.s0 { fill: #c6c9cc   }\n' +
    '\t\t.s1 { fill: #a8aab0  } \n' +
    '\t\t.s2 { fill: #ffffff  } \n' +
    '\t\t.s3 { fill: #b1c1de  } \n' +
    '\t</style>\n' +
    '\t<g id="Layer" style="opacity: 0.6">\n' +
    '\t\t<path id="Layer" class="s0" d="m600.2 554.5l6.1 7.3q-1.2 1.2-2.5 2.5-8.1 8.1-17.2 15.7-8.9 7.5-18.4 14.2-2.2 1.5-4.3 2.9-0.7 0.5-1.5 1l-5.8-7-3.4-4-3.3-4q2.1-1.4 4.3-2.9 9.5-6.7 18.4-14.2 9.1-7.6 17.2-15.8 1.9-1.8 3.7-3.7l3.3 4z" />\n' +
    '\t\t<path id="Layer" class="s0" d="m714.2 690.4l-6.9 5.8-36.7 30.8-22.6-26.9-3.3-3.9-3.3-4-32.4-38.6q0.7-0.5 1.4-1 2.1-1.5 4.2-3 9.4-6.9 18.4-14.4 9-7.6 17.3-15.6 1.3-1.2 2.5-2.4l32.2 38.4 3.3 4 3.4 3.9 16.7 20z" />\n' +
    '\t\t<path id="Layer" class="s1" d="m830.3 975.8l-201.5-240.2c-7.1-8.5-6-21.1 2.5-28.2l70.4-59.1c8.5-7.1 21.1-6 28.2 2.5l201.5 240.1c16.7 19.9 14.1 49.4-5.7 66.1l-29.3 24.5c-19.9 16.7-49.4 14.1-66.1-5.7z" />\n' +
    '\t\t<path id="Layer" class="s1" d="m660.5 626.4q-1.2 1.2-2.5 2.4-8.3 8.1-17.3 15.6-9 7.5-18.3 14.4-2.1 1.5-4.3 3-0.7 0.5-1.4 1l-16.9-20.2-3.4-4-3.3-4-30.7-36.5q0.8-0.5 1.5-1 2.1-1.4 4.3-2.9 9.5-6.7 18.4-14.2 9.1-7.6 17.2-15.7 1.3-1.3 2.5-2.5l30.4 36.2 3.3 4 3.4 4z" />\n' +
    '\t\t<path id="Layer" class="s0" d="m643.7 598.3q-1.8 1.9-3.7 3.7-1.8 1.9-3.7 3.6-8.3 8-17.3 15.6-9 7.5-18.4 14.4-2.1 1.5-4.2 3-2.1 1.5-4.2 3c-143.9 98.6-341.8 73.7-456.2-62.7-121.8-145-102.8-361.3 42.2-483 145.1-121.7 361.3-102.8 483 42.3 114.5 136.3 104.6 335.6-17.5 460.1zm-452.5-65.7c46.5 55.4 111.7 89.4 183.8 95.7 60.8 5.3 120.2-9.6 170.6-42.3q2.1-1.4 4.3-2.8 2.1-1.5 4.3-3 9.5-6.7 18.4-14.2 9.1-7.6 17.2-15.8 1.9-1.8 3.7-3.7 1.8-1.8 3.6-3.7c91.5-98.6 97.6-252.7 8.9-358.3-96-114.4-267.1-129.3-381.5-33.4-114.3 96-129.3 267.1-33.3 381.5z" />\n' +
    '\t\t<path id="Layer" class="s2" d="m878.3 889c-2.5 0-5-1-6.8-3.1l-164.1-189.6c-3.3-3.7-2.9-9.5 0.9-12.7 3.8-3.3 9.5-2.9 12.8 0.9l164.1 189.5c3.3 3.8 2.8 9.5-0.9 12.8-1.7 1.5-3.9 2.2-6 2.2z" />\n' +
    '\t\t<path id="Layer" class="s2" d="m158.3 167.5c-1.4 0-2.9-0.4-4.1-1.4-2.9-2.3-3.4-6.4-1.1-9.3 21.2-27.1 46.6-50.6 75.5-69.7 28.4-18.8 59.5-32.9 92.4-42 68.3-18.9 139.4-15 205.4 11.4 3.4 1.4 5 5.3 3.7 8.6-1.4 3.4-5.3 5.1-8.6 3.7-126.7-50.7-273.9-11.1-358 96.2-1.3 1.6-3.2 2.5-5.2 2.5z" />\n' +
    '\t\t<path id="Layer" class="s3" d="m394 433q-19.5 0.8-23.3-25l-4.5-40.7q-1.4-12.6 4.4-19.9 5.8-7.3 18-11 19.7-5.9 30.2-17.4 10.4-11.6 9.6-29.8-0.8-19.9-12-31.1-11.1-11.1-30.7-10.3-24.5 1.1-35 20.1-8.1 13.1-14.8 17-6.6 4-18 2.6-12.9-1.7-19.8-9.8-6.9-8.2-1.5-20.7 10.7-24.5 33.5-39.6 22.8-15 53.7-16.3 28.1-1.2 50.1 8.6 22 9.7 35.1 29.4 13.1 19.7 14.3 47.8 1.4 32.2-17.5 53-18.9 20.8-51.3 30.4l-0.3 35.9q-1.1 26-20.2 26.8zm3.7 97.6q-14.1 0.6-24.7-9-10.7-9.5-11.3-23.6-0.6-14.5 9.2-24.9 9.8-10.5 23.9-11.1 14.5-0.6 25.1 9 10.7 9.5 11.3 24.1 0.6 14-9.2 24.5-9.8 10.4-24.3 11z" />\n' +
    '\t</g>\n' +
    '</svg>';

@Directive({
    selector: '[appSearchIcon]'
})
export class SearchIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
