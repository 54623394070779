import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from '../icons/icons.module';
import {ShareTableComponent} from './share-table/share-table.component';
import {PageViewComponent} from './page-view/page-view.component';

@NgModule({
    declarations: [
        ShareTableComponent,
        PageViewComponent
    ],
    imports: [
        BrowserModule,
        BaseModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        IconsModule
    ],
    exports: [
        PageViewComponent
    ]
})
export class PageViewModule {
}
