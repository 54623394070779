import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {ShareService} from '../../base/share.service';
import {ConfluenceService} from '../../confluence.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

    view$: Observable<{ title: string, type: string }>;

    constructor(private confluence: ConfluenceService) {}

    ngOnInit(): void {
        document.body.classList.add('delete-view');

        this.view$ =
            this.confluence.getCustomData<any>().pipe(
                map(it => {
                    let type: string;
                    if (it.type) {
                        type = it.type;
                    } else {
                        type = 'delete';
                    }
                    return {
                        title: it.title,
                        type
                    };
                })
            );
    }

    delete() {
        this.confluence.closeDialog({deleted: true});
    }

    close() {
        this.confluence.closeDialog({deleted: false});
    }
}
