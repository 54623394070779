import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Content} from './base/content';
import {ConfluenceService} from './confluence.service';

@Injectable({
    providedIn: 'root'
})
export class ContentResolver implements Resolve<Content> {

    constructor(private confluence: ConfluenceService) {}

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Content> | Promise<Content> | Content {
        return of(route.paramMap).pipe(
            map(it => {
                return {contentId: it.get('contentId')};
            }),
            switchMap(({contentId}) => {
                return this.confluence.getContent(contentId);
            })
        );
    }
}
