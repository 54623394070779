import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-labels',
    templateUrl: './labels.component.html',
    styleUrls: ['./labels.component.css']
})
export class LabelsComponent {

    @Input() form: FormGroup;

    constructor() {
    }
}
