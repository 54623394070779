import {Component, OnInit} from '@angular/core';
import {SpaceSchemaViewService} from './space-schema-view.service';

@Component({
    selector: 'app-space-schema-form-parent',
    templateUrl: './space-schema-form-parent.component.html',
    styleUrls: ['./space-schema-form-parent.component.css']
})
export class SpaceSchemaFormParentComponent implements OnInit {
    fullHeight = true;

    constructor(private psv: SpaceSchemaViewService) {
    }

    ngOnInit(): void {
        this.psv.toggleFullHeight.subscribe(it => {
            this.fullHeight = it;
        });
    }
}
