import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ConfluenceService} from '../confluence.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-no-access-content-view',
    templateUrl: './no-access-content-view.component.html',
    styleUrls: ['./no-access-content-view.component.css']
})
export class NoAccessContentViewComponent implements OnInit {

    config$: Observable<{ reason: string }>;

    constructor(private route: ActivatedRoute,
                private confluence: ConfluenceService) {
    }

    ngOnInit(): void {
        this.confluence.resize('es-expand');
        this.config$ = this.route.queryParamMap.pipe(
            map(it => ({reason: it.get('reason')}))
        );
    }
}
