import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.css']
})
export class RadioButtonComponent {
    @Input() form: FormGroup;
    @Input() name: string;
    @Input() items: (string|boolean)[][];

    constructor() {
    }

}
