import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-image-upload',
    templateUrl: 'image-upload.component.html',
    styleUrls: ['./image-upload.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ImageUploadComponent),
        }
    ]
})
export class ImageUploadComponent implements ControlValueAccessor, OnInit {
    @Input() form: FormGroup;
    @Input() formControlName: string;
    @ViewChild('fileInput') fileInput: ElementRef;

    selectedFile: File;
    imageSrc;
    hide: boolean;
    isDisabled = false;

    private onChangeFn: any;
    private onTouchedFn: any;

    constructor() {
    }

    onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        this.onChangeFn(this.selectedFile);
        this.viewImage(this.selectedFile);
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    writeValue(value: any): void {
        this.selectedFile = value;
        this.viewImage(this.selectedFile);

    }

    ngOnInit(): void {
        this.viewImage(this.selectedFile);
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    private viewImage(file: File) {
        if (file) {
            this.hide = false;
            const reader = new FileReader();
            reader.onload = e => this.imageSrc = reader.result;

            reader.readAsDataURL(file);
        } else {
            this.hide = true;
        }
    }

    resetInput() {
        if (this.fileInput && this.fileInput.nativeElement) {
            this.fileInput.nativeElement.value = '';
            if (this.form && this.formControlName) {
                this.form.get(this.formControlName).setValue(null);
            }
        }
    }
}
