import {Component, Input} from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-link-to-content',
    template: '<a [href]="href" (click)="onClick($event)">{{titleVariable}}</a>'
})
export class LinkToContentComponent {

    public contentIdVariable: string;
    public titleVariable: string;
    private spaceKey: string;

    public href = '';

    constructor() {
        // refresh base url
        if (this.contentIdVariable) {
            this.contentId = this.contentIdVariable;
        }
    }

    @Input()
    public set contentId(contentId: string) {
        this.contentIdVariable = contentId;
        this.initialize();
    }

    @Input()
    public set title(title: string) {
        this.titleVariable = !!title ? title : 'No access to page';
    }

    @Input()
    public set space(key: string) {
        this.spaceKey = key;
        this.initialize();
    }

    initialize() {
        if (this.contentIdVariable && this.spaceKey) {
            if (environment.server) {
                this.href = environment.host + `/pages/viewpage.action?pageId=${this.contentIdVariable}`;
            } else {
                this.href = environment.host + `/wiki/spaces/${this.spaceKey}/pages/${this.contentIdVariable}`;
            }
        }
    }

    onClick(e) {
        if (this.contentIdVariable) {
            AP.navigator.go('contentview', {contentId: this.contentIdVariable});
        }
    }
}
