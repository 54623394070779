import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Share} from './base/share';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ShareService} from './base/share.service';

@Injectable({
    providedIn: 'root'
})
export class ShareResolver implements Resolve<Share> {

    constructor(private service: ShareService) {}

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Share> | Promise<Share> | Share {
        return of(route.paramMap).pipe(
            map(it => {
                return {uuid: it.get('uuid')};
            }),

            switchMap(({uuid}) => {
                return this.service.get(uuid);
            })
        );
    }
}
