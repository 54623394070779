import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-custom-email',
    templateUrl: './custom-email.component.html',
    styleUrls: ['./custom-email.component.css']
})
export class CustomEmailComponent implements OnInit {

    defaultEmail = 'External Share for Confluence <no-reply@external-share.com>';

    @Input() form: FormGroup;

    ngOnInit(): void {
        if (this.form.get('emailCustom').value) {
            Utils.validateAllFormFields(this.form);
        }
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
