export class Content {
    id: number;
    title: string;
    type?: string;
    container: Container;
}

export class Container {
    id: number;
    key: string;
}

export enum ContentType {
    page = 'page',
    blogpost = 'blogpost',
    comment = 'comment',
    attachment = 'attachment'
}
