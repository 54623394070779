import {Component, OnInit} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {Share} from '../../base/share';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ShareService} from '../../base/share.service';
import {ConfluenceService, Space} from '../../confluence.service';
import {ShareListConfig} from '../share-table/share-table.component';
import Utils from '../../utils/utils';
import AppUtils from '../../utils/app-utils';
import {LITE_APP_NAME} from '../../../const';
import {ActivatedRoute} from '@angular/router';
import {Content} from '../../base/content';
import {ActivityParameters} from '../../components/activity/activity.component';
import PermissionUtils from '../../utils/permission-utils';

@Component({
    selector: 'app-page-view',
    templateUrl: './page-view.component.html',
    styleUrls: ['./page-view.component.css']
})
export class PageViewComponent implements OnInit {

    config$: Observable<ShareListConfig>;

    title: string;
    tab: string;
    contentId: string;
    buttonDisabled = false;

    constructor(private service: ShareService,
                private confluence: ConfluenceService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.confluence.resize('es-expand');

        this.config$ = zip(Utils.combineParams(this.route), this.route.data).pipe(
            switchMap(([params, data]) =>
                zip(AppUtils.getAppState(params), this.linkConfig(params, data))
            ),
            map(([state, config]) => {
                const appName = state.isLite ? LITE_APP_NAME : '';
                this.tab = 'links';

                return Object.assign(state, {
                    spaceKey: config.space.key,
                    spaceEnabled: config.spaceEnabled,
                    hasRestrictions: config.hasRestrictions,
                    hasCreatePermission: PermissionUtils.hasCreatePermission(config.userIsAdmin, state.permissions),
                    hasEditPermission: PermissionUtils.hasEditPermission(config.userIsAdmin, state.permissions),
                    hasSendEmailPermission: PermissionUtils.hasSendEmailPermission(config.userIsAdmin, state.permissions),
                    hasDeletePermission: PermissionUtils.hasDeletePermission(config.userIsAdmin, state.permissions),
                    contentId: config.contentId,
                    appName,
                    userIsAdmin: config.userIsAdmin,
                    permissions: state.permissions
                });
            })
        );
    }

    linkConfig(paramMap, data): Observable<{
        space: Space;
        hasRestrictions: boolean;
        contentId: string;
        userIsAdmin: boolean;
        spaceEnabled: boolean;
    }> {
        const space = data.space as Space;
        const hasRestrictions = data.restrictions;
        const contentId = paramMap.get('contentId');
        const content = data.content as Content;
        this.title = content.title;

        return of({
            space,
            hasRestrictions,
            contentId,
            userIsAdmin: PermissionUtils.isAdminUser(paramMap),
            spaceEnabled: true
        });
    }

    createNew(config: ShareListConfig) {
        if (!config.hasRestrictions) {
            this.buttonDisabled = true;

            this.createShare(config.contentId, '', this.title)
                .subscribe(link => {
                    this.confluence.showDialog({
                        key: 'share-edition-popup',
                        customData: {
                            contentId: config.contentId,
                            uuid: link.uuid,
                            permissions: config.permissions
                        }
                    });
                    this.buttonDisabled = false;
                });
        }
    }

    close() {
        this.confluence.closeDialog();
    }

    changeTab(tab: string) {
        this.tab = tab;
    }

    getActivityParameters(config: ShareListConfig): ActivityParameters {
        return {
            level: 'PAGE',
            spaceKey: config.spaceKey,
            contentId: config.contentId,
            hasCreatePermission: config.hasCreatePermission,
            hasEditPermission: config.hasEditPermission,
            hasSendEmailPermission: config.hasSendEmailPermission,
            hasDeletePermission: config.hasDeletePermission
        };
    }

    private createShare(contentId: string,
                        name: string,
                        title: string): Observable<Share> {
        return this.service.create(contentId, name, title).pipe(
            catchError((err) => {
                this.confluence.closeDialog();
                throw err;
            }),
            tap(it => {
                this.confluence.emitEvent('link-update', JSON.stringify(it));
            }),
        );
    }
}
