import {ParamMap} from '@angular/router';
import Utils from './utils';
import {Observable, of} from 'rxjs';
import {Permissions, PermissionSettings} from '../confluence.service';

export default class PermissionUtils {

    public static getPermissions(query: ParamMap): Observable<Permissions> {
        return of(PermissionUtils.getPermissionsObj(query));
    }

    public static getPermissionsObj(query: ParamMap): Permissions {
        const usePermissionScheme = Utils.isTrue(query.get('permissionsEnabled')) &&
            !PermissionUtils.isAdminVal(query);

        return {
            canCreate: usePermissionScheme ? Utils.isTrue(query.get('hasCreatePermission')) : true,
            canEdit: usePermissionScheme ? Utils.isTrue(query.get('hasEditPermission')) : true,
            canDelete: usePermissionScheme ? Utils.isTrue(query.get('hasDeletePermission')) : true,
            canSendEmail: usePermissionScheme ? Utils.isTrue(query.get('hasSendEmailPermission')) : true
        };
    }

    public static getPermissionsGroups(permissions: PermissionSettings): PermissionSettings {
        return {
            enabled: permissions.enabled,
            viewOwnSharesOnPage: permissions.viewOwnSharesOnPage ? permissions.viewOwnSharesOnPage : [],
            viewAllSharesOnPage: permissions.viewAllSharesOnPage ? permissions.viewAllSharesOnPage : [],
            createNewShare: permissions.createNewShare ? permissions.createNewShare : [],
            editShare: permissions.editShare ? permissions.editShare : [],
            emailShare: permissions.emailShare ? permissions.emailShare : [],
            deleteShare: permissions.deleteShare ? permissions.deleteShare : [],
            viewSharesFromSpace: permissions.viewSharesFromSpace ? permissions.viewSharesFromSpace : []
        };
    }

    public static getPermissionsFromCustomData(data: any, permissions?: Permissions): Permissions {
        if (data.readOnly) {
            return {
                canCreate: false,
                canEdit: false,
                canDelete: data.permissions ? data.permissions.canDelete : false,
                canSendEmail: data.permissions ? data.permissions.canSendEmail : false
            };
        } else if (data.permissions) {
            return data.permissions;
        } else if (permissions) {
            return permissions;
        } else {
            return {
                canCreate: true,
                canEdit: true,
                canDelete: true,
                canSendEmail: true
            };
        }
    }

    public static isAdminUser(paramMap: ParamMap) {
        return paramMap.get('user_is_admin') === 'true';
    }

    public static hasCreatePermission(userIsAdmin: boolean,
                                      permissions: Permissions) {
        return userIsAdmin || permissions.canCreate;
    }

    public static hasEditPermission(userIsAdmin: boolean,
                                    permissions: Permissions) {
        return userIsAdmin || permissions.canEdit;
    }

    public static hasSendEmailPermission(userIsAdmin: boolean,
                                         permissions: Permissions) {
        return userIsAdmin || permissions.canSendEmail;
    }

    public static hasDeletePermission(userIsAdmin: boolean,
                                      permissions: Permissions) {
        return userIsAdmin || permissions.canDelete;
    }

    private static isAdminVal(params: ParamMap): boolean {
        return Utils.isTrue(params.get('user_is_admin'))
            || Utils.isTrue(params.get('user_is_space_admin'));
    }
}
