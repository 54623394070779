import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {AppState, ShareService} from '../../base/share.service';
import {ConfluenceService} from '../../confluence.service';
import {ActivatedRoute} from '@angular/router';
import Utils from '../../utils/utils';
import {ServerDataSource} from '../../components/app-table.component';
import {catchError, tap} from 'rxjs/operators';
import {Share, ShareFilterCriteria} from '../../base/share';

export interface ShareListConfig extends AppState {
    spaceKey?: string;
    spaceEnabled: boolean;
    hasRestrictions: boolean;
    contentId: string;
    appName?: string;
}

@Component({
    selector: 'app-share-table',
    templateUrl: './share-table.component.html',
    styleUrls: ['./share-table.component.css']
})
export class ShareTableComponent implements OnInit {

    showExpiredWasEnabled = false;

    @Input() config: ShareListConfig;

    filterForm = new FormGroup({
        showExpired: new FormControl(false)
    });

    title: string;

    buttonDisabled = false;

    constructor(private service: ShareService,
                private confluence: ConfluenceService,
                private route: ActivatedRoute,
                public source: ServerDataSource<Share, ShareFilterCriteria>) {
    }

    ngOnInit() {
        this.source.reportError = true;
        this.route.paramMap.subscribe(paramMap => {
            const contentId = paramMap.get('contentId');
            this.setupDatasource(contentId);
            this.setupForm(contentId);
        });

        const linkUpdate = this.confluence.observeEvent('link-update');
        linkUpdate.subscribe(() => {
            this.source.refresh();
        });
    }

    private setupForm(contentId: string) {
        this.filterForm.patchValue({
            showExpired: false
        });

        this.filterForm.valueChanges.subscribe(it => {
            if (it.showExpired) {
                this.showExpiredWasEnabled = true;
            }

            this.source.updateFilter({
                contents: [contentId],
                status: it.showExpired ? 'all' : 'active'
            });
        });
    }

    private setupDatasource(contentId: string) {
        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 10,
            filter: {
                contents: [contentId],
                status: 'active'
            }
        });
    }

    private createShare(contentId: string,
                        name: string): Observable<Share> {
        return this.service.create(contentId, name).pipe(
            catchError((err) => {
                this.confluence.closeDialog();
                throw err;
            }),
            tap(it => {
                this.confluence.emitEvent('link-update', JSON.stringify(it));
            }),
        );
    }

    createNew(config: ShareListConfig) {
        if (!config.hasRestrictions) {
            this.buttonDisabled = true;

            this.createShare(config.contentId, this.title)
                .subscribe(link => {
                    this.confluence.showDialog({
                            key: 'share-edition-popup',
                            customData: {
                                contentId: config.contentId,
                                uuid: link.uuid,
                                permissions: config.permissions
                            }
                        }
                    );
                    this.buttonDisabled = false;
                });
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    close() {
        this.confluence.closeDialog();
    }
}
