import {AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ShareActionService} from '../../share-action.service';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {ConfluenceService, PermissionSettings} from '../../confluence.service';
import {Observable, zip} from 'rxjs';
import {delay, map, shareReplay, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {AppService, GlobalSettings} from '../../app.service';
import Utils from '../../utils/utils';
import {LoaderService} from '../../loader.service';
import {CustomDomainComponent} from './custom-domain/custom-domain.component';
import CustomValidators, {urlValidator} from '../../utils/custom-validators';
import PermissionUtils from '../../utils/permission-utils';
import {workspaceValidator} from '../../utils/workspace-validator.directive';
import {SamlConfig} from './saml-config';

export interface GlobalViewSettingsComponentConfig {
    settings: GlobalSettings;
    permissions: PermissionSettings;
    isLite: boolean;
}

@Component({
    selector: 'app-global-settings',
    templateUrl: './global-settings.component.html',
    styleUrls: ['./global-settings.component.css']
})
export class GlobalSettingsComponent implements OnInit, AfterContentChecked, SamlConfig {

    defaultRelayState;
    assertionConsumerUrl;
    issuerId;

    @ViewChild('customDomainComponent') customDomainComponent: CustomDomainComponent;
    samlConfigTab = 'config';

    permissionForm = this.fb.group({
        enablePermissions: [false],
        viewOwnSharesOnPageUserGroups: [],
        viewShareOnPageUserGroups: [],
        createNewShareUserGroups: [],
        editShareUserGroups: [],
        deleteShareUserGroups: [],
        emailShareUserGroups: [],
        viewSharesFromSpaceUserGroups: []
    });

    form = this.fb.group({
        pageHeader: this.fb.group({
            customHeader: [],
            color: [],
            colorDarkMode: [],
            backgroundColor: [],
            backgroundColorDarkMode: [],
            locationSelect: [],
            headerLinkStyles: this.fb.group({
                color: [],
                colorDarkMode: [],
                fontSize: [],
                padding: [],
                margin: [],
                backgroundColor: [],
                backgroundColorDarkMode: [],
                hoverColor: [],
                hoverColorDarkMode: []
            }),
            headerButtonStyles: this.fb.group({
                fontSize: [],
                color: [],
                colorDarkMode: [],
                backgroundColor: [],
                backgroundColorDarkMode: [],
            }),
            logoHeight: [],
            logoWidth: [],
            hideHeaderLinks: [false],
            showHeader: [true],
            customHeaderLinks: []
        }),
        customBody: this.fb.group({
            showBackgroundImage: [false],
            backgroundColor: [],
            backgroundColorDarkMode: [],
            backgroundImage: ['', [Validators.maxLength(1000),
                Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]],
            backgroundImageDarkMode: ['', [Validators.maxLength(1000),
                Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]]
        }),
        pageFooter: this.fb.group({
            customFooter: [],
            color: [],
            colorDarkMode: [],
            backgroundColor: [],
            backgroundColorDarkMode: [],
            customFooterLinks: [],
            showFooter: [false],
            customFooterLinkFontSize: []
        }),
        mainFrame: this.fb.group({
            width: [],
            height: [],
            margin: [],
            padding: [],
            buttonColor: [],
            svgColor: [],
            buttonTextColor: [],
            boxShadow: [],
            borderWidth: [],
            borderStyle: [],
            borderColor: [],
            buttonColorDarkMode: [],
            buttonTextColorDarkMode: [],
            svgColorDarkMode: [],
            boxShadowColor: [],
            boxShadowColorDarkMode: [],
            borderColorDarkMode: [],
            borderRadius: []
        }),
        page: this.fb.group({
            fontFamily: [],
            genericFontFamily: [],
            fontSize: [],
            creatorDataFontSize: [],
            contentFontSize: []
        }),
        pageNavigation: this.fb.group({
            backgroundColor: [],
            backgroundColorDarkMode: [],
            backgroundImage: ['', [Validators.maxLength(1000),
                Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]],
            backgroundImageDarkMode: ['', [Validators.maxLength(1000),
                Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]],
            textColor: [],
            textColorDarkMode: [],
            fontSize: [],
            showBackgroundImage: [false],
            hover: [],
            hoverDarkMode: [],
            linkColor: [],
            linkColorDarkMode: []
        }),
        passwordPage: this.fb.group({
            fontFamily: [],
            genericFontFamily: []
        }),
        table: this.fb.group({
            borderWidth: [],
            borderStyle: [],
            borderColor: [],
            borderColorDarkMode: [],
            fontSize: []
        }),

        allowedIPs: ['', [CustomValidators.validateIP()]],
        emailCustom: [false],
        emailHost: [{value: '', disabled: true}],
        emailPort: [{value: '', disabled: true}],
        emailUsername: [{value: '', disabled: true}],
        emailFrom: [{value: '', disabled: true}],
        emailPersonalName: [{value: '', disabled: true}],
        emailPassword: [{value: '', disabled: true}],
        emailTitle: [null, Validators.required],
        emailContent: [null, Validators.required],
        emailTemplate: [null, Validators.required],
        removeDirectLinks: [false],
        enableCustomDomain: [false],
        enableCustomLandingPage: [false],
        customDomain: [{value: '', disabled: true}],
        customLandingPage: [{value: '', disabled: true}],
        customCsp: [],
        enableDarkMode: [false],
        showLogo: [true],
        showSearchForm: [true],
        showSubscribe: [true],
        showMyAccount: [true],
        disableVideoDownload: [false],
        titleAsLink: [false],
        analyticsID: [],
        logo: [null, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(524288)]],
        logoDark: [null, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(524288)]],
        favicon: [null, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(35840)]],
        samlEnabled: [false],
        loginUrl: ['', Validators.required],
        identifier: ['', Validators.required],
        workspace: ['', {
            validators: [Validators.required, Validators.minLength(3)],
            asyncValidators: [workspaceValidator(this.app, this)],
            updateOn: 'blur'
        }],
        identityProvider: [''],
        sameSiteCookieRestriction: [''],
        logoutUrl: [''],
        certificate: ['', Validators.required],
        samlDomains: ['']
    });

    $config: Observable<GlobalViewSettingsComponentConfig>;

    tab = 'permissions';

    constructor(private shareActionService: ShareActionService,
                private app: AppService,
                private fb: FormBuilder,
                private confluence: ConfluenceService,
                private loader: LoaderService,
                private route: ActivatedRoute,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.$config = zip(
            this.app.getGlobalSettings(),
            this.confluence.getAddonProperty('permissions', '{"enabled": false}'),
            Utils.isLiteApp$(this.route.parent.queryParamMap)
        ).pipe(
            map(([settings, permissions, isLite]) => {
                return {
                    settings,
                    permissions: PermissionUtils.getPermissionsGroups(permissions),
                    isLite
                };
            }),
            shareReplay(1)
        );

        this.$config.pipe(
            delay(100), // needed for multi-select
            tap(({settings, permissions, isLite}) => {
                this.defaultRelayState = settings.samlConfig.defaultRelayState;
                this.assertionConsumerUrl = settings.samlConfig.assertionConsumerUrl;
                this.issuerId = settings.samlConfig.issuerId;

                this.form.get('emailCustom').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('emailHost'),
                            this.form.get('emailPort'),
                            this.form.get('emailUsername'),
                            this.form.get('emailFrom'),
                            this.form.get('emailPersonalName'),
                            this.form.get('emailPassword')
                        ], v)
                );
                this.form.get('enableCustomDomain').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('customDomain')
                        ], v)
                );
                this.form.get('enableCustomLandingPage').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('customLandingPage')
                        ], v)
                );
                this.form.get('samlEnabled').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('loginUrl'),
                            this.form.get('identifier'),
                            this.form.get('logoutUrl'),
                            this.form.get('certificate'),
                            this.form.get('workspace'),
                            this.form.get('identityProvider')
                        ], v)
                );

                const formVal = {
                    ...settings,
                    pageHeader: {
                        hideHeaderLinks: settings.pageSettings.pageHeader.hideHeaderLinks,
                        showHeader: settings.pageSettings.pageHeader.showHeader,
                        customHeader: settings.pageSettings.pageHeader.customHeader,
                        color: settings.pageSettings.pageHeader.color,
                        colorDarkMode: settings.pageSettings.pageHeader.colorDarkMode,
                        backgroundColor: settings.pageSettings.pageHeader.backgroundColor,
                        backgroundColorDarkMode: settings.pageSettings.pageHeader.backgroundColorDarkMode,
                        locationSelect: settings.pageSettings.pageHeader.locationSelect,
                        headerLinkStyles: {
                            color: settings.pageSettings.pageHeader.headerLinkStyles.color,
                            colorDarkMode: settings.pageSettings.pageHeader.headerLinkStyles.colorDarkMode,
                            fontSize: settings.pageSettings.pageHeader.headerLinkStyles.fontSize,
                            padding: settings.pageSettings.pageHeader.headerLinkStyles.padding,
                            margin: settings.pageSettings.pageHeader.headerLinkStyles.margin,
                            backgroundColor: settings.pageSettings.pageHeader.headerLinkStyles.backgroundColor,
                            backgroundColorDarkMode: settings.pageSettings.pageHeader.headerLinkStyles.backgroundColorDarkMode,
                            hoverColor: settings.pageSettings.pageHeader.headerLinkStyles.hoverColor,
                            hoverColorDarkMode: settings.pageSettings.pageHeader.headerLinkStyles.hoverColorDarkMode
                        },
                        headerButtonStyles: {
                            fontSize: settings.pageSettings.pageHeader.headerButtonStyles.fontSize,
                            color: settings.pageSettings.pageHeader.headerButtonStyles.color,
                            colorDarkMode: settings.pageSettings.pageHeader.headerButtonStyles.colorDarkMode,
                            backgroundColor: settings.pageSettings.pageHeader.headerButtonStyles.backgroundColor,
                            backgroundColorDarkMode: settings.pageSettings.pageHeader.headerButtonStyles.backgroundColorDarkMode,
                        },
                        logoHeight: settings.pageSettings.pageHeader.logoHeight,
                        logoWidth: settings.pageSettings.pageHeader.logoWidth,
                        customHeaderLinks: settings.pageSettings.pageHeader.customHeaderLinks
                    },
                    customBody: {
                        showBackgroundImage: settings.pageSettings.customBody.showBackgroundImage,
                        backgroundColor: settings.pageSettings.customBody.backgroundColor,
                        backgroundColorDarkMode: settings.pageSettings.customBody.backgroundColorDarkMode,
                        backgroundImage: settings.pageSettings.customBody.backgroundImage,
                        backgroundImageDarkMode: settings.pageSettings.customBody.backgroundImageDarkMode
                    },
                    pageFooter: {
                        showFooter: settings.pageSettings.pageFooter.showFooter,
                        customFooter: settings.pageSettings.pageFooter.customFooter,
                        color: settings.pageSettings.pageFooter.color,
                        colorDarkMode: settings.pageSettings.pageFooter.colorDarkMode,
                        backgroundColor: settings.pageSettings.pageFooter.backgroundColor,
                        backgroundColorDarkMode: settings.pageSettings.pageFooter.backgroundColorDarkMode,
                        customFooterLinks: settings.pageSettings.pageFooter.customFooterLinks,
                        customFooterLinkFontSize: settings.pageSettings.pageFooter.customFooterLinkFontSize
                    },
                    mainFrame: {
                        width: settings.pageSettings.mainFrame.width,
                        height: settings.pageSettings.mainFrame.height,
                        margin: settings.pageSettings.mainFrame.margin,
                        padding: settings.pageSettings.mainFrame.padding,
                        buttonColor: settings.pageSettings.mainFrame.buttonColor,
                        svgColor: settings.pageSettings.mainFrame.svgColor,
                        buttonTextColor: settings.pageSettings.mainFrame.buttonTextColor,
                        boxShadow: settings.pageSettings.mainFrame.boxShadow,
                        borderWidth: settings.pageSettings.mainFrame.borderWidth,
                        borderStyle: settings.pageSettings.mainFrame.borderStyle,
                        borderColor: settings.pageSettings.mainFrame.borderColor,
                        buttonColorDarkMode: settings.pageSettings.mainFrame.buttonColorDarkMode,
                        buttonTextColorDarkMode: settings.pageSettings.mainFrame.buttonTextColorDarkMode,
                        svgColorDarkMode: settings.pageSettings.mainFrame.svgColorDarkMode,
                        boxShadowColor: settings.pageSettings.mainFrame.boxShadowColor,
                        boxShadowColorDarkMode: settings.pageSettings.mainFrame.boxShadowColorDarkMode,
                        borderColorDarkMode: settings.pageSettings.mainFrame.borderColorDarkMode,
                        borderRadius: settings.pageSettings.mainFrame.borderRadius
                    },
                    page: {
                        fontFamily: settings.pageSettings.page.fontFamily,
                        genericFontFamily: settings.pageSettings.page.genericFontFamily,
                        fontSize: settings.pageSettings.page.fontSize,
                        creatorDataFontSize: settings.pageSettings.page.creatorDataFontSize,
                        contentFontSize: settings.pageSettings.page.contentFontSize
                    },
                    pageNavigation: {
                        backgroundColor: settings.pageSettings.pageNavigation.backgroundColor,
                        backgroundColorDarkMode: settings.pageSettings.pageNavigation.backgroundColorDarkMode,
                        backgroundImage: settings.pageSettings.pageNavigation.backgroundImage,
                        backgroundImageDarkMode: settings.pageSettings.pageNavigation.backgroundImageDarkMode,
                        textColor: settings.pageSettings.pageNavigation.textColor,
                        textColorDarkMode: settings.pageSettings.pageNavigation.textColorDarkMode,
                        fontSize: settings.pageSettings.pageNavigation.fontSize,
                        showBackgroundImage: settings.pageSettings.pageNavigation.showBackgroundImage,
                        hover: settings.pageSettings.pageNavigation.hover,
                        hoverDarkMode: settings.pageSettings.pageNavigation.hoverDarkMode,
                        linkColor: settings.pageSettings.pageNavigation.linkColor,
                        linkColorDarkMode: settings.pageSettings.pageNavigation.linkColorDarkMode
                    },
                    passwordPage: {
                        fontFamily: settings.pageSettings.passwordPage.fontFamily,
                        genericFontFamily: settings.pageSettings.passwordPage.genericFontFamily
                    },
                    table: {
                        borderWidth: settings.pageSettings.table.borderWidth,
                        borderStyle: settings.pageSettings.table.borderStyle,
                        borderColor: settings.pageSettings.table.borderColor,
                        borderColorDarkMode: settings.pageSettings.table.borderColorDarkMode,
                        fontSize: settings.pageSettings.table.fontSize
                    },
                    allowedIPs: settings.allowedIPs,
                    emailCustom: settings.emailSettings.custom,
                    emailHost: settings.emailSettings.host,
                    emailPort: settings.emailSettings.port,
                    emailUsername: settings.emailSettings.username,
                    emailFrom: settings.emailSettings.from,
                    emailPersonalName: settings.emailSettings.personalName,
                    emailPassword: settings.emailSettings.password,
                    emailTitle: settings.emailTemplate.title,
                    emailContent: settings.emailTemplate.content,
                    emailTemplate: settings.emailTemplate.template,
                    enableCustomDomain: settings.customDomain.enabled,
                    enableCustomLandingPage: settings.customDomain.enableRedirectUrl,
                    customDomain: settings.customDomain.domain,
                    customLandingPage: settings.customDomain.redirectUrl,
                    customCsp: settings.customCsp,
                    enableDarkMode: settings.pageSettings.darkModeEnabled,
                    showLogo: settings.pageSettings.showLogo,
                    showSearchForm: settings.pageSettings.showSearchForm,
                    showSubscribe: settings.pageSettings.showSubscribe,
                    showMyAccount: settings.pageSettings.showMyAccount,
                    disableVideoDownload: settings.pageSettings.disableVideoDownload,
                    titleAsLink: settings.pageSettings.titleAsLink,
                    logo: Utils.modelToFile(settings.pageSettings.logo),
                    logoDark: Utils.modelToFile(settings.pageSettings.logoDark),
                    favicon: Utils.modelToFile(settings.pageSettings.favicon),
                    analyticsID: settings.pageSettings.analyticsID,
                    samlEnabled: settings.samlConfig.samlEnabled,
                    loginUrl: settings.samlConfig.loginUrl,
                    identifier: settings.samlConfig.identifier,
                    logoutUrl: settings.samlConfig.logoutUrl,
                    certificate: settings.samlConfig.certificate,
                    workspace: settings.samlConfig.workspace,
                    identityProvider: settings.samlConfig.identityProvider ? settings.samlConfig.identityProvider : 'OTHER',
                    sameSiteCookieRestriction: settings.sameSiteCookieRestriction ? settings.sameSiteCookieRestriction : 'LAX',
                    samlDomains: settings.samlConfig.domains
                };

                const emailCustomControl = this.form.get('emailCustom');
                const emailHostControl = this.form.get('emailHost');
                const emailPortControl = this.form.get('emailPort');
                const emailUsernameControl = this.form.get('emailUsername');

                // Custom email
                emailCustomControl.valueChanges.subscribe(value => {
                    if (value) {
                        emailHostControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required)
                        ]);
                        emailPortControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required)
                        ]);
                        emailUsernameControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required)
                        ]);
                    } else {
                        emailHostControl.clearValidators();
                        emailPortControl.clearValidators();
                        emailUsernameControl.clearValidators();
                    }
                    emailHostControl.updateValueAndValidity();
                    emailPortControl.updateValueAndValidity();
                    emailUsernameControl.updateValueAndValidity();
                });

                const enableCustomDomainControl = this.form.get('enableCustomDomain');
                const customDomainControl = this.form.get('customDomain');

                // Custom domain
                enableCustomDomainControl.valueChanges.subscribe(value => {
                    if (value) {
                        customDomainControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required)
                        ]);
                    } else {
                        customDomainControl.clearValidators();
                    }
                    customDomainControl.updateValueAndValidity();
                });

                const enableCustomLandingPageControl = this.form.get('enableCustomLandingPage');
                const customLandingPageControl = this.form.get('customLandingPage');

                // Custom Domain Landing Page
                enableCustomLandingPageControl.valueChanges.subscribe(value => {
                    if (value) {
                        customLandingPageControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required),
                            urlValidator()
                        ]);
                    } else {
                        customLandingPageControl.clearValidators();
                    }
                    customLandingPageControl.updateValueAndValidity();
                });
                this.form.patchValue(formVal);

                this.permissionForm.patchValue({
                    enablePermissions: permissions.enabled,
                    viewOwnSharesOnPageUserGroups: this.mapUserGroup(permissions.viewOwnSharesOnPage),
                    viewShareOnPageUserGroups: this.mapUserGroup(permissions.viewAllSharesOnPage),
                    createNewShareUserGroups: this.mapUserGroup(permissions.createNewShare),
                    editShareUserGroups: this.mapUserGroup(permissions.editShare),
                    emailShareUserGroups: this.mapUserGroup(permissions.emailShare),
                    deleteShareUserGroups: this.mapUserGroup(permissions.deleteShare),
                    viewSharesFromSpaceUserGroups: this.mapUserGroup(permissions.viewSharesFromSpace)
                });

                this.permissionForm.valueChanges.subscribe((value) => {
                    const permissionsToSet = {
                        enabled: value.enablePermissions,
                        viewOwnSharesOnPage: value.viewOwnSharesOnPageUserGroups,
                        viewAllSharesOnPage: value.viewShareOnPageUserGroups,
                        createNewShare: value.createNewShareUserGroups,
                        editShare: value.editShareUserGroups,
                        emailShare: value.emailShareUserGroups,
                        deleteShare: value.deleteShareUserGroups,
                        viewSharesFromSpace: value.viewSharesFromSpaceUserGroups
                    };

                    this.confluence.setAddonProperty('permissions', permissionsToSet);
                });
            })
        ).subscribe();
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    mapUserGroup(groups) {
        let result = [];
        if (!!groups) {
            result = groups.map(userGroup => ({
                id: userGroup,
                text: userGroup
            }));
        }
        return result;
    }

    deleteAll() {
        this.shareActionService.deleteAll();
    }

    confirmRestore() {
        this.shareActionService.confirmRestore(this.restoreDefaultPageSettings.bind(this));
    }

    migrateLite() {
        this.shareActionService.migrateLite().subscribe();
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    save() {
        if (this.form.status === 'VALID') {
            this.update();
        }
    }

    saveAndCheckSSO() {
        if (this.form.status === 'VALID' && this.form.get('samlEnabled').value) {
            this.update();
            const checkURLLink = encodeURI(this.assertionConsumerUrl + '/check?workspace=' + this.form.get('workspace').value);
            window.open(checkURLLink, '_blank');
        }
    }

    private update() {
        this.loader.fullscreen(true);
        const model = this.form.getRawValue();

        zip(Utils.fileToModel(model.favicon), Utils.fileToModel(model.logo), Utils.fileToModel(model.logoDark))
            .pipe(map(([favicon, logo, logoDark]) => {
                const newSettings: GlobalSettings = {
                    allowedIPs: model.allowedIPs,
                    emailSettings: {
                        custom: model.emailCustom,
                        host: model.emailHost,
                        port: model.emailPort,
                        username: model.emailUsername,
                        from: model.emailFrom,
                        personalName: model.emailPersonalName,
                        password: model.emailPassword,
                    },
                    emailTemplate: {
                        title: model.emailTitle,
                        content: model.emailContent,
                        template: model.emailTemplate
                    },
                    customDomain: {
                        enabled: model.enableCustomDomain,
                        domain: model.customDomain,
                        enableRedirectUrl: model.enableCustomLandingPage,
                        redirectUrl: model.customLandingPage
                    },
                    pageSettings: {
                        darkModeEnabled: model.enableDarkMode,
                        pageHeader: {
                            customHeader: model.pageHeader.customHeader,
                            color: model.pageHeader.color,
                            colorDarkMode: model.pageHeader.colorDarkMode,
                            backgroundColor: model.pageHeader.backgroundColor,
                            backgroundColorDarkMode: model.pageHeader.backgroundColorDarkMode,
                            locationSelect: model.pageHeader.locationSelect,
                            headerLinkStyles: {
                                color: model.pageHeader.headerLinkStyles.color,
                                colorDarkMode: model.pageHeader.headerLinkStyles.colorDarkMode,
                                fontSize: model.pageHeader.headerLinkStyles.fontSize,
                                padding: model.pageHeader.headerLinkStyles.padding,
                                margin: model.pageHeader.headerLinkStyles.margin,
                                backgroundColor: model.pageHeader.headerLinkStyles.backgroundColor,
                                backgroundColorDarkMode: model.pageHeader.headerLinkStyles.backgroundColorDarkMode,
                                hoverColor: model.pageHeader.headerLinkStyles.hoverColor,
                                hoverColorDarkMode: model.pageHeader.headerLinkStyles.hoverColorDarkMode
                            },
                            headerButtonStyles: {
                                fontSize: model.pageHeader.headerButtonStyles.fontSize,
                                color: model.pageHeader.headerButtonStyles.color,
                                colorDarkMode: model.pageHeader.headerButtonStyles.colorDarkMode,
                                backgroundColor: model.pageHeader.headerButtonStyles.backgroundColor,
                                backgroundColorDarkMode: model.pageHeader.headerButtonStyles.backgroundColorDarkMode,
                            },
                            logoHeight: model.pageHeader.logoHeight,
                            logoWidth: model.pageHeader.logoWidth,
                            hideHeaderLinks: model.pageHeader.hideHeaderLinks,
                            showHeader: model.pageHeader.showHeader,
                            customHeaderLinks: model.pageHeader.customHeaderLinks,
                        },
                        customBody: {
                            showBackgroundImage: model.customBody.showBackgroundImage,
                            backgroundColor: model.customBody.backgroundColor,
                            backgroundColorDarkMode: model.customBody.backgroundColorDarkMode,
                            backgroundImage: model.customBody.backgroundImage,
                            backgroundImageDarkMode: model.customBody.backgroundImageDarkMode
                        },
                        pageFooter: {
                            customFooter: model.pageFooter.customFooter,
                            color: model.pageFooter.color,
                            colorDarkMode: model.pageFooter.colorDarkMode,
                            backgroundColor: model.pageFooter.backgroundColor,
                            backgroundColorDarkMode: model.pageFooter.backgroundColorDarkMode,
                            customFooterLinks: model.pageFooter.customFooterLinks,
                            customFooterLinkFontSize: model.pageFooter.customFooterLinkFontSize,
                            showFooter: model.pageFooter.showFooter
                        },
                        mainFrame: {
                            width: model.mainFrame.width,
                            height: model.mainFrame.height,
                            margin: model.mainFrame.margin,
                            padding: model.mainFrame.padding,
                            buttonColor: model.mainFrame.buttonColor,
                            svgColor: model.mainFrame.svgColor,
                            buttonTextColor: model.mainFrame.buttonTextColor,
                            boxShadow: model.mainFrame.boxShadow,
                            borderWidth: model.mainFrame.borderWidth,
                            borderStyle: model.mainFrame.borderStyle,
                            borderColor: model.mainFrame.borderColor,
                            buttonColorDarkMode: model.mainFrame.buttonColorDarkMode,
                            buttonTextColorDarkMode: model.mainFrame.buttonTextColorDarkMode,
                            svgColorDarkMode: model.mainFrame.svgColorDarkMode,
                            boxShadowColor: model.mainFrame.boxShadowColor,
                            boxShadowColorDarkMode: model.mainFrame.boxShadowColorDarkMode,
                            borderColorDarkMode: model.mainFrame.borderColorDarkMode,
                            borderRadius: model.mainFrame.borderRadius
                        },
                        page: {
                            fontFamily: model.page.fontFamily,
                            genericFontFamily: model.page.genericFontFamily,
                            fontSize: model.page.fontSize,
                            creatorDataFontSize: model.page.creatorDataFontSize,
                            contentFontSize: model.page.contentFontSize
                        },
                        pageNavigation: {
                            backgroundColor: model.pageNavigation.backgroundColor,
                            backgroundColorDarkMode: model.pageNavigation.backgroundColorDarkMode,
                            backgroundImage: model.pageNavigation.backgroundImage,
                            backgroundImageDarkMode: model.pageNavigation.backgroundImageDarkMode,
                            textColor: model.pageNavigation.textColor,
                            textColorDarkMode: model.pageNavigation.textColorDarkMode,
                            fontSize: model.pageNavigation.fontSize,
                            showBackgroundImage: model.pageNavigation.showBackgroundImage,
                            hover: model.pageNavigation.hover,
                            hoverDarkMode: model.pageNavigation.hoverDarkMode,
                            linkColor: model.pageNavigation.linkColor,
                            linkColorDarkMode: model.pageNavigation.linkColorDarkMode
                        },
                        passwordPage: {
                            fontFamily: model.passwordPage.fontFamily,
                            genericFontFamily: model.passwordPage.genericFontFamily
                        },
                        table: {
                            borderWidth: model.table.borderWidth,
                            borderStyle: model.table.borderStyle,
                            borderColor: model.table.borderColor,
                            borderColorDarkMode: model.table.borderColorDarkMode,
                            fontSize: model.table.fontSize
                        },
                        showLogo: model.showLogo,
                        showSubscribe: model.showSubscribe,
                        showMyAccount: model.showMyAccount,
                        analyticsID: model.analyticsID,
                        logo,
                        logoDark,
                        favicon,
                        showSearchForm: model.showSearchForm,
                        disableVideoDownload: model.disableVideoDownload,
                        titleAsLink: model.titleAsLink
                    },
                    removeDirectLinks: model.removeDirectLinks,
                    samlConfig: {
                        samlEnabled: model.samlEnabled,
                        loginUrl: model.loginUrl,
                        identifier: model.identifier,
                        logoutUrl: model.logoutUrl,
                        certificate: model.certificate,
                        workspace: model.workspace,
                        identityProvider: model.identityProvider,
                        domains: model.domains
                    },
                    customCsp: model.customCsp,
                    sameSiteCookieRestriction: model.sameSiteCookieRestriction
                };
                return newSettings;
            })).subscribe((newSettings) =>
            this.app.updateGlobalSettings(newSettings)
                .subscribe((value) => {
                    if (!!value.customDomain && !!value.customDomain.status) {
                        this.loader.fullscreen(false);
                        this.tab = 'customDomain';
                        if (this.customDomainComponent) {
                            this.customDomainComponent.changeControlValidity(value.customDomain.status);
                        }
                    } else {
                        this.displaySuccessfulFlag();
                    }
                }));

    }

    restoreDefaultEmailTemplate() {
        this.loader.fullscreen(true);
        this.app.restoreDefaultEmailTemplate()
            .subscribe(result => {
                this.form.get('emailTitle').setValue(result.emailTemplate.title);
                this.form.get('emailContent').setValue(result.emailTemplate.content);
                this.form.get('emailTemplate').setValue(result.emailTemplate.template);
                this.loader.fullscreen(false);
            });
    }

    restoreDefaultPageSettings() {
        this.app.restoreDefaultPageSettings()
            .subscribe(result => {
                this.form.get('enableDarkMode').setValue(result.pageSettings.darkModeEnabled);
                this.form.get('pageHeader.hideHeaderLinks').setValue(result.pageSettings.pageHeader.hideHeaderLinks);
                this.form.get('pageHeader.showHeader').setValue(result.pageSettings.pageHeader.showHeader);
                this.form.get('showLogo').setValue(result.pageSettings.showLogo);
                this.form.get('showSubscribe').setValue(result.pageSettings.showSubscribe);
                this.form.get('showMyAccount').setValue(result.pageSettings.showMyAccount);
                this.form.get('showSearchForm').setValue(result.pageSettings.showSearchForm);
                this.form.get('disableVideoDownload').setValue(result.pageSettings.disableVideoDownload);
                this.form.get('titleAsLink').setValue(result.pageSettings.titleAsLink);
                this.form.get('pageHeader.customHeader').setValue(result.pageSettings.pageHeader.customHeader);
                this.form.get('pageHeader.color').setValue(result.pageSettings.pageHeader.color);
                this.form.get('pageHeader.colorDarkMode').setValue(result.pageSettings.pageHeader.colorDarkMode);
                this.form.get('pageHeader.backgroundColor').setValue(result.pageSettings.pageHeader.backgroundColor);
                this.form.get('pageHeader.backgroundColorDarkMode').setValue(result.pageSettings.pageHeader.backgroundColorDarkMode);
                this.form.get('pageHeader.locationSelect').setValue(result.pageSettings.pageHeader.locationSelect);
                this.form.get('pageHeader.headerLinkStyles.color').setValue(result.pageSettings.pageHeader.headerLinkStyles.color);
                this.form.get('pageHeader.headerLinkStyles.colorDarkMode')
                    .setValue(result.pageSettings.pageHeader.headerLinkStyles.colorDarkMode);
                this.form.get('pageHeader.headerLinkStyles.fontSize').setValue(result.pageSettings.pageHeader.headerLinkStyles.fontSize);
                this.form.get('pageHeader.headerLinkStyles.padding').setValue(result.pageSettings.pageHeader.headerLinkStyles.padding);
                this.form.get('pageHeader.headerLinkStyles.margin').setValue(result.pageSettings.pageHeader.headerLinkStyles.margin);
                this.form.get('pageHeader.headerButtonStyles.fontSize')
                    .setValue(result.pageSettings.pageHeader.headerButtonStyles.fontSize);
                this.form.get('pageHeader.headerButtonStyles.color').setValue(result.pageSettings.pageHeader.headerButtonStyles.color);
                this.form.get('pageHeader.headerButtonStyles.colorDarkMode')
                    .setValue(result.pageSettings.pageHeader.headerButtonStyles.colorDarkMode);
                this.form.get('pageHeader.headerButtonStyles.backgroundColor')
                    .setValue(result.pageSettings.pageHeader.headerButtonStyles.backgroundColor);
                this.form.get('pageHeader.headerButtonStyles.backgroundColorDarkMode')
                    .setValue(result.pageSettings.pageHeader.headerButtonStyles.backgroundColorDarkMode);
                this.form.get('pageHeader.logoHeight').setValue(result.pageSettings.pageHeader.logoHeight);
                this.form.get('pageHeader.logoWidth').setValue(result.pageSettings.pageHeader.logoWidth);
                this.form.get('customBody.showBackgroundImage').setValue(result.pageSettings.customBody.showBackgroundImage);
                this.form.get('customBody.backgroundColor').setValue(result.pageSettings.customBody.backgroundColor);
                this.form.get('customBody.backgroundColorDarkMode').setValue(result.pageSettings.customBody.backgroundColorDarkMode);
                this.form.get('customBody.backgroundImage').setValue(result.pageSettings.customBody.backgroundImage);
                this.form.get('customBody.backgroundImageDarkMode').setValue(result.pageSettings.customBody.backgroundImageDarkMode);
                this.form.get('pageFooter.showFooter').setValue(result.pageSettings.pageFooter.showFooter);
                this.form.get('pageFooter.customFooter').setValue(result.pageSettings.pageFooter.customFooter);
                this.form.get('pageFooter.color').setValue(result.pageSettings.pageFooter.color);
                this.form.get('pageFooter.colorDarkMode').setValue(result.pageSettings.pageFooter.colorDarkMode);
                this.form.get('pageFooter.backgroundColor').setValue(result.pageSettings.pageFooter.backgroundColor);
                this.form.get('pageFooter.backgroundColorDarkMode').setValue(result.pageSettings.pageFooter.backgroundColorDarkMode);
                this.form.get('pageFooter.customFooterLinks').setValue(result.pageSettings.pageFooter.customFooterLinks);
                this.form.get('logo').setValue(Utils.modelToFile(result.pageSettings.logo));
                this.form.get('logoDark').setValue(Utils.modelToFile(result.pageSettings.logoDark));
                this.form.get('favicon').setValue(Utils.modelToFile(result.pageSettings.favicon));
                this.form.get('analyticsID').setValue(result.pageSettings.analyticsID);
                this.form.get('mainFrame.width').setValue(result.pageSettings.mainFrame.width);
                this.form.get('mainFrame.height').setValue(result.pageSettings.mainFrame.height);
                this.form.get('mainFrame.margin').setValue(result.pageSettings.mainFrame.margin);
                this.form.get('mainFrame.padding').setValue(result.pageSettings.mainFrame.padding);
                this.form.get('mainFrame.buttonColor').setValue(result.pageSettings.mainFrame.buttonColor);
                this.form.get('mainFrame.svgColor').setValue(result.pageSettings.mainFrame.svgColor);
                this.form.get('mainFrame.buttonTextColor').setValue(result.pageSettings.mainFrame.buttonTextColor);
                this.form.get('mainFrame.boxShadow').setValue(result.pageSettings.mainFrame.boxShadow);
                this.form.get('mainFrame.borderWidth').setValue(result.pageSettings.mainFrame.borderWidth);
                this.form.get('mainFrame.borderStyle').setValue(result.pageSettings.mainFrame.borderStyle);
                this.form.get('mainFrame.borderColor').setValue(result.pageSettings.mainFrame.borderColor);
                this.form.get('mainFrame.buttonColorDarkMode').setValue(result.pageSettings.mainFrame.buttonColorDarkMode);
                this.form.get('mainFrame.buttonTextColorDarkMode').setValue(result.pageSettings.mainFrame.buttonTextColorDarkMode);
                this.form.get('mainFrame.svgColorDarkMode').setValue(result.pageSettings.mainFrame.svgColorDarkMode);
                this.form.get('mainFrame.boxShadowColor').setValue(result.pageSettings.mainFrame.boxShadowColor);
                this.form.get('mainFrame.boxShadowColorDarkMode').setValue(result.pageSettings.mainFrame.boxShadowColorDarkMode);
                this.form.get('mainFrame.borderColorDarkMode').setValue(result.pageSettings.mainFrame.borderColorDarkMode);
                this.form.get('mainFrame.borderRadius').setValue(result.pageSettings.mainFrame.borderRadius);
                this.form.get('passwordPage.fontFamily').setValue(result.pageSettings.passwordPage.fontFamily);
                this.form.get('pageNavigation.backgroundColor').setValue(result.pageSettings.pageNavigation.backgroundColor);
                this.form.get('pageNavigation.backgroundColorDarkMode')
                    .setValue(result.pageSettings.pageNavigation.backgroundColorDarkMode);
                this.form.get('pageNavigation.backgroundImage').setValue(result.pageSettings.pageNavigation.backgroundImage);
                this.form.get('pageNavigation.backgroundImageDarkMode')
                    .setValue(result.pageSettings.pageNavigation.backgroundImageDarkMode);
                this.form.get('pageNavigation.textColor').setValue(result.pageSettings.pageNavigation.textColor);
                this.form.get('pageNavigation.textColorDarkMode').setValue(result.pageSettings.pageNavigation.textColorDarkMode);
                this.form.get('pageNavigation.fontSize').setValue(result.pageSettings.pageNavigation.fontSize);
                this.form.get('pageNavigation.showBackgroundImage').setValue(result.pageSettings.pageNavigation.showBackgroundImage);
                this.form.get('passwordPage.genericFontFamily').setValue(result.pageSettings.passwordPage.genericFontFamily);
                this.form.get('page.fontFamily').setValue(result.pageSettings.page.fontFamily);
                this.form.get('page.genericFontFamily').setValue(result.pageSettings.page.genericFontFamily);
                this.form.get('page.fontSize').setValue(result.pageSettings.page.fontSize);
                this.form.get('table.borderWidth').setValue(result.pageSettings.table.borderWidth);
                this.form.get('table.borderStyle').setValue(result.pageSettings.table.borderStyle);
                this.form.get('table.borderColor').setValue(result.pageSettings.table.borderColor);
                this.form.get('table.borderColorDarkMode').setValue(result.pageSettings.table.borderColorDarkMode);
                this.form.get('pageFooter.customFooterLinkFontSize').setValue(result.pageSettings.pageFooter.customFooterLinkFontSize);
                this.form.get('table.fontSize').setValue(result.pageSettings.table.fontSize);
                this.form.get('pageNavigation.hover').setValue(result.pageSettings.pageNavigation.hover);
                this.form.get('pageNavigation.hoverDarkMode').setValue(result.pageSettings.pageNavigation.hoverDarkMode);
                this.form.get('pageNavigation.linkColor').setValue(result.pageSettings.pageNavigation.linkColor);
                this.form.get('pageNavigation.linkColorDarkMode').setValue(result.pageSettings.pageNavigation.linkColorDarkMode);
                this.form.get('pageHeader.customHeaderLinks').setValue(result.pageSettings.pageHeader.customHeaderLinks);
                this.form.get('page.creatorDataFontSize').setValue(result.pageSettings.page.creatorDataFontSize);
                this.form.get('page.contentFontSize').setValue(result.pageSettings.page.contentFontSize);
                this.form.get('pageHeader.headerLinkStyles.backgroundColor')
                    .setValue(result.pageSettings.pageHeader.headerLinkStyles.backgroundColor);
                this.form.get('pageHeader.headerLinkStyles.backgroundColorDarkMode')
                    .setValue(result.pageSettings.pageHeader.headerLinkStyles.backgroundColorDarkMode);
                this.form.get('pageHeader.headerLinkStyles.hoverColor')
                    .setValue(result.pageSettings.pageHeader.headerLinkStyles.hoverColor);
                this.form.get('pageHeader.headerLinkStyles.hoverColorDarkMode')
                    .setValue(result.pageSettings.pageHeader.headerLinkStyles.hoverColorDarkMode);
            });
    }

    saveButtonVisible() {
        return this.tab !== 'actions' && this.tab !== 'permissions' && !this.isSamlConfigTab();
    }

    isSamlConfigTab() {
        return this && this.isSamlConfig() && (this.samlConfigTab === 'workspace' || this.samlConfigTab === 'domains');
    }

    isSamlConfig() {
        return this.tab === 'samlConfig';
    }

    saveButtonDisabled(): boolean {
        return !this.form.valid || this.loader.inProgress();
    }

    workspaceConfigDisabled(): boolean {
        return !this.form.valid || !this.form.get('samlEnabled').value;
    }

    displaySuccessfulFlag(body = 'Settings updated successfully') {
        this.loader.fullscreen(false);
        AP.flag.create({
            title: 'External Share | Success',
            body,
            type: 'success',
            close: 'auto'
        });
    }

    isAccessInvalid() {
        return this.isInvalid('allowedIPs');
    }

    isCustomEmailInvalid() {
        return this.form.get('emailCustom').value
            && this.isAnyInvalid('emailHost', 'emailPort', 'emailUsername');
    }

    isAnyEmailTemplateInvalid() {
        return this.isAnyInvalid('emailTitle', 'emailContent', 'emailTemplate');
    }

    isCustomDomainInvalid() {
        return this.form.get('enableCustomDomain').value
            && this.isInvalid('customDomain');
    }

    isCustomLandingPageInvalid() {
        return this.form.get('enableCustomLandingPage').value
            && this.isInvalid('customLandingPage');
    }

    isPageCustomizationInvalid() {
        return false;
    }

    private isAnyInvalid(...args: any[]): boolean {
        for (const arg of args) {
            if (this.isInvalid(arg)) {
                return true;
            }
        }
        return false;
    }

    private isInvalid(name: string): boolean {
        const control = this.form.get(name);
        return this.isTouchedOrDirty(control) && control.invalid;
    }

    private isTouchedOrDirty(control: AbstractControl) {
        return control.dirty || control.touched;
    }

    changeTab(tab: string) {
        if (this.shouldMarkCustomEmailAsTouched()) {
            this.markCustomEmailAsTouched();
        }
        this.tab = tab;
    }

    private shouldMarkCustomEmailAsTouched(): boolean {
        return this.tab === 'customEmail'
            && this.form.get('emailCustom').value;
    }

    private markCustomEmailAsTouched() {
        this.form.get('emailHost').markAsTouched();
        this.form.get('emailPort').markAsTouched();
        this.form.get('emailUsername').markAsTouched();
    }

    isSamlConfigInvalid() {
        return this.form.get('samlEnabled').value
            && this.isAnyInvalid('loginUrl', 'identifier', 'workspace', 'certificate');
    }

    changeSAMLConfigTab(tab: string) {
        this.samlConfigTab = tab;
    }

    private setState(control: AbstractControl[], enabled: boolean, opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }) {
        for (const c of control) {
            if (enabled) {
                c.enable(opts);
            } else {
                c.disable(opts);
            }
        }
    }
}
