import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    '   <g fill-rule="evenodd"><circle fill="currentColor" cx="12" cy="12" r="10"></circle>' +
    // tslint:disable-next-line:max-line-length
    '       <path d="M9.707 11.293a1 1 0 10-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 10-1.414-1.414L11 12.586l-1.293-1.293z" fill="white"></path>' +
    '   </g>' +
    '</svg>';

@Directive({
    selector: '[appVerifyIcon]'
})
export class VerifyIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
