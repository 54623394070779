import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-select-clear-all',
    templateUrl: './select-clear-all.component.html',
    styleUrls: ['./select-clear-all.component.css']
})
export class SelectClearAllComponent implements OnInit {

    @Output() clearAll: EventEmitter<any> = new EventEmitter();

    @Output() selectAll: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    clear() {
        this.clearAll.emit();
    }

    select() {
        this.selectAll.emit();
    }
}
