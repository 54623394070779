import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="22" height="22" focusable="false" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <g fill="currentColor"><path d="m5.74776,10.72576l-3.31694,0c-0.54959,0 -0.99509,0.40811 -0.99509,0.91156l0,9.1156c0,0.50344 0.4455,0.91156 0.99509,0.91156l3.31694,0c0.54958,0 0.99509,-0.40811 0.99509,-0.91156l0,-9.1156m-2.65357,8.50789c-0.54958,0 -0.99508,-0.40811 -0.99508,-0.91156s0.4455,-0.91156 0.99508,-0.91156s0.99509,0.40811 0.99509,0.91156s-4.97544,-19.79387 -0.99509,0.91156zm13.26779,-14.83367c0,1.61103 -1.07677,2.5147 -1.37973,3.59109l4.21763,0c1.38469,0 2.46271,1.05384 2.46917,2.20666c0.00349,0.68131 -0.31287,1.41477 -0.80597,1.86858l-0.00456,0.00417c0.40782,0.88638 0.34152,2.12839 -0.38593,3.01836c0.35992,0.98354 -0.00287,2.1917 -0.67923,2.8394c0.1782,0.66841 0.09304,1.23725 -0.25491,1.6952c-0.84623,1.11378 -2.94363,1.12946 -4.71724,1.12946l-0.11797,-0.00004c-2.00207,-0.00064 -3.64059,-0.66839 -4.95717,-1.20497c-0.6616,-0.26963 -1.52666,-0.60341 -2.183,-0.61447c-0.27116,-0.00456 -0.48854,-0.20726 -0.48854,-0.4557l0,-8.11934c0,-0.12154 0.05315,-0.23818 0.14751,-0.32365c1.64248,-1.48675 2.34873,-3.06083 3.69496,-4.29615c0.61379,-0.56334 0.83703,-1.41428 1.05283,-2.23719c0.18439,-0.70269 0.57006,-2.19511 1.40689,-2.19511c0.99509,0 2.98525,0.30385 2.98525,3.09368z" /></g>' +
    '</svg>';

@Directive({
    selector: '[appThumbUpIcon]'
})
export class ThumbUpIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
