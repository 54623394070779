import {NgModule} from '@angular/core';
import {DeleteIconDirective} from './delete-icon.directive';
import {EmailIconDirective} from './email-icon.directive';
import {CopyIconDirective} from './copy-icon.directive';
import {IconComponent} from './icon.component';
import {InfoIconDirective} from './info-icon.directive';
import {MinusIconDirective} from './minus-icon.directive';
import {CloseIconDirective} from './close-icon.directive';
import {CalendarIconDirective} from './calendar-icon.directive';
import {SubtaskIconDirective} from './subtask-icon.directive';
import {LinkIconDirective} from './link-icon.directive';
import {CommentIconDirective} from './comment-icon.directive';
import {AttachmentIconDirective} from './attachment-icon.directive';
import {MacroIconDirective} from './macro-icon.directive';
import {LabelIconDirective} from './label-icon.directive';
import {StarFilledIconDirective} from './star-filled-icon.directive';
import {ShortcutIconDirective} from './shortcut-icon.directive';
import {LockIconDirective} from './lock-icon.directive';
import {DownloadIconDirective} from './download-icon.directive';
import {ThumbUpIconDirective} from './thumb-up-icon.directive';
import {CollapsedIconDirective} from './collapsed-icon.directive';
import {ExpandedIconDirective} from './expanded-icon.directive';
import {LockFullIconDirective} from './lock-full-icon.directive';
import {ExpirationIconDirective} from './expiration-icon.directive';
import {UsersIconDirective} from './users-icon.directive';
import {ChildIconDirective} from './child-icon.directive';
import {CustomizeColumnsIconDirective} from './customize-columns-icon.directive';
import {LockCircleIconDirective} from './lock-circle-icon.directive';
import {UserIconDirective} from './user-icon.directive';
import {FilterIconDirective} from './filter-icon.directive';
import {EditIconDirective} from './edit-icon.directive';
import {RefreshIconDirective} from './refresh-icon.directive';
import {FileIconDirective} from './file-icon.directive';
import {PageIconDirective} from './page-icon.directive';
import {ExportIconDirective} from './export-icon.directive';
import {AddIconDirective} from './add-icon.directive';
import {ListIconDirective} from './list-icon.directive';
import {ArrowDownIconDirective} from './arrow-down-icon.directive';
import {ViewIconDirective} from './view-icon.directive';
import {WarnIconDirective} from './warn-icon.directive';
import {BulletListIconDirective} from './bullet-list-icon.directive';
import {DangerIconDirective} from './danger-icon.directive';
import {DocumentationIconDirective} from './documentation-icon.directive';
import {EditFilledIconDirective} from './edit-filled-icon.directive';
import {PreselectedIconDirective} from './preselected-icon.directive';
import {StatusIconDirective} from './status-icon.directive';
import {DoneIconDirective} from './done-icon.directive';
import {VerifyIconDirective} from './verify-icon.directive';
import {SearchIconDirective} from './search-icon.directive';
import {ChevronRightIconDirective} from './chevron-right-icon.directive';
import {ChevronDownIconDirective} from './chevron-down-icon.directive';
import {AddLinkIconDirective} from './add-link-icon.directive';
import {AddSectionIconDirective} from './add-section-icon.directive';
import {SectionIconDirective} from './section-icon.directive';
import {GroupIconDirective} from './group-icon.directive';
import {SmallLinkIconDirective} from './small-link-icon.directive';

@NgModule({
    declarations: [
        IconComponent,
        DangerIconDirective,
        EditFilledIconDirective,
        EditIconDirective,
        RefreshIconDirective,
        DeleteIconDirective,
        EmailIconDirective,
        BulletListIconDirective,
        CopyIconDirective,
        InfoIconDirective,
        MinusIconDirective,
        CloseIconDirective,
        CalendarIconDirective,
        AttachmentIconDirective,
        CommentIconDirective,
        LinkIconDirective,
        SubtaskIconDirective,
        MacroIconDirective,
        LabelIconDirective,
        StarFilledIconDirective,
        StatusIconDirective,
        ShortcutIconDirective,
        LockIconDirective,
        LockFullIconDirective,
        LockCircleIconDirective,
        DownloadIconDirective,
        ThumbUpIconDirective,
        CollapsedIconDirective,
        ExpandedIconDirective,
        ExpirationIconDirective,
        UsersIconDirective,
        ChildIconDirective,
        CustomizeColumnsIconDirective,
        UserIconDirective,
        FilterIconDirective,
        FileIconDirective,
        PageIconDirective,
        PreselectedIconDirective,
        ExportIconDirective,
        AddIconDirective,
        ListIconDirective,
        ArrowDownIconDirective,
        ViewIconDirective,
        WarnIconDirective,
        DocumentationIconDirective,
        DoneIconDirective,
        VerifyIconDirective,
        SearchIconDirective,
        DoneIconDirective,
        ChevronRightIconDirective,
        ChevronDownIconDirective,
        AddLinkIconDirective,
        AddSectionIconDirective,
        SectionIconDirective,
        GroupIconDirective,
        SmallLinkIconDirective
    ],
    exports: [
        IconComponent,
        DangerIconDirective,
        EditFilledIconDirective,
        EditIconDirective,
        DeleteIconDirective,
        EmailIconDirective,
        BulletListIconDirective,
        CopyIconDirective,
        InfoIconDirective,
        MinusIconDirective,
        CloseIconDirective,
        CalendarIconDirective,
        AttachmentIconDirective,
        CommentIconDirective,
        LinkIconDirective,
        SubtaskIconDirective,
        MacroIconDirective,
        LabelIconDirective,
        StarFilledIconDirective,
        StatusIconDirective,
        ShortcutIconDirective,
        LockIconDirective,
        LockFullIconDirective,
        LockCircleIconDirective,
        DownloadIconDirective,
        ThumbUpIconDirective,
        CollapsedIconDirective,
        ExpandedIconDirective,
        ExpirationIconDirective,
        UsersIconDirective,
        ChildIconDirective,
        CustomizeColumnsIconDirective,
        UserIconDirective,
        FilterIconDirective,
        FileIconDirective,
        PageIconDirective,
        PreselectedIconDirective,
        ExportIconDirective,
        AddIconDirective,
        ListIconDirective,
        ArrowDownIconDirective,
        ViewIconDirective,
        RefreshIconDirective,
        WarnIconDirective,
        DocumentationIconDirective,
        DoneIconDirective,
        VerifyIconDirective,
        SearchIconDirective,
        DoneIconDirective,
        ChevronRightIconDirective,
        ChevronDownIconDirective,
        AddLinkIconDirective,
        AddSectionIconDirective,
        SectionIconDirective,
        GroupIconDirective,
        SmallLinkIconDirective
    ]
})
export class IconsModule {
}
