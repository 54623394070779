import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor" fill-rule="evenodd">' +
    // tslint:disable-next-line:max-line-length
    '   <path fill-rule="nonzero" d="M16.002 3H4.995A1.995 1.995 0 0 0 3 4.995v14.01C3 20.107 3.893 21 4.995 21h14.01A1.995 1.995 0 0 0 21 19.005V7.998 11h-2v8H5V5h8V3z"></path><path d="M19 5V3.99A1 1 0 0 0 18 3c-.556 0-1 .444-1 .99V5h-1a1 1 0 0 0 0 2h1v1.01A1 1 0 0 0 18 9c.556 0 1-.444 1-.99V7h1a1 1 0 0 0 0-2z"></path></g>' +
    '</svg>';

@Directive({
    selector: '[appAddSectionIcon]'
})
export class AddSectionIconDirective implements OnInit {
    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
