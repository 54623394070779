import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-data-defaults',
    templateUrl: './data-defaults.component.html',
    styleUrls: ['./data-defaults.component.css']
})
export class DataDefaultsComponent implements OnInit {

    features = window.getToken().features;

    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;

    namedShareLink = [];
    content = [];
    comments = [];
    attachments = [];
    childPages = [];
    labels = [];
    unsupportedMacros = [];
    history = [];
    status = [];
    selectedUsers = [];
    expiration = [];
    password = [];
    samlLogin = [];

    ngOnInit(): void {
        const opt = this.enabled.dataOptions;

        this.namedShareLink = [
            ['off', 'Empty', null, opt.namedShareLink.off],
            ['enable', 'Selected', 'All newly created shares will have \'Use public link\' option selected', opt.namedShareLink.enable]
        ];

        this.content = [
            ['off', 'Empty', null, opt.contentEdit.off],
            ['enable', 'Selected', 'All newly created shares will have \'Edit page content\' option selected', opt.contentEdit.enable]
        ];

        this.comments = [
            ['off', 'Empty', null, opt.comments.off],
            ['show', 'View', 'All newly created shares will have \'View comments\' option selected', opt.comments.show],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add comments\' options selected',
                opt.comments.add]
        ];

        this.attachments = [
            ['off', 'Empty', null, opt.attachments.off],
            ['show', 'View', 'All newly created shares will have \'View attachments\' option selected', opt.attachments.show],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add attachments\' options selected',
                opt.attachments.add]
        ];

        this.childPages = [
            ['off', 'Empty', null, opt.childPages.off],
            ['share', 'Share child pages', 'All newly created shares will have \'Share child pages\' option selected', opt.childPages.share]
        ];

        this.labels = [
            ['off', 'Empty', null, opt.labels.off],
            ['show', 'Share labels', 'All newly created shares will have \'View page labels\' option selected', opt.labels.show]
        ];

        this.unsupportedMacros = [
            ['off', 'Empty', null, opt.unsupportedMacros.off],
            ['remove', 'Remove', 'All newly created shares will have \'Remove unsupported macros\' option selected',
                opt.unsupportedMacros.remove],
            ['placeholder', 'Remove & Show placeholders', 'All newly created shares will have \'Remove unsupported macros\' ' +
            '& \'Show macro placeholder\' options selected', opt.unsupportedMacros.placeholder]];

        this.history = [
            ['off', 'Empty', null, opt.history.off],
            ['show', 'View', 'All newly created shares will have \'View page history\' option selected', opt.history.show],
            ['delete', 'View & Delete', 'All newly created shares will have \'View page history\'' +
                ' and \'Delete page version\' options selected', opt.history.delete],
            ['restore', 'View & Restore', 'All newly created shares will have \'View page history\'' +
                ' and \'Restore page version\' options selected', opt.history.restore],
            ['deleterestore', 'View & Delete & Restore', 'All newly created shares will have \'View page history\',' +
                ' \'Delete page version\' and \'Restore page version\' options selected', opt.history.deleterestore],
        ];

        this.status = [
            ['off', 'Empty', null, opt.status.off],
            ['show', 'Selected', 'All newly created shares will have \'View page status\' option selected', opt.status.show],
        ];

        this.expiration = [
            ['off', 'Empty', null, true],
            ['on', 'Selected', 'All newly created shares will have \'Link expiration date and time\' option selected ' +
            'on the \'Security\' tab', true]
        ];

        this.password = [
            ['off', 'Empty', null, true],
            ['on', 'Selected', 'All newly created shares will have \'Protect link with password\' option selected ' +
            'on the \'Security\' tab', true]
        ];

        this.samlLogin = [
            ['off', 'Empty', null, true],
            ['on', 'Selected', 'All newly created shares will have \'Require to confirm identity via Corporate SSO\' option selected ' +
            'on the \'Security\' tab', true]
        ];

        this.selectedUsers = [
            ['off', 'Empty', null, true],
            ['enable', 'Enable', 'All newly created shares will have \'Share only with the following users\' option selected ' +
            'on the \'Selected users\' tab', true],
            ['notify', 'Enable & Notify', 'All newly created shares will have \'Share only with the following users\' ' +
            '& \'Notify newly added users\' options selected on the \'Selected users\' tab', true]
        ];
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
