import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConfigData} from '../data';

@Component({
    selector: 'app-api-key-config-view',
    templateUrl: './api-key-config-view.component.html',
    styleUrls: ['./api-key-config-view.component.css']
})
export class ApiKeyConfigViewComponent {
    @Input() data: ConfigData;
    @Output() back = new EventEmitter<void>();

    goBack() {
        this.back.emit();
    }
}
