import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Recipient, RecipientChangeEvent} from '../recipient';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import CustomValidators from '../../utils/custom-validators';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit, AfterViewInit {

    @Input() item: Recipient;
    @Input() editable: boolean;
    @Input() readOnly: boolean;
    @Input() focused: boolean;
    @Input() empty: boolean;

    @Input() allowedEmails: [];
    @Input() allowedDomains: [];

    @Output() markAsEditedEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() changeEvent: EventEmitter<RecipientChangeEvent> = new EventEmitter();
    @Output() removeEvent: EventEmitter<Recipient> = new EventEmitter();
    @Output() blur = new EventEmitter<boolean>();

    recipientGroup: FormGroup;
    address: FormControl;

    internalEditionMode: boolean;

    @ViewChild('emailField') inputEl: ElementRef;

    private editedRecord: Recipient;

    ngOnInit(): void {
        this.address = new FormControl(this.item.email, [Validators.required, CustomValidators.email]);

        if (this.shouldValidate()) {
            this.address.setValidators([
                this.validator(Validators.compose([
                    Validators.required,
                    CustomValidators.email,
                    CustomValidators.matchesEmailOrEmailDomain(this.allowedEmails, this.allowedDomains)
                ]))
            ]);
        }

        this.recipientGroup = new FormGroup({
            type: new FormControl(this.item.type),
            address: this.address
        });
    }

    ngAfterViewInit() {
        if (this.focused) {
            this.focus();
        }
    }

    markAsEdited(edition: boolean) {
        this.markAsEditedEvent.emit(edition);
        this.setEditedRecord(edition ? this.item : null);
        this.internalEditionMode = edition;
        if (edition) {
            setTimeout(() => {
                this.resizeInput();
                this.focus();
                this.select();
                this.inputEl.nativeElement.value = this.editedRecord.email;
            }, 0);
        }
    }

    change() {
        if (this.address.value && this.address.valid) {
            this.changeEvent.emit({
                item: {
                    type: this.item.type,
                    email: this.address.value.toLowerCase()
                },
                editedRecord: this.editedRecord
            });
            this.markAsEdited(false);
        }
    }

    remove(recipient: Recipient) {
        this.removeEvent.emit(recipient);
    }

    focus() {
        if (this.inputEl) {
            this.inputEl.nativeElement.focus();
        }
    }

    select() {
        if (this.inputEl) {
            this.inputEl.nativeElement.select();
        }
    }

    isInputAddressInvalid() {
        return this.address.invalid && (this.address.dirty || this.address.touched)
            && (this.address.errors.email || this.address.errors.matchesEmailOrEmailDomain)
            && document.activeElement.id !== 'email-field-' + this.item.type;
    }

    clearValue() {
        this.address.setValue('');
        this.inputEl.nativeElement.value = '';
        this.inputEl.nativeElement.setAttribute('size', 12);
    }

    setEditedRecord(value: Recipient) {
        this.editedRecord = value;
    }

    resizeInput() {
        this.inputEl.nativeElement.setAttribute('size', this.inputEl.nativeElement.value.length);
    }

    onCtrlV() {
        setTimeout(() => {
            this.change();
        }, 0);
    }

    placeholder(): string {
        return this.empty ? 'Enter valid email address' : null;
    }

    validator(validator: ValidatorFn): ValidatorFn {
        return control => {
            return validator(control);
        };
    }

    shouldValidate(): boolean {
        return Utils.isNotEmptyArray(this.allowedEmails)
            || Utils.isNotEmptyArray(this.allowedDomains);
    }
}
