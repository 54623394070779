import {Pipe, PipeTransform} from '@angular/core';
import Utils from '../utils/utils';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, args?: string[]): string {
        const limit = this.valueExists(0, args) ? parseInt(args[0], 10) : 40;
        const trail = this.valueExists(1, args) ? args[1] : '...';

        return !Utils.isEmpty(value) && value.length > limit ? value.substring(0, limit) + trail : value;
    }

    valueExists(index: number, args?: string[]): boolean {
        return args && args.length > index && !Utils.isEmpty(args[index]);
    }
}
