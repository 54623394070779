import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" focusable="false" role="presentation">' +
    '   <path d="M4.02 19.23a1 1 0 0 0 1.18 1.18l3.81-.78-4.21-4.21-.78 3.81zM19.844 ' +
    '6.707l-2.12-2.122A1.997 1.997 0 0 0 16.308 4c-.512 0-1.024.195-1.415.585l-9.757 ' +
    '9.758 4.95 4.95 9.757-9.758a2 2 0 0 0 0-2.828z"' +
    '       fill="currentColor" fill-rule="evenodd">' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appEditIcon]'
})
export class EditIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
