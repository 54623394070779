import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';
import Utils from '../../utils/utils';
import {SecurityConfig} from '../../base/config';

@Component({
    selector: 'app-selected-users',
    templateUrl: './selected-users.component.html',
    styleUrls: ['./selected-users.component.css']
})
export class SelectedUsersComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    @Input() global: boolean;
    @Input() parentSecurityConfig: SecurityConfig;

    selectedUsers = [];
    private allowedSelectedUserEmails: string[];
    private allowedSelectedUserEmailDomains: string[];

    constructor() {
    }

    ngOnInit(): void {
        const opt = this.enabled.security;
        this.selectedUsers = [
            ['OPTIONAL', 'Optional', null, opt.selectedUsers.optional],
            ['REQUIRED', 'Required', null, opt.selectedUsers.required]
        ];

        this.getAllowedSelectedUsersEmailDomains().valueChanges.subscribe(v => {
            if (v) {
                let value = v.toLowerCase();
                if (v.includes(' ')) {
                    value = value.replace(/\s/g, '');
                }
                this.getAllowedSelectedUsersEmailDomains().setValue(value, {emitEvent: false});
            }
        });
        if (this.parentSecurityConfig) {
            const allowedSelectedUsers: any = this.parentSecurityConfig.allowedSelectedUsers;
            this.allowedSelectedUserEmails = allowedSelectedUsers.map(i => i.email);
            if (this.parentSecurityConfig.allowedSelectedUsersEmailDomains) {
                this.allowedSelectedUserEmailDomains = this.parentSecurityConfig.allowedSelectedUsersEmailDomains.split(',');
            }
        }
    }

    shouldDisplayAllowedSelectedUsersEmailDomainsError(): boolean {
        return Utils.shouldDisplayError(this.getAllowedSelectedUsersEmailDomains());
    }

    shouldDisplayError(): boolean {
        return this.getAllowedEmails().invalid && this.isTouchedOrDirty();
    }

    isTouchedOrDirty(): boolean {
        const control: AbstractControl = this.getAllowedEmails();
        return control.dirty || control.touched;
    }

    getAllowedEmails(): AbstractControl {
        return this.form.get('allowedSelectedUsers');
    }

    getAllowedUserEmails() {
        return this.allowedSelectedUserEmails ? this.allowedSelectedUserEmails : [];
    }

    getAllowedEmailDomains() {
        return this.allowedSelectedUserEmailDomains ? this.allowedSelectedUserEmailDomains : [];
    }

    hasParentRestrictions(): boolean {
        return this.parentSecurityConfig
            && (Utils.isNotEmptyArray(this.parentSecurityConfig.allowedSelectedUsers)
                || !Utils.isEmpty(this.parentSecurityConfig.allowedSelectedUsersEmailDomains));
    }

    emailDomainsAllowed(): boolean {
        return !this.parentSecurityConfig ||
            (Utils.isEmptyArray(this.parentSecurityConfig.allowedSelectedUsers)
                || !Utils.isEmpty(this.parentSecurityConfig.allowedSelectedUsersEmailDomains));
    }

    getParentRestrictions(): string {
        const config = this.parentSecurityConfig;
        let result = '';
        let isSingle = false;

        if (!Utils.isEmpty(config.allowedSelectedUsers)) {
            isSingle = this.hasSize(config.allowedSelectedUsers, 1);
            result += 'Only email address' + (this.hasSize(config.allowedSelectedUsers, 1) ? '' : 'es') + ': '
                + this.allowedSelectedUserEmails.join(', ');
        }
        if (!Utils.isEmpty(config.allowedSelectedUsersEmailDomains)) {
            isSingle = false;
            if (result.length > 0) {
                result += ' or ';
            } else {
                result += 'Only ';
            }
            result += 'emails from domain' + (this.hasLength(config.allowedSelectedUsersEmailDomains, 0) ? '' : 's') + ': '
                + config.allowedSelectedUsersEmailDomains.split(',').join(', ');
        }
        result += (isSingle ? ' is' : ' are') + ' allowed';
        return result;
    }

    allowedDomains() {
        let result = this.parentSecurityConfig.allowedSelectedUsersEmailDomains;
        if (this.allowedSelectedUserEmailDomains.length === 1) {
            result += ' domain is';
        } else {
            result += ' domains are';
        }
        return result;
    }

    private hasSize(items: [], size: number): boolean {
        return Utils.isNotEmptyArray(items)
            && items.length === size;
    }

    private hasLength(input: string, length: number): boolean {
        return !Utils.isEmpty(input)
            && (input.match(/,/g) || []).length === length;
    }

    private getAllowedSelectedUsersEmailDomains() {
        return this.form.get('allowedSelectedUsersEmailDomains');
    }
}
