import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Config} from '../share-form-config';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-security-options',
    templateUrl: './security-options.component.html',
    styleUrls: ['./security-options.component.css']
})
export class SecurityOptionsComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() config: Config;

    constructor() {
    }

    ngOnInit(): void {
        if (this.form.get('hasPassword').value) {
            Utils.validateAllFormFields(this.form);
        }
    }
}
