import {Component, forwardRef, HostBinding, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-domains-input',
    templateUrl: './domains-input.component.html',
    styleUrls: ['./domains-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DomainsInputComponent),
            multi: true
        }
    ],
})
export class DomainsInputComponent implements ControlValueAccessor {

    readonly INVALID_CHARACTERS = /[^a-zA-Z0-9.,-]/g;

    @Input() height = '30px';
    @Input() disabled: boolean;
    @Input() errorCouldBeDisplayed: boolean;
    value: string;
    onChange: any;
    onTouched: any;

    removeInvalidCharacters(value: string): string {
        if (!value) {
            return '';
        }
        return value.replace(this.INVALID_CHARACTERS, '');
    }

    constructor() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.value = this.removeInvalidCharacters(obj);
    }

    onInput($event: Event) {
        const element = $event.target as HTMLInputElement;
        if (this.INVALID_CHARACTERS.test(element.value)) {
            element.value = element.value.replace(this.INVALID_CHARACTERS, '');
        }
        if (this.value !== element.value) {
            this.value = element.value;
            this.onChange(element.value);
        }
    }
}
