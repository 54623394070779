import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html'
})
export class StatusComponent {

    @Input() form: FormGroup;

    constructor() {
    }
}
