import {initJwt} from './jwt-token';

export async function envInit(environment) {
    await initJwt(environment.production);

    environment.host = await getBaseUrl();
    environment.pluginSettingsUrl = await getPluginSettingsUrl();

    if (environment.contentUrl === '') {
        const location = window.location;
        environment.contentUrl = location.protocol + '//' + location.host;
    }

    function getPluginSettingsUrl(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const pluginKey = window.getToken().claims.iss;
            return resolve(
                `${environment.host}/wiki/plugins/servlet/ac/${pluginKey}/confluence-external-share-global-view?settingsView=true`
            );
        });
    }

    function getBaseUrl(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            AP.getLocation(it => {
                const parser = document.createElement('a');
                parser.href = it;
                const base = parser.origin;
                window.__cache_base_url = base;
                return resolve(`${base}`);
            });
        });
    }
}
