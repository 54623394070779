import {
    AfterViewInit,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
    QueryList
} from '@angular/core';

@Directive({
    selector: '[appDroplistItem]',
    exportAs: 'droplist',
})
export class AppDropListItemDirective {

    @Output() public selectEvent: EventEmitter<any> = new EventEmitter();

    constructor(public ref: ElementRef) {
    }
}

@Directive({
    selector: '[appDroplist]',
    exportAs: 'droplist',
})
export class AppDropListDirective implements AfterViewInit {

    @ContentChildren(AppDropListItemDirective) options: QueryList<AppDropListItemDirective>;

    @HostListener('change') onChange() {
        const ne = this.getNativeElement();
        const selectedValue = ne.value;

        this.options.forEach(it => {
            if (selectedValue === it.ref.nativeElement.value) {
                it.selectEvent.emit();
            }
        });
        ne.selectedIndex = 0;
    }

    constructor(private ref: ElementRef) {
    }

    ngAfterViewInit() {
    }

    private getNativeElement() {
        return this.ref.nativeElement;
    }
}




