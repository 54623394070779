import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    '   <g fill="currentColor" fill-rule="evenodd">' +
    // tslint:disable-next-line:max-line-length
    '       <path d="M8.126 10H4c-1.113 0-2 .895-2 2v3.73c0 1.31 1.874 2.053 4 2.23v-2.964A3.002 3.002 0 019 12h.354a4 4 0 01-1.228-2zm7.748 0H20c1.105 0 2 .885 2 2v3.73c0 1.31-1.874 2.053-4 2.23v-2.964A3 3 0 0015 12h-.354a4 4 0 001.228-2zM9.967 5.554a3 3 0 10-1.963 3.274 3.999 3.999 0 011.963-3.274zm6.03 3.274a3 3 0 10-1.963-3.276 3.994 3.994 0 011.963 3.276z"></path><path d="M7 15c0-1.105.887-2 2-2h6c1.105 0 2 .885 2 2v3.73c0 3.027-10 3.027-10 0V15z"></path>' +
    '       <circle cx="12" cy="9" r="3"></circle>' +
    '   </g>' +
    '</svg>';

@Directive({
    selector: '[appUsersIcon]'
})
export class UsersIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
