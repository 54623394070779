import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ShareListComponent} from './share-list/share-list.component';
import {IconsModule} from '../icons/icons.module';
import {SettingsComponent} from './settings/settings.component';
import {SpaceViewComponent} from './space-view.component';
import {RouterModule} from '@angular/router';
import {SpaceSchemaFormModule} from '../space-schema-form/space-schema-form.module';
import {SpaceActivityComponent} from './space-activity/space-activity.component';

@NgModule({
    declarations: [
        SpaceViewComponent,
        ShareListComponent,
        SettingsComponent,
        SpaceActivityComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule,
        SpaceSchemaFormModule
    ],
    exports: [
        SpaceViewComponent
    ]
})
export class SpaceViewModule {
}
