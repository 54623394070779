import {Directive} from '@angular/core';
import {AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {delay, map, switchMap} from 'rxjs/operators';
import {AppService} from '../app.service';
import {SamlConfig} from '../global-view/global-settings/saml-config';

export function workspaceValidator(app: AppService,
                                   samlConfig: SamlConfig): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (samlConfig.isSamlConfig()) {
            return of(control.value).pipe(
                delay(500),
                switchMap((value) => app.checkWorkspace(value).pipe(
                    map(result => result ? null : {workspaceErr: true}))));
        }
        return of(null);
    };
}

@Directive({
    selector: '[workspace][formControlName],[workspace][formControl],[workspace][ngModel]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: WorkspaceValidatorDirective, multi: true}]
})
export class WorkspaceValidatorDirective implements AsyncValidator {
    constructor(private app: AppService) {
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        const samlConfig: SamlConfig = {
            isSamlConfig() {
                return true;
            }
        };
        return workspaceValidator(this.app, samlConfig)(control);
    }
}
