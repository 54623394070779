import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" focusable="false" role="presentation">' +
    '   <path d="M5 5a1 1 0 0 0-1 1v1h16V6a1 1 0 0 0-1-1H5zm11.15 15H7.845a1 1 0 0 ' +
    '1-.986-.835L5 8h14l-1.864 11.166a.999.999 0 0 1-.986.834M9 4.5a.5.5 0 0 1 ' +
    '.491-.5h5.018a.5.5 0 0 1 .491.5V5H9v-.5z"' +
    '       fill="currentColor" fill-rule="evenodd">' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appDeleteIcon]'
})
export class DeleteIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
