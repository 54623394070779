import {Component, Input} from '@angular/core';
import {Share} from '../../base/share';
import {ConfluenceService, Permissions} from '../../confluence.service';
import {ShareActionService} from '../../share-action.service';

@Component({
    selector: 'app-share-action-row',
    templateUrl: './share-action-row.component.html'
})
export class ShareActionRowComponent {

    tooltipInfo = {
        ACTIVE: 'Send via email',
        DISABLED: 'Can\'t send via email when space is disabled',
        EXPIRED: 'Can\'t send via email when link has expired'
    };

    @Input() share: Share;
    @Input() sendAllowed = true;
    @Input() editAllowed = true;
    @Input() deleteAllowed = true;
    @Input() permissions: Permissions;

    constructor(private confluence: ConfluenceService,
                private shareActionService: ShareActionService) {
    }

    edit(share: Share) {
        this.shareActionService.edit(share, this.permissions);
    }

    view(share: Share) {
        this.shareActionService.view(share, this.permissions);
    }

    sendViaMail(s: Share) {
        if (s.status === 'ACTIVE') {
            const customData = {
                uuid: s.uuid,
                contentId: s.contentId,
                permissions: this.permissions
            };
            this.confluence.showDialog({
                    key: 'send-via-mail',
                    customData
                }
            );
        }
    }

    confirmDeletion(s: Share) {
        this.shareActionService.deleteSingle(s);
    }
}
