import {Component, Input} from '@angular/core';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-link-input',
    templateUrl: './link-input.component.html',
    styleUrls: ['./link-input.component.css']
})
export class LinkInputComponent {

    @Input() editionLink: string;

    selectItemLink() {
        const selectedLink = (document.getElementById('link_item') as HTMLInputElement);
        if (selectedLink) {
            selectedLink.select();
        }
    }

    copyToClipboard() {
        Utils.copyToClipboard(this.editionLink);
    }
}
