import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from '../icons/icons.module';
import {TitleCasePipe} from '@angular/common';
import {ShareFormComponent} from './share-form.component';
import {CommentsComponent} from './data-options/comments/comments.component';
import {AttachmentsComponent} from './data-options/attachments/attachments.component';
import {ChildPagesComponent} from './data-options/child-pages/child-pages.component';
import {LabelsComponent} from './data-options/labels/labels.component';
import {MacrosComponent} from './data-options/macros/macros.component';
import {ExpirationComponent} from './security-options/expiration/expiration.component';
import {PasswordComponent} from './security-options/password/password.component';
import {SelectedUsersComponent} from './selected-users/selected-users.component';
import {DataOptionsComponent} from './data-options/data-options.component';
import {SecurityOptionsComponent} from './security-options/security-options.component';
import {EmailFormModule} from '../email-form/email-form.module';
import {SamlComponent} from './security-options/saml/saml.component';
import {ContentComponent} from './data-options/content/content.component';
import {HistoryComponent} from './data-options/history/history.component';
import {DomainsInputComponent} from './selected-users/components/domains-input/domains-input.component';
import {
    DomainsInputErrorsComponent,
} from './selected-users/components/domains-input-errors/domains-input-errors.component';
import { PageCustomizeComponent } from './page-customize/page-customize.component';
import {StatusComponent} from './data-options/status/status.component';

@NgModule({
    declarations: [
        ShareFormComponent,
        CommentsComponent,
        AttachmentsComponent,
        ChildPagesComponent,
        LabelsComponent,
        MacrosComponent,
        ExpirationComponent,
        PasswordComponent,
        SelectedUsersComponent,
        DataOptionsComponent,
        SecurityOptionsComponent,
        SamlComponent,
        HistoryComponent,
        ContentComponent,
        StatusComponent,
        DomainsInputComponent,
        DomainsInputErrorsComponent,
        PageCustomizeComponent,
    ],
    imports: [
        BrowserModule,
        BaseModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule,
        EmailFormModule,
    ],
    exports: [
        ShareFormComponent,
        SelectedUsersComponent,
        DomainsInputComponent,
        DomainsInputErrorsComponent,
    ],
    providers: [
        TitleCasePipe,
    ],
})
export class ShareFormModule {
}
