import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RowActionSettings} from './row-action-settings';
import {Share} from '../../base/share';

@Component({
    selector: 'app-row-action',
    templateUrl: './row-action.component.html'
})
export class RowActionComponent {
    @Input() settings: RowActionSettings;

    @Output() execute: EventEmitter<Share> = new EventEmitter();

    executeAction() {
        this.execute.emit(this.settings.item);
    }
}

