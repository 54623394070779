import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../utils/utils';
import {AppService} from '../../app.service';

@Component({
    selector: 'app-page-customize',
    templateUrl: './page-customize.component.html',
    styleUrls: ['./page-customize.component.css']
})
export class PageCustomizeComponent implements OnInit {
    @Input() form: FormGroup;
    showSubscribeButton = false;

    constructor(private appService: AppService) {
    }

    ngOnInit() {
        this.appService.getGlobalSettings().subscribe(settings => {
            this.showSubscribeButton = settings.pageSettings.showSubscribe;
            if (!this.showSubscribeButton) {
                this.form.get('subscribeButtonPlaceholder').disable();
            } else {
                this.form.get('subscribeButtonPlaceholder').enable();
            }
        });
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
