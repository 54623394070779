import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements AfterViewInit {

    @Input() form: FormGroup;

    @ViewChild('emailContent') emailContent: ElementRef<HTMLInputElement>;
    @ViewChild('emailTemplate') emailTemplate: ElementRef<HTMLInputElement>;

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    ngAfterViewInit() {
        Utils.expandTextArea(this.emailContent);
        Utils.expandTextArea(this.emailTemplate);
    }
}
