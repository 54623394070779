import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from '../icons/icons.module';
import {RouterModule} from '@angular/router';
import {SpaceSchemaFormComponent} from './space-schema-form.component';
import {SpaceSchemaFormParentComponent} from './space-schema-form-parent.component';
import {DataOptionsComponent} from './data-options/data-options.component';
import {SecurityOptionsComponent} from './security-options/security-options.component';
import {AssociatedSpacesComponent} from './associated-spaces/associated-spaces.component';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {AuditComponent} from './audit/audit.component';
import {DataDefaultsComponent} from './data-defaults/data-defaults.component';
import {EmailFormModule} from '../email-form/email-form.module';
import {SelectedUsersComponent} from './selected-users/selected-users.component';

@NgModule({
    declarations: [
        SpaceSchemaFormComponent,
        SpaceSchemaFormParentComponent,
        DataOptionsComponent,
        SecurityOptionsComponent,
        AssociatedSpacesComponent,
        RadioButtonComponent,
        AuditComponent,
        DataDefaultsComponent,
        SelectedUsersComponent
    ],
    imports: [
        BaseModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule,
        EmailFormModule
    ],
    exports: [
        SpaceSchemaFormComponent,
        SpaceSchemaFormParentComponent,
        DataDefaultsComponent
    ]
})
export class SpaceSchemaFormModule {
}
