import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShareFormAccessGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (next.queryParams.source === 'secondary') {
            const userIsAdmin = next.queryParams.user_is_admin === 'true';
            const userIsSpaceAdmin = (next.queryParams.user_is_space_admin === 'true') || userIsAdmin;
            const permissionsEnabled = next.queryParams.permissionsEnabled === 'true';
            const hasViewSharesOnPagePermission = next.queryParams.hasViewSharesOnPagePermission === 'true';
            const hasViewOwnSharesOnPagePermission = next.queryParams.hasViewOwnSharesOnPagePermission === 'true';
            const canViewShares = hasViewSharesOnPagePermission || hasViewOwnSharesOnPagePermission;

            if (userIsSpaceAdmin || !permissionsEnabled || (permissionsEnabled && canViewShares)) {
                return of(true);
            } else {
                const reason = 'You do not have permission to view External Share for Confluence section on page';
                return this.router.parseUrl('/no-access-content-view?' +
                    'source=operations-top-level' +
                    '&reason=' + reason);
            }
        }
        return of(true);
    }
}
