import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Constants} from '../../../../utils/constants';

@Component({
    selector: 'app-page-customization-page',
    templateUrl: './page-customization-page.component.html',
    styleUrls: ['./page-customization-page.component.css']
})
export class PageCustomizationPageComponent implements OnInit {
    @Input() form: FormGroup;
    pageAvailableFonts = Constants.AVAILABLE_FONTS;
    pageSelectedFonts = [];
    genericFontFamily = Constants.GENERIC_FONT_FAMILY;

    tableStyles = [
        {text: 'None', value: 'none'},
        {text: 'Hidden', value: 'hidden'},
        {text: 'Dotted', value: 'dotted'},
        {text: 'Dashed', value: 'dashed'},
        {text: 'Solid', value: 'solid'},
        {text: 'Double', value: 'double'},
        {text: 'Groove', value: 'groove'},
        {text: 'Ridge', value: 'ridge'},
        {text: 'Inset', value: 'inset'},
        {text: 'Outset', value: 'outset'}
    ];

    ngOnInit(): void {
        const initialPageFonts = this.form.get('page.fontFamily')?.value.split(', ');
        this.pageSelectedFonts = initialPageFonts.filter(font => this.pageAvailableFonts.includes(font));
        this.pageAvailableFonts = this.pageAvailableFonts.filter(font => !initialPageFonts.includes(font));
    }

    removeNavigationImageUrl() {
        this.form.patchValue({
            pageNavigation: {
                backgroundImage: null
            }
        });
    }

    removeNavigationImageUrlDarkMode() {
        this.form.patchValue({
            pageNavigation: {
                backgroundImageDarkMode: null
            }
        });
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
        this.updateFormValue();
    }

    private updateFormValue() {
        const selectedFontsOrder = this.pageSelectedFonts;
        this.form.get('page.fontFamily')?.setValue(selectedFontsOrder.join(', '));
    }
}
