import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PeriodEnum} from '../base/period';
import {ConfluenceService} from '../confluence.service';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {AppService, GlobalSettings} from '../app.service';
import {SpaceSchemaViewService} from './space-schema-view.service';
import Utils from '../utils/utils';
import {Config, ConfigView, GlobalConfigView, SecurityConfig, SpaceConfigView} from '../base/config';
import {LoaderService} from '../loader.service';
import {ConfigEnabled} from './config-enabled';
import CustomValidators from '../utils/custom-validators';

export interface CustomData {
    isNew?: boolean;
    type?: 'DEFAULT' | 'GLOBAL' | 'SPACE' | 'SHARE';
    id?: string;
    spaceId?: string;
    model?: any;
    publishValueChange?: boolean;
    allowedTab?: string;
}

interface SpaceSchemaView {
    data: CustomData;
    schema: ConfigView;
}

const initDataOptionDisabled: ConfigEnabled = {
    general: true,
    dataOptions: {
        namedShareLink: {off: true, enable: true},
        contentEdit: {off: true, enable: true},
        comments: {off: true, show: true, add: true},
        attachments: {off: true, show: true, add: true},
        childPages: {off: true, share: true},
        labels: {off: true, show: true},
        unsupportedMacros: {off: true, remove: true, placeholder: true},
        history: {off: true, show: true, delete: true, restore: true, deleterestore: true},
        status: {off: true, show: true},
    },
    security: {
        expiration: {optional: true, required: true, custom: true},
        password: {optional: true, required: true},
        samlLogin: {optional: true, required: true, disabled: true},
        selectedUsers: {optional: true, required: true},
    },
};

@Component({
    selector: 'app-space-schema-config',
    templateUrl: './space-schema-form.component.html',
    styleUrls: ['./space-schema-form.component.css'],
})
export class SpaceSchemaFormComponent implements OnInit {
    @Input() customData: CustomData;
    @Input() space: string;

    @Output() valueEvent = new EventEmitter<any>();

    private refreshEventName = 'config-update';
    config$: Observable<SpaceSchemaView>;
    settings: Observable<GlobalSettings>;
    enabled = initDataOptionDisabled;
    tab: string;

    form: FormGroup = this.fb.group({
        name: ['', Validators.required],
        description: [''],

        enabled: [true],
        associatedSpaces: [[]],

        expirationType: ['OPTIONAL'],
        expirationPeriod: [{count: 1, unit: PeriodEnum.WEEK}], // Expiration unit + count (SELECTABLE)

        expirationDefault: ['on'],
        expirationDefaultPeriod: [{count: 1, unit: PeriodEnum.WEEK}], // Expiration unit + count (SELECTABLE)

        password: ['OPTIONAL'],
        passwordDefault: ['on'],

        namedShareLink: ['enable'],
        namedShareLinkDefault: ['enable'],

        contentEdit: ['enable'],
        contentEditDefault: ['enable'],

        comments: ['add'],
        commentsDefault: ['add'],

        attachments: ['add'],
        attachmentsDefault: ['add'],

        childPages: ['share'],
        childPagesDefault: ['share'],
        restrictChildPagesWithLabelsDefault: ['', [Validators.maxLength(1024)]],
        restrictChildPagesWithoutLabelsDefault: ['', [Validators.maxLength(1024)]],

        labels: ['add'],
        labelsDefault: ['add'],

        unsupportedMacros: ['placeholder'],
        unsupportedMacrosDefault: ['placeholder'],

        history: ['deleterestore'],
        historyDefault: ['deleterestore'],

        status: ['show'],
        statusDefault: ['show'],

        selectedUsers: ['OPTIONAL'],
        allowedSelectedUsers: [''],
        allowedSelectedUsersEmailDomains: ['', [CustomValidators.emailDomains()]],
        selectedUsersDefault: ['notify'],

        samlLogin: ['DISABLED'],
        samlLoginDefault: ['enable'],

        defaultShareName: [''],
    });

    users = new Map();

    constructor(private fb: FormBuilder,
                private app: AppService,
                private psv: SpaceSchemaViewService,
                private loader: LoaderService,
                private confluence: ConfluenceService) {
    }

    ngOnInit(): void {
        if (!!this.customData && !!this.customData.allowedTab) {
            this.tab = this.customData.allowedTab;
        } else {
            this.tab = 'general';
        }
        this.settings = this.app.getGlobalSettings();

        this.config$ = this.getCustomData()
            .pipe(
                switchMap(data => zip(of(data), this.createOrLoad(data))),
                map(([data, schema]) => {
                    return {data, schema};
                }),
                tap((x: SpaceSchemaView) => {
                    const view = x.schema;
                    const item = view.item;
                    const config = item.config;
                    const fieldConfig = config.fieldConfig;
                    const fieldDefaults = config.fieldDefaults;

                    this.confluence.fetchUsers(
                        new Set([config.audit.createdBy, config.audit.updatedBy]),
                        this.users,
                    );

                    const formValue: any = {
                        id: ('id' in item) ? item.id : '',
                        name: config.name,
                        description: config.description,
                        enabled: config.enabled,
                        associatedSpaces: ('associatedSpace' in view) ? view.associatedSpace : [],

                        namedShareLink: fieldConfig.namedShareLinkEnabled ? 'enable' : 'off',
                        namedShareLinkDefault: fieldDefaults.namedShareLink ? 'enable' : 'off',

                        contentEdit: fieldConfig.contentAllowEdit ? 'enable' : 'off',
                        contentEditDefault: fieldDefaults.contentEdit ? 'enable' : 'off',

                        comments: fieldConfig.commentsAllowAdd ? 'add' : (fieldConfig.commentsShow ? 'show' : 'off'),
                        commentsDefault: fieldDefaults.commentsAdd ? 'add' : (fieldDefaults.commentsShow ? 'show' : 'off'),

                        attachments: fieldConfig.attachmentsAllowAdd ? 'add' : (fieldConfig.attachmentsShow ? 'show' : 'off'),
                        attachmentsDefault: fieldDefaults.attachmentsAdd ? 'add' : (fieldDefaults.attachmentsShow ? 'show' : 'off'),

                        childPages: fieldConfig.childPagesAllowShare ? 'share' : 'off',
                        childPagesDefault: fieldDefaults.childPagesShare ? 'share' : 'off',

                        restrictChildPagesWithLabelsDefault: fieldDefaults.childPagesShare ?
                            fieldDefaults.restrictChildPagesWithLabelsDefault : '',
                        restrictChildPagesWithoutLabelsDefault: fieldDefaults.childPagesShare ?
                            fieldDefaults.restrictChildPagesWithoutLabelsDefault : '',

                        labels: fieldConfig.labelsShow ? 'show' : 'off',
                        labelsDefault: fieldDefaults.labelsShow ? 'show' : 'off',

                        unsupportedMacros: fieldConfig.macrosPlaceholderShow ? 'placeholder' :
                            (fieldConfig.unsupportedMacrosAllowRemove ? 'remove' : 'off'),
                        unsupportedMacrosDefault: fieldDefaults.macrosPlaceholderShow ? 'placeholder' :
                            (fieldDefaults.unsupportedMacrosRemove ? 'remove' : 'off'),

                        history: (fieldConfig.pageHistoryAllowDelete && fieldConfig.pageHistoryAllowRestore)
                            ? 'deleterestore'
                            : fieldConfig.pageHistoryAllowDelete
                                ? 'delete'
                                : fieldConfig.pageHistoryAllowRestore
                                    ? 'restore'
                                    : fieldConfig.pageHistoryShow
                                        ? 'show'
                                        : 'off',

                        historyDefault: (fieldDefaults.pageHistoryAllowDelete && fieldDefaults.pageHistoryAllowRestore)
                            ? 'deleterestore'
                            : fieldDefaults.pageHistoryAllowDelete
                                ? 'delete'
                                : fieldDefaults.pageHistoryAllowRestore
                                    ? 'restore'
                                    : fieldDefaults.pageHistoryShow
                                        ? 'show'
                                        : 'off',

                        status: fieldConfig.pageStatusShow ? 'show' : 'off',
                        statusDefault: fieldDefaults.pageStatusShow ? 'show' : 'off',
                        expirationType: this.expirationTypeValue(config.securityConfig.expirationType),
                        expirationPeriod: {
                            unit: this.expirationPeriodUnitValue(config.securityConfig.expirationUnit),
                            count: this.expirationPeriodCountValue(config.securityConfig.expirationCount),
                        },
                        expirationDefault: fieldDefaults.expiration ? 'on' : 'off',
                        expirationDefaultPeriod: {
                            count: fieldDefaults.expiration ? fieldDefaults.expirationCount : 1,
                            unit: fieldDefaults.expiration ? fieldDefaults.expirationUnit : PeriodEnum.WEEK,
                        },

                        password: this.passwordValue(config.securityConfig.password),
                        passwordDefault: fieldDefaults.password ? 'on' : 'off',

                        selectedUsers: config.securityConfig.selectedUsers,
                        allowedSelectedUsers: config.securityConfig.allowedSelectedUsers,
                        allowedSelectedUsersEmailDomains: config.securityConfig.allowedSelectedUsersEmailDomains,
                        selectedUsersDefault: fieldDefaults.selectedUsersNotify ? 'notify' :
                            (fieldDefaults.selectedUsersShare ? 'enable' : 'off'),
                        samlLogin: config.securityConfig.samlDisabled ? 'DISABLED' : config.securityConfig.samlLogin,
                        samlLoginDefault: fieldDefaults.samlLogin ? 'on' : 'off',
                        defaultShareName: fieldDefaults.defaultShareName,
                    };

                    this.enabled.security.samlLogin = {
                        optional: !config.securityConfig.samlDisabled,
                        required: !config.securityConfig.samlDisabled,
                        disabled: !config.securityConfig.samlDisabled,
                    };

                    if (this.isDefaultView(x.data)) {
                        this.form.get('name').setValidators([]);
                    }

                    this.form.get('enabled').valueChanges.subscribe(it => {
                        this.psv.emitChange(it);
                    });

                    if (x.data.publishValueChange) {
                        this.form.valueChanges.subscribe(it => {
                            this.valueEvent.emit(it);
                        });
                    }

                    if (this.isSpaceView(x.data)) {
                        formValue.samlLogin = config.securityConfig.samlLogin;

                        const v: SpaceConfigView = view as SpaceConfigView;

                        const parent = v.parent;
                        const parentConfig = parent.fieldConfig;
                        const security = parent.securityConfig;

                        this.enabled = {
                            general: parent.enabled,
                            dataOptions: {
                                namedShareLink: {
                                    off: true,
                                    enable: parentConfig.namedShareLinkEnabled,
                                },
                                contentEdit: {
                                    off: true,
                                    enable: parentConfig.contentAllowEdit,
                                },
                                comments: {
                                    off: true,
                                    show: parentConfig.commentsShow,
                                    add: parentConfig.commentsAllowAdd,
                                },
                                attachments: {
                                    off: true,
                                    show: parentConfig.attachmentsShow,
                                    add: parentConfig.attachmentsAllowAdd,
                                },
                                childPages: {
                                    off: true,
                                    share: parentConfig.childPagesAllowShare,
                                },
                                labels: {
                                    off: true,
                                    show: parentConfig.labelsShow,
                                },
                                unsupportedMacros: {
                                    off: true,
                                    remove: parentConfig.unsupportedMacrosAllowRemove,
                                    placeholder: parentConfig.macrosPlaceholderShow,
                                },
                                history: {
                                    off: true,
                                    show: parentConfig.pageHistoryShow,
                                    delete: parentConfig.pageHistoryAllowDelete,
                                    restore: parentConfig.pageHistoryAllowRestore,
                                    deleterestore: parentConfig.pageHistoryAllowDelete && parentConfig.pageHistoryAllowRestore,
                                },
                                status: {
                                    off: true,
                                    show: parentConfig.pageStatusShow,
                                }
                            },
                            security: {
                                expiration: {
                                    optional: security.expirationType === 'OPTIONAL',
                                    required: security.expirationType === 'OPTIONAL' || security.expirationType === 'REQUIRED',
                                    custom: true,
                                    max: {count: security.expirationCount, unit: security.expirationUnit},
                                },
                                password: {optional: security.password === 'OPTIONAL', required: true},
                                selectedUsers: {
                                    optional: security.selectedUsers === 'OPTIONAL',
                                    required: security.selectedUsers === 'REQUIRED' || security.selectedUsers === 'OPTIONAL',
                                },
                                samlLogin: {
                                    optional: !security.samlDisabled && security.samlLogin === 'OPTIONAL',
                                    required: !security.samlDisabled && (security.samlLogin === 'OPTIONAL'
                                        || security.samlLogin === 'REQUIRED'),
                                    disabled: security.samlDisabled || security.samlLogin !== 'REQUIRED',
                                },
                            },
                        };

                        formValue.enabled = parent.enabled ? formValue.enabled : false;

                        formValue.namedShareLink = parentConfig.namedShareLinkEnabled ? formValue.namedShareLink : 'off';
                        formValue.comments = toggle(formValue.comments, parentConfig.commentsShow,
                            parentConfig.commentsAllowAdd, 'show');
                        formValue.attachments = toggle(formValue.attachments,
                            parentConfig.attachmentsShow, parentConfig.attachmentsAllowAdd, 'show');
                        formValue.childPages = toggle(formValue.childPages, parentConfig.childPagesAllowShare, false, 'share');
                        formValue.labels = toggle(formValue.labels, parentConfig.labelsShow, false, 'show');
                        formValue.unsupportedMacros = toggle(formValue.unsupportedMacros, parentConfig.macrosPlaceholderShow,
                            parentConfig.unsupportedMacrosAllowRemove, 'remove');
                        formValue.history = toggleHistory(formValue.history, parentConfig);

                        formValue.expirationType = security.expirationType === 'OPTIONAL' ? formValue.expirationType :
                            (security.expirationType === 'REQUIRED' && formValue.expirationType === 'OPTIONAL'
                                ? 'REQUIRED' : formValue.expirationType);

                        const expirationDefaultControl = this.form.get('expirationDefault');
                        const expirationDefaultPeriodControl = this.form.get('expirationDefaultPeriod');

                        // Expiration period eg. 1 Month
                        expirationDefaultPeriodControl.setValidators([
                            Utils.conditionalValidator(() => {
                                return expirationDefaultControl.value === 'on';
                            }, Validators.compose([
                                Validators.required,
                                CustomValidators.unitPositiveNumber,
                            ])),
                        ]);

                        formValue.samlLogin = security.samlLogin === 'OPTIONAL' ? formValue.samlLogin :
                            (security.samlLogin === 'REQUIRED' && formValue.samlLogin === 'OPTIONAL'
                                ? 'REQUIRED' : formValue.samlLogin);

                        formValue.selectedUsers = security.selectedUsers === 'OPTIONAL' ? formValue.selectedUsers :
                            (security.selectedUsers === 'REQUIRED' && formValue.selectedUsers === 'OPTIONAL'
                                ? 'REQUIRED' : formValue.selectedUsers);

                        const allowedDomains = v.parent.securityConfig.allowedSelectedUsersEmailDomains;

                        if (allowedDomains) {
                            const arentAllowedDomains = allowedDomains.split(',') as [];
                            this.form.get('allowedSelectedUsersEmailDomains').setValidators(Validators.compose([
                                    CustomValidators.emailDomains(),
                                    CustomValidators.matchesEmailDomain(arentAllowedDomains),
                                ]),
                            );
                        }
                    }

                    function toggle(val, condVal, overCondVal, condName) {
                        return overCondVal ? val : ((condVal && val === condName) ? condName : 'off');
                    }

                    function toggleHistory(val, parentConfig) {
                        if (parentConfig.pageHistoryAllowDelete && parentConfig.pageHistoryAllowRestore) {
                            return val;
                        } else if (parentConfig.pageHistoryAllowDelete) {
                            return ['off', 'show', 'delete'].includes(val) ? val : 'delete';
                        } else if (parentConfig.pageHistoryAllowRestore) {
                            return ['off', 'show', 'restore'].includes(val) ? val : 'restore';
                        } else if (parentConfig.pageHistoryShow) {
                            return ['off', 'show'].includes(val) ? val : 'show';
                        } else {
                            return 'off';
                        }
                    }

                    this.form.patchValue(formValue);
                }),
            );
    }

    getCustomData(): Observable<CustomData> {
        return !!this.customData ? of(this.customData) : this.confluence.getCustomData<CustomData>();
    }

    private getConfig(): Config {
        const v = this.form.getRawValue();
        return {
            name: v.name,
            description: v.description,
            enabled: v.enabled,
            fieldConfig: {
                namedShareLinkEnabled: v.namedShareLink === 'enable',
                contentAllowEdit: v.contentEdit === 'enable',
                commentsShow: v.comments === 'add' ? true : v.comments === 'show',
                commentsAllowAdd: v.comments === 'add',
                attachmentsShow: v.attachments === 'add' ? true : v.attachments === 'show',
                attachmentsAllowAdd: v.attachments === 'add',
                childPagesAllowShare: v.childPages === 'share',
                labelsShow: v.labels === 'show',
                unsupportedMacrosAllowRemove: v.unsupportedMacros === 'placeholder' ? true : v.unsupportedMacros === 'remove',
                macrosPlaceholderShow: v.unsupportedMacros === 'placeholder',
                pageHistoryShow: ['show', 'delete', 'restore', 'deleterestore'].includes(v.history),
                pageHistoryAllowDelete: ['delete', 'deleterestore'].includes(v.history),
                pageHistoryAllowRestore: ['restore', 'deleterestore'].includes(v.history),
                pageStatusShow: v.status === 'show',
            },
            fieldDefaults: {
                namedShareLink: v.namedShareLinkDefault === 'enable',
                contentEdit: v.contentEditDefault === 'enable',
                commentsShow: v.commentsDefault === 'add' ? true : v.commentsDefault === 'show',
                commentsAdd: v.commentsDefault === 'add',
                attachmentsShow: v.attachmentsDefault === 'add' ? true : v.attachmentsDefault === 'show',
                attachmentsAdd: v.attachmentsDefault === 'add',
                childPagesShare: v.childPagesDefault === 'share',
                restrictChildPagesWithLabelsDefault: v.restrictChildPagesWithLabelsDefault,
                restrictChildPagesWithoutLabelsDefault: v.restrictChildPagesWithoutLabelsDefault,
                labelsShow: v.labelsDefault === 'show',
                unsupportedMacrosRemove: v.unsupportedMacrosDefault === 'placeholder' ? true : v.unsupportedMacrosDefault === 'remove',
                macrosPlaceholderShow: v.unsupportedMacrosDefault === 'placeholder',
                expiration: v.expirationDefault === 'on',
                expirationUnit: v.expirationDefaultPeriod.unit,
                expirationCount: v.expirationDefaultPeriod.count,
                password: v.passwordDefault === 'on',
                selectedUsersShare: v.selectedUsersDefault === 'notify' ? true : v.selectedUsersDefault === 'enable',
                selectedUsersNotify: v.selectedUsersDefault === 'notify',
                samlLogin: v.samlLoginDefault === 'on',
                defaultShareName: v.defaultShareName,
                pageHistoryShow: ['show', 'delete', 'restore', 'deleterestore'].includes(v.historyDefault),
                pageHistoryAllowDelete: ['delete', 'deleterestore'].includes(v.historyDefault),
                pageHistoryAllowRestore: ['restore', 'deleterestore'].includes(v.historyDefault),
                pageStatusShow: v.statusDefault === 'show',
            },
            securityConfig: {
                expirationType: v.expirationType,
                password: v.password,
                expirationUnit: v.expirationPeriod.unit,
                expirationCount: v.expirationPeriod.count,
                selectedUsers: v.selectedUsers,
                allowedSelectedUsers: v.allowedSelectedUsers,
                allowedSelectedUsersEmailDomains: v.allowedSelectedUsersEmailDomains,
                samlLogin: v.samlLogin,
            },
        };
    }

    saveAndExit(x: SpaceSchemaView) {
        this.update(x).subscribe(() => {
            this.confluence.emitEvent(this.refreshEventName, JSON.stringify(x.schema));
            if (!!this.space) {
                AP.flag.create({
                    title: 'External Share | Success',
                    body: 'Space ' + this.space + ' settings has been successfully saved',
                    type: 'success',
                    close: 'auto',
                });
            }
            this.close(x);
        });
    }

    @HostListener('document:keyup', ['$event'])
    onKeyUp(e: KeyboardEvent) {
        if (e.ctrlKey && e.key === 'Enter') {
            this.config$.subscribe(item => {
                this.saveAndExit(item);
            });
        }
    }

    private isOptionsFormValid(): boolean {
        Utils.validateAllFormFields(this.form);
        return this.form.valid;
    }

    update(x: SpaceSchemaView) {
        if (this.isOptionsFormValid()) {
            const v = this.form.getRawValue();
            const config = this.getConfig();
            if (this.isDefaultView(x.data)) {
                return this.app.updateDefaultConfig({item: {config}});
            } else if (this.isGlobalView(x.data)) {
                const spaceIds = v.associatedSpaces.map(it => it.id);
                const view = x.schema as GlobalConfigView;
                return this.app.updateGlobalConfig({item: {id: view.item.id, spaceIds, config}});
            } else if (this.isSpaceView(x.data)) {
                const view = x.schema as SpaceConfigView;
                const spaceId = view.item.spaceId;
                return this.app.updateSpaceConfig({item: {config, spaceId}});
            }
        }
        return of(undefined);
    }

    close(x: SpaceSchemaView) {
        this.confluence.closeDialog(x.schema.item);
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    createOrLoad(customData: CustomData): Observable<ConfigView> {
        customData = customData || {};
        if (this.isDefaultView(customData)) {
            return this.app.getDefaultConfig();
        } else if (this.isGlobalView(customData)) {
            if (customData.isNew) {
                return this.app.createGlobalConfig().pipe(tap((scheme) => {
                    this.confluence.emitEvent(this.refreshEventName, JSON.stringify(scheme));
                }));
            }
            if (customData.id) {
                return this.app.getGlobalConfig(customData.id);
            }
        } else if (this.isSpaceView(customData)) {
            if (customData.spaceId) {
                return this.app.getSpaceConfig(customData.spaceId);
            }
        }
        return throwError('Failed to handle init data in space config form ' + customData);
    }

    confirmDeletion(x: SpaceSchemaView) {
        this.confluence.showDialog({
                key: 'confirm-delete',
                customData: {
                    title: 'Delete Space Config?',
                },
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);

                    const view = x.schema as GlobalConfigView;
                    this.app.deleteGlobalConfig([view.item.id])
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.confluence.emitEvent(this.refreshEventName);
                            this.close(x);
                        });
                }
            },
        );
    }

    isSingleTabAllowed(data: CustomData) {
        return !!data.allowedTab;
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }

    isDefaultView(data: CustomData) {
        return data.type === 'DEFAULT';
    }

    isSpaceView(data: CustomData) {
        return data.type === 'SPACE';
    }

    isGlobalView(data: CustomData) {
        return data.type === 'GLOBAL';
    }

    getParentSecurityConfig(data: SpaceSchemaView): SecurityConfig {
        let result = null;
        if (this.isSpaceView(data.data)) {
            const spaceConfig = data.schema as SpaceConfigView;
            result = spaceConfig.parent.securityConfig;
        }
        return result;
    }

    isDefaultsInvalid() {
        return this.isInvalid('expirationDefaultPeriod');
    }

    private isInvalid(name: string): boolean {
        const control = this.form.get(name);
        return this.isTouchedOrDirty(control) && control.invalid;
    }

    private isTouchedOrDirty(control: AbstractControl) {
        return control.dirty || control.touched;
    }

    private expirationTypeValue(expirationType) {
        return this.isCustomDataModel()
        && !!this.customData.model.expirationType ? this.customData.model.expirationType : expirationType;
    }

    private expirationPeriodUnitValue(expirationUnit) {
        return this.isCustomDataModel()
        && !!this.customData.model.expirationUnit ? this.customData.model.expirationUnit : expirationUnit;
    }

    private expirationPeriodCountValue(expirationCount) {
        return this.isCustomDataModel()
        && !!this.customData.model.expirationCount ? this.customData.model.expirationCount : expirationCount;
    }

    private passwordValue(password) {
        return this.isCustomDataModel()
        && !!this.customData.model.password ? this.customData.model.password : password;
    }

    private isCustomDataModel() {
        return !!this.customData && !!this.customData.model;
    }
}
