import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IconsModule} from '../icons/icons.module';
import {RouterModule} from '@angular/router';
import {GlobalSettingsComponent} from './global-settings/global-settings.component';
import {GlobalViewComponent} from './global-view.component';
import {GlobalListComponent} from './global-list/global-list.component';
import {ApplicationLogsComponent} from './application-logs/application-logs.component';
import {SpaceSchemaSettingsComponent} from './space-schema-settings/space-schema-settings.component';
import {SpaceSchemaFormModule} from '../space-schema-form/space-schema-form.module';
import {GlobalPermissionsComponent} from './global-settings/global-permissions/global-permissions.component';
import {CustomEmailComponent} from './global-settings/custom-email/custom-email.component';
import {EmailTemplateComponent} from './global-settings/email-template/email-template.component';
import {CustomCspComponent} from './global-settings/custom-csp/custom-csp.component';
import {CustomDomainComponent} from './global-settings/custom-domain/custom-domain.component';
import {GlobalViewApiKeysComponent} from './global-view-api-keys/global-view-api-keys.component';
import {PageCustomizationComponent} from './global-settings/page-customization/page-customization.component';
import {AccessComponent} from './global-settings/access/access.component';
import {SamlConfigurationComponent} from './global-settings/saml-config/saml-configuration.component';
import {GlobalActivityComponent} from './global-activity/global-activity.component';
import {SamlManageWorkspaceComponent} from './global-settings/saml-config/saml-manage-workspace/saml-manage-workspace.component';
import {WorkspaceValidatorDirective} from '../utils/workspace-validator.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    PageCustomizationGeneralComponent
} from './global-settings/page-customization/page-customization-general/page-customization-general.component';
import {
    PageCustomizationHeaderComponent
} from './global-settings/page-customization/page-customization-header/page-customization-header.component';
import {
    PageCustomizationPageComponent
} from './global-settings/page-customization/page-customization-page/page-customization-page.component';
import {
    PageCustomizationPasswordPageComponent
} from './global-settings/page-customization/page-customization-password-page/page-customization-password-page.component';
import {
    PageCustomizationFooterComponent
} from './global-settings/page-customization/page-customization-footer/page-customization-footer.component';
import { SamlDomainsComponent } from './global-settings/saml-config/saml-domains/saml-domains.component';

@NgModule({
    declarations: [
        GlobalViewComponent,
        GlobalListComponent,
        GlobalSettingsComponent,
        ApplicationLogsComponent,
        SpaceSchemaSettingsComponent,
        GlobalPermissionsComponent,
        CustomEmailComponent,
        EmailTemplateComponent,
        CustomCspComponent,
        CustomDomainComponent,
        GlobalViewApiKeysComponent,
        PageCustomizationComponent,
        AccessComponent,
        SamlConfigurationComponent,
        GlobalActivityComponent,
        SamlManageWorkspaceComponent,
        WorkspaceValidatorDirective,
        PageCustomizationGeneralComponent,
        PageCustomizationHeaderComponent,
        PageCustomizationPageComponent,
        PageCustomizationPasswordPageComponent,
        PageCustomizationFooterComponent,
        SamlDomainsComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule,
        SpaceSchemaFormModule,
        DragDropModule
    ],
    exports: [
        GlobalViewComponent,
        GlobalListComponent,
        GlobalSettingsComponent,
        ApplicationLogsComponent,
        SpaceSchemaSettingsComponent,
        CustomDomainComponent,
        GlobalPermissionsComponent
    ]
})
export class GlobalViewModule {
}
