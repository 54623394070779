import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DummyComponent} from './utils/dummy.component';
import {RedirectGuard} from './redirect-guard';
import {ShareFormComponent} from './share-form/share-form.component';
import {DeleteConfirmationComponent} from './components/delete-confirmation/delete-confirmation.component';
import {ErrorComponent} from './components/error/error.component';
import {EmailFormComponent} from './email-form/email-form.component';
import {ShareResolver} from './share-resolver.service';
import {ShareListComponent} from './space-view/share-list/share-list.component';
import {SpaceViewResolver} from './space-view/space-view.resolver';
import {SettingsComponent} from './space-view/settings/settings.component';
import {SpaceViewComponent} from './space-view/space-view.component';
import {GlobalSettingsComponent} from './global-view/global-settings/global-settings.component';
import {GlobalViewComponent} from './global-view/global-view.component';
import {GlobalListComponent} from './global-view/global-list/global-list.component';
import {ContentResolver} from './content.resolver';
import {RestrictionsResolver} from './restrictions-resolver.service';
import {ApplicationLogsComponent} from './global-view/application-logs/application-logs.component';
import {SpaceResolver} from './space-resolver';
import {SpaceSchemaSettingsComponent} from './global-view/space-schema-settings/space-schema-settings.component';
import {SpaceSchemaFormComponent} from './space-schema-form/space-schema-form.component';
import {SpaceSchemaFormParentComponent} from './space-schema-form/space-schema-form-parent.component';
import {NoAccessContentViewComponent} from './no-access-content-view/no-access-content-view.component';
import {ShareListAccessGuard} from './guard/share-list-access.guard';
import {ShareFormAccessGuard} from './guard/share-form-access.guard';
import {SpaceAccessGuard} from './guard/space-access.guard';
import {ApiKeyFormParentComponent} from './api-key-form/api-key-form-parent.component';
import {ApiKeyFormComponent} from './api-key-form/api-key-form.component';
import {GlobalViewApiKeysComponent} from './global-view/global-view-api-keys/global-view-api-keys.component';
import {UserShareListComponent} from './personal-view/user-share-list/user-share-list.component';
import {PageViewComponent} from './page-view/page-view/page-view.component';
import {GlobalActivityComponent} from './global-view/global-activity/global-activity.component';
import {SpaceActivityComponent} from './space-view/space-activity/space-activity.component';
import {ShareBulkEditPopupComponent} from './share-bulk-edit/share-bulk-edit-popup.component';

const routes: Routes = [
    {
        path: 'browse/global',
        component: GlobalViewComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: GlobalListComponent
            },
            {
                path: 'activity',
                component: GlobalActivityComponent
            },
            {
                path: 'space-schema-settings',
                component: SpaceSchemaSettingsComponent
            },
            {
                path: 'settings',
                component: GlobalSettingsComponent
            },
            {
                path: 'logs',
                component: ApplicationLogsComponent
            },
            {
                path: 'api-keys',
                component: GlobalViewApiKeysComponent
            },
            {
                path: 'user-list',
                component: UserShareListComponent
            }
        ]
    },
    {
        path: 'browse/space/:spaceKey/:spaceId',
        component: SpaceViewComponent,
        canActivate: [SpaceAccessGuard],
        resolve: {
            space: SpaceResolver,
            config: SpaceViewResolver
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: ShareListComponent
            },
            {
                path: 'activity',
                component: SpaceActivityComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            }
        ]
    },
    {
        path: 'browse/list/share/:spaceKey/:contentId',
        component: PageViewComponent,
        canActivate: [ShareListAccessGuard],
        resolve: {
            space: SpaceResolver,
            content: ContentResolver,
            restrictions: RestrictionsResolver
        }
    },
    {
        path: 'browse/share/:uuid/:contentId',
        component: ShareFormComponent,
        canActivate: [ShareFormAccessGuard],
        resolve: {
            share: ShareResolver,
            content: ContentResolver,
            restrictions: RestrictionsResolver
        }
    },
    {
        path: 'redirect/:location',
        canActivate: [RedirectGuard],
        component: DummyComponent
    },
    {
        path: 'schema',
        component: SpaceSchemaFormParentComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'item'
            },
            {
                path: 'item',
                component: SpaceSchemaFormComponent,
            }
        ]
    },
    {
        path: 'confirm-delete',
        component: DeleteConfirmationComponent
    },
    {
        path: 'bulk-edit',
        component: ShareBulkEditPopupComponent,
    },
    {
        path: 'send-via-mail/:uuid/:contentId',
        component: EmailFormComponent
    },
    {
        path: 'no-access-content-view',
        component: NoAccessContentViewComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },
    {
        path: 'api-key',
        component: ApiKeyFormParentComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'item'
            },
            {
                path: 'item',
                component: ApiKeyFormComponent,
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
