import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EMPTY, merge, NEVER, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

type SortOrderType = 'asc' | 'desc';

export interface TableOpts {
    endPoint: string;
    defaultSort?: string;
    defaultSortOrder?: SortOrderType;
    defaultLimit?: number;
    map?: (it: any) => any;
    filter?: FilterCriteria;
    headers?: HttpHeaders;
    skipFetchData?: boolean;
}

interface Page {
    active: boolean;
    dots: string;
    label: string;
    rangeNumber: number;
}

export interface FilterCriteria {
    contents?: string[];
    spaces?: string[];
    spaceKey?: string;
    contentId?: string;
    uuid?: string;
    source?: string;
    action?: string;
    userName?: string;
    userEmail?: string;
    status?: string;
    createdBy?: string[];
    updatedBy?: string[];
    selectedUsersQuery?: string;
    userId?: string;
    filterByUser?: string;
    searchByUuid?: string;
    searchByLinkName?: string;
}

@Injectable({providedIn: 'root'})
export class ServerDataSource<V, C> {
    public sort = {
        key: '',
        sortOrder: 'asc'
    };

    public filter: C = {} as C;
    public page = 0; // page

    private limitVal = 100;

    public get limit() {
        return this.limitVal;
    }

    public set limit(val: number) {
        this.limitVal = val;
        this.paginationVal.next();
    }

    private refreshTrigger = new Subject<any>();
    private filterVal = new Subject<any>();
    private sourceVal: Observable<any> = EMPTY;
    private paginationVal = new Subject<any>();
    private pagesVal = [];
    private opts: TableOpts;

    public totalCount = 0;
    public totalPages = 0;
    public showGoToPrevious = false;
    public showGoToNext = false;
    public sortChange = new Subject<{ key: string, sortOrder: SortOrderType }>();
    public reportError = false;
    public skipInitialFetchData = false;

    constructor(private http: HttpClient) {
    }

    public reload(opts: TableOpts): Observable<Array<V>> {
        this.opts = opts;
        this.sortChange.subscribe(it => this.sort = it);

        this.sortChange.next({
            key: opts.defaultSort,
            sortOrder: opts.defaultSortOrder || 'asc'
        });

        this.limitVal = opts.defaultLimit || this.limit;
        this.pagesVal = [];
        this.filter = (opts.filter || {}) as C;
        this.skipInitialFetchData = opts.skipFetchData;

        const dataMutations = [
            of([]),
            this.refreshTrigger,
            this.filterVal,
            this.paginationVal,
            this.sortChange
        ];

        this.sourceVal = merge(...dataMutations)
            .pipe(
                debounceTime(200),
                mergeMap(() => this.skipInitialFetchData ? of({items: [], totalCount: 0}) :  this.getData()),
                tap(it => this.update(it)),
                map(it => it.items),
                catchError((error) => {
                    atlas.log('Datasource error ', error);
                    if (this.showErrorMessage(error)) {
                        AP.flag.create({
                            title: 'External Share | Error',
                            body: error.error.message,
                            type: 'error',
                            close: 'auto'
                        });
                    }
                    return this.reportError ? EMPTY : NEVER;
                }),
                map((it: any[]) => opts.map ? it.map(opts.map) : it),
                share()
            );
        return this.sourceVal;
    }

    private showErrorMessage(error) {
        return this.reportError && error && error.error && error.error.message;
    }

    public get(): Observable<Array<V>> {
        return this.sourceVal;
    }

    public getTotalCount(): number {
        return this.totalCount;
    }

    public updateFilter(filter: C) {
        this.skipInitialFetchData = false;
        this.filter = filter;
        this.filterVal.next({name});
    }

    goToNextPage() {
        const pages = Math.ceil(this.totalCount / this.limit);
        if (this.page < pages) {
            this.page++;
            this.paginationVal.next();
        }
    }

    goToPreviousPage() {
        if (this.page > 0) {
            this.page--;
            this.paginationVal.next();
        }
    }

    goToPage(page: number) {
        const pages = Math.ceil(this.totalCount / this.limit);
        if (page >= 0 && page < pages) {
            this.page = page;
            this.paginationVal.next();
        }
    }

    pages(): Page[] {
        return this.pagesVal;
    }

    private update(it: any) {
        this.pagesVal = [];
        const {totalCount} = it;
        this.totalCount = totalCount;

        const pages = Math.ceil(totalCount / this.limit);
        this.totalPages = Math.ceil(totalCount / this.limit);

        if (this.page >= this.totalPages) {
            this.goToPage(this.totalPages - 1);
        }

        this.showGoToPrevious = this.page !== 0;
        this.showGoToNext = totalCount > 0 && this.page + 1 !== this.totalPages;

        const arange = collapseRange(this.page, pages, 6);

        for (const rangeNumber of arange) {
            const dots = rangeNumber === '...';
            this.pagesVal.push({
                active: rangeNumber === this.page,
                dots,
                label: dots ? '...' : (rangeNumber as number) + 1,
                rangeNumber
            });
        }
    }

    private getData(): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                ...this.filter,
                limit: this.limit.toString(),
                offset: (this.limit * this.page).toString(),
                sort: this.sort.key,
                sortOrder: this.sort.sortOrder
            }
        });

        return this.http.get(this.opts.endPoint, {
            params
        }).pipe(catchError((error) => {
                atlas.log('Data source error', error);
                if (this.showErrorMessage(error)) {
                    AP.flag.create({
                        title: 'External Share | Error',
                        body: error.error.message,
                        type: 'error',
                        close: 'auto'
                    });
                }
                return this.reportError ? EMPTY : NEVER;
            }),
        );
    }

    refresh() {
        this.refreshTrigger.next();
    }
}

function collapseRange(
    current: number,
    total: number,
    max: number,
): Array<number | string> {

    // only need ellipsis if we have more pages than we can display
    const needEllipsis = total - 1 > max;

    // show start ellipsis if the current page is further away than max - 3 from the first page
    const hasStartEllipsis = needEllipsis && max - 3 < current;

    // show end ellipsis if the current page is further than total - max + 3 from the last page
    const hasEndEllipsis = needEllipsis && current < total - 4;

    if (!needEllipsis) {
        return range(0, total - 1);
    }

    if (hasStartEllipsis && !hasEndEllipsis) {
        const pageCountCalc = max - 1;
        return [
            0,
            '...',
            ...range(total - pageCountCalc, total - 1),
        ];
    }
    if (!hasStartEllipsis && hasEndEllipsis) {
        const pageCountCalc = max - 2;
        return [
            ...range(0, pageCountCalc),
            '...',
            total - 1,
        ];
    }
    // we have both start and end ellipsis
    const pageCount = max - 4;
    return [
        0,
        '...',
        ...range(
            current - Math.floor(pageCount / 2),
            current + pageCount - 1,
        ),
        '...',
        total - 1,
    ];
}

function range(start, end) {
    return Array(end - start + 1)
        .fill(undefined)
        .map((_, idx) => start + idx);
}
