import {Observable, of, zip} from 'rxjs';
import {map} from 'rxjs/operators';

import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

interface SpaceViewData {
    spaceKey: string;
    userIsAdmin: boolean;
    userIsSpaceAdmin: boolean;
    isAppsView: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SpaceViewResolver implements Resolve<SpaceViewData> {

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<SpaceViewData> | Promise<SpaceViewData> | SpaceViewData {

        return zip(of(route.paramMap), of(route.queryParamMap))
            .pipe(map(([paramMap, queryParamMap]) => {
                const userIsAdmin = queryParamMap.get('user_is_admin') === 'true';
                const userIsSpaceAdmin = (queryParamMap.get('user_is_space_admin') === 'true') || userIsAdmin;
                const spaceKey = paramMap.get('spaceKey');
                const isAppsView = queryParamMap.get('appsView') === 'true';

                return {
                    spaceKey,
                    userIsAdmin,
                    userIsSpaceAdmin,
                    isAppsView
                };
            }));
    }
}

