import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    forwardRef,
    Input,
    OnChanges, Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import Utils from '../utils/utils';

@Component({
    selector: 'app-select',
    template: '<select style="width: 100%" #selectElement></select>',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppSelectComponent),
            multi: true
        }
    ]
})
export class AppSelectComponent implements ControlValueAccessor, OnChanges, AfterViewInit {

    @Input() items: any[];

    @Output() changeEvent = new EventEmitter<Event>();
    @Output() blurEvent = new EventEmitter<Event>();
    @Input() disabled = false;

    @ViewChild('selectElement', {static: true}) selectElement: ElementRef;
    private selectElement$: JQuery<HTMLSelectElement>;

    private value: any;

    private onChangeFn: any = (e) => {
        this.changeEvent.emit(e);
    }

    private onTouchedFn: any = (e) => {
        this.blurEvent.emit(e);
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = (e) => {
            this.changeEvent.emit(e);
            fn(e);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = (e) => {
            this.blurEvent.emit(e);
            fn(e);
        };
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.selectElement$) {
            this.selectElement$.prop('disabled', isDisabled);
        }
    }

    writeValue(obj: any): void {
        this.value = obj;
        if (this.selectElement$) {
            this.selectElement$
                .val(this.value)
                .trigger('change');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.setDisabledState(changes.disabled.currentValue);
        } else {
            const items = changes.items.currentValue as Array<any>;
            this.initialize(items);
        }
    }

    private initialize(items) {
        if (this.selectElement$) {
            if (Utils.isNotEmptyArray(items)) {
                items.forEach(item => {
                    const itemValue = item.value;
                    const option = new Option(item.text, itemValue, false);
                    option['__app-select-value'] = itemValue;
                    this.selectElement$.append(option);
                });
                this.selectElement$
                    .val(this.value)
                    .trigger('change');
            } else {
                this.selectElement$.empty()
                    .val(null)
                    .trigger('change');
            }
        }
    }

    ngAfterViewInit(): void {
        this.selectElement$ = $(this.selectElement.nativeElement);
        const options: any = {
            disabled: this.disabled
        };
        this.selectElement$.select2(options);

        this.selectElement$.on('select2:close', (e: any) => {
            this.onTouchedFn(e);
        });

        this.selectElement$.on('select2:select', (e: any) => {
            const result = this.selectElement$
                .find('option')
                .filter((idx, that: any) => (that as HTMLOptionElement).selected)
                .map((idx, that) => that['__app-select-value'])
                .get();
            this.onChangeFn(result[0]);
        });

        this.initialize(this.items);
    }
}
