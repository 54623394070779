import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipDirective} from './tooltip.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppDropListDirective, AppDropListItemDirective} from '../components/app-droplist';
import {AppMultiSelectComponent} from '../components/app-multi-select.component';
import {NiceDateTimeFormatPipe, ShortNiceDateTimeFormatPipe} from '../components/nice-date-time-format-pipe';
import {AppSelectComponent} from '../components/app-select.component';
import {AppCheckboxComponent} from '../components/app-checkbox.component';
import {AppRadioComponent} from '../components/app-radio.component';
import {AppRadioGroupDirective} from '../components/app-radio-group.directive';
import {LinkToUserComponent} from '../components/link-to-user.component';
import {TableSortHeaderDirective} from '../components/table-sort-header.directive';
import {LinkToContentComponent} from '../components/link-to-content.component';
import {SelectClearAllComponent} from '../components/select-clear-all/select-clear-all.component';
import {LinkInputComponent} from '../components/link-input/link-input.component';
import {DeleteConfirmationComponent} from '../components/delete-confirmation/delete-confirmation.component';
import {DateTimePickerDirective} from '../components/date-time-picker.directive';
import {RowActionComponent} from '../components/row-action/row-action.component';
import {ToggleComponent} from '../components/toggle/toggle.component';
import {ShareActionRowComponent} from '../components/share-action-row/share-action-row.component';
import {IconsModule} from '../icons/icons.module';
import {PeriodComponent} from '../components/period/period.component';
import {AppPageRestrictionsComponent} from '../components/sections/page-restrictions/app-page-restrictions.component';
import {GlobalSettingsLinkDirective} from '../components/global-settings-link/global-settings-link.directive';
import {ShareLinkComponent} from '../components/share-link/share-link.component';
import {AutofocusDirective} from '../components/autofocus.directive';
import {TruncatePipe} from '../components/truncate-pipe';
import {BetterSelectComponent} from '../components/better-select/better-select.component';
import {CtrlKeysDirective} from '../components/ctrl-keys.directive';
import {ShortDescriptionPipe} from '../components/short-description-pipe';
import {ImageUploadComponent} from '../components/image-upload/image-upload.component';
import {TrimInputDirective} from './trim-input.directive';
import {UrlEncodePipe} from '../components/url-encode-pipe';
import {CustomizeColumnsComponent} from '../components/customize-columns/customize-columns.component';
import {ActivityComponent} from '../components/activity/activity.component';
import {ColumnCheckboxComponent} from '../components/customize-columns/column-checkbox.component';
import {ShareListTableComponent} from '../components/share-list-table/share-list-table.component';
import {SafeJsonPipe} from '../components/safe-json-pipe';
import {CamelCaseSplitPipe} from '../components/camel-case-split-pipe';
import {SnakeCaseSplitPipe} from '../components/snake-case-split-pipe';
import {LinkTreeComponent} from '../components/link-tree/link-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import { GroupFormComponent } from '../components/group-form/group-form.component';
import { SectionFormComponent } from '../components/section-form/section-form.component';
import { LinkFormComponent } from '../components/link-form/link-form.component';

@NgModule({
    declarations: [
        TooltipDirective,
        AppDropListDirective,
        AppDropListItemDirective,
        AppMultiSelectComponent,
        AppSelectComponent,
        NiceDateTimeFormatPipe,
        SafeJsonPipe,
        ShortNiceDateTimeFormatPipe,
        TruncatePipe,
        AppCheckboxComponent,
        AppRadioComponent,
        AppRadioGroupDirective,
        LinkToUserComponent,
        LinkToContentComponent,
        TableSortHeaderDirective,
        SelectClearAllComponent,
        LinkInputComponent,
        DeleteConfirmationComponent,
        DateTimePickerDirective,
        RowActionComponent,
        ToggleComponent,
        ShareActionRowComponent,
        PeriodComponent,
        AppPageRestrictionsComponent,
        GlobalSettingsLinkDirective,
        ShareLinkComponent,
        AutofocusDirective,
        BetterSelectComponent,
        CtrlKeysDirective,
        ShortDescriptionPipe,
        ImageUploadComponent,
        TrimInputDirective,
        CamelCaseSplitPipe,
        SnakeCaseSplitPipe,
        UrlEncodePipe,
        CustomizeColumnsComponent,
        ActivityComponent,
        ColumnCheckboxComponent,
        ShareListTableComponent,
        LinkTreeComponent,
        GroupFormComponent,
        SectionFormComponent,
        LinkFormComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule,
        MatTreeModule
    ],
    entryComponents: [
        DeleteConfirmationComponent
    ],
    exports: [
        TooltipDirective,
        AppDropListDirective,
        AppDropListItemDirective,
        AppMultiSelectComponent,
        AppSelectComponent,
        NiceDateTimeFormatPipe,
        ShortNiceDateTimeFormatPipe,
        SafeJsonPipe,
        AppCheckboxComponent,
        AppRadioComponent,
        AppRadioGroupDirective,
        LinkToUserComponent,
        LinkToContentComponent,
        TableSortHeaderDirective,
        SelectClearAllComponent,
        LinkInputComponent,
        DateTimePickerDirective,
        RowActionComponent,
        ToggleComponent,
        ShareActionRowComponent,
        PeriodComponent,
        AppPageRestrictionsComponent,
        GlobalSettingsLinkDirective,
        ShareLinkComponent,
        AutofocusDirective,
        BetterSelectComponent,
        CtrlKeysDirective,
        ShortDescriptionPipe,
        ImageUploadComponent,
        TrimInputDirective,
        CamelCaseSplitPipe,
        SnakeCaseSplitPipe,
        UrlEncodePipe,
        CustomizeColumnsComponent,
        ActivityComponent,
        ColumnCheckboxComponent,
        ShareListTableComponent,
        LinkTreeComponent,
        GroupFormComponent,
        SectionFormComponent,
        LinkFormComponent
    ]
})
export class BaseModule {
}
