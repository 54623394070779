import './error-report';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import 'jquery';
import 'select2';
import 'tippy.js';

document.addEventListener('keydown', evt => {
    const isEscape = 'key' in evt && (evt.key === 'Escape' || evt.key === 'Esc');
    if (!isEscape) {
        return;
    }

    if (AP && AP.dialog && AP.dialog.close) {
        try {
            AP.dialog.close();
        } catch (_) {
            atlas.log(_);
        }
    }
});

if (environment.production) {
    enableProdMode();
}

window.atlas = new class implements Atlas {
    log(...message): void {
        if (!environment.production) {
            console.log('%c[CES]', 'color:white; background-color: #5eba7d', ...message);
        }
    }
}();

function bootstrap() {
    return environment.init()
        .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
        .then((it) => {
            return it;
        });
}

environment.hmr(bootstrap);
