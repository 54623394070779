import {Component, OnDestroy, OnInit} from '@angular/core';
import {merge, Observable, of, Subscription, zip} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ServerDataSource} from '../../components/app-table.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, mergeAll, switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {Share, ShareFilterCriteria} from '../../base/share';
import {ShareActionService} from '../../share-action.service';
import {ConfluenceService} from '../../confluence.service';
import {AppState} from '../../base/share.service';
import {ActivatedRoute} from '@angular/router';
import {Constants} from '../../utils/constants';
import AppUtils from '../../utils/app-utils';
import {Column} from '../../components/customize-columns/customize-columns.component';
import {CsvData, ExportCsv} from '../../components/exportCsv/exportCsv';
import {AppService} from '../../app.service';
import {AtlassianUser} from '../../base/atlassian-user';
import {environment} from '../../../environments/environment';
import {LoaderService} from '../../loader.service';
import {DatePipe} from '@angular/common';
import {SearchFunction} from '../../components/better-select/better-select.component';

@Component({
    selector: 'app-global-list',
    templateUrl: './global-list.component.html',
    styleUrls: ['./global-list.component.css']
})
export class GlobalListComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    features = window.getToken().features;
    config$: Observable<AppState>;

    filterForm = new FormGroup({
        spaces: new FormControl(''),
        contents: new FormControl(''),
        createdBy: new FormControl(''),
        updatedBy: new FormControl(''),
        linkStatus: new FormControl('all'),
        selectedUsersQuery: new FormControl(''),
        searchByUuid: new FormControl(''),
        searchByLinkName: new FormControl('')
    });

    columnForm = new FormGroup({
        columns: new FormControl('')
    });

    deleteSelected = false;

    spacePick: SearchFunction;

    columns = [
        new Column('page-name', 'Page name', true, 1),
        new Column('link-name', 'Link name', true, 2),
        new Column('created-by', 'Created by', true, 3),
        new Column('created', 'Created', true, 4),
        new Column('last-updated-by', 'Last updated by', true, 5),
        new Column('last-updated', 'Last updated', true, 6),
        new Column('opened', 'Opened', true, 7),
        new Column('password', 'Password', true, 8),
        new Column('expiration', 'Expiration', true, 9),
        new Column('link-status', 'Link status', true, 10),
        new Column('selected-users-emails', 'Emails', false, 11),
        new Column('selected-users-domains', 'Domains', false, 12),
        new Column('options', 'Options', true, 13),
        new Column('actions', 'Actions', true, 14)
    ];

    source = new ServerDataSource<Share, ShareFilterCriteria>(this.http);

    moreThanOneItemIsSelected = false;

    titles = new Map();
    users = new Map();

    statuses = Constants.SHARE_STATUSES;

    selectedUserFilterValue;
    filterValue;

    displayErrorCsvMessage = false;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private linkActions: ShareActionService,
                private app: AppService,
                private confluence: ConfluenceService,
                private loader: LoaderService,
                private datePipe: DatePipe) {
        this.spacePick = this.spacePickFoo.bind(this);
    }

    ngOnInit() {
        document.body.classList.add('global-view');

        const refresh = this.confluence.observeEvent('link-update');
        refresh.subscribe(() => {
            this.source.refresh();
        });

        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(_ => Utils.combineParams(this.route.parent)),
            switchMap(params => AppUtils.getAppState(params))
        );

        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 20,
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares',
            }),
            skipFetchData: true
        });

        this.subscription = this.source.get().pipe(
            switchMap((list) => {
                this.confluence.fetchUsers(
                    new Set(list.map(el => el.createdBy).concat(list.map(el => el.updatedBy))),
                    this.users
                );

                const contentIds = [...new Set(list.map(el => el.contentId))].join(', ');
                if (!Utils.isEmpty(contentIds)) {
                    return this.fetchPageTitles(contentIds);
                }
                return of({results: []});
            })
        ).subscribe((title) => {
            title.results.forEach(el => {
                this.titles.set('' + el.content.id, el.content.title);
            });
        });

        this.filterForm.valueChanges.subscribe(it => {
            if (!this.filterValue || this.filterFormSelectValuesChanged(it)) {
                const spaces = (it.spaces || []).map(space => space.id);
                this.source.updateFilter({
                    contents: it.contents || [],
                    spaces,
                    status: it.linkStatus,
                    createdBy: it.createdBy,
                    updatedBy: it.updatedBy,
                    selectedUsersQuery: it.selectedUsersQuery,
                    searchByUuid: it.searchByUuid,
                    searchByLinkName: it.searchByLinkName
                });
            }
            this.filterValue = it;
            this.filterTable(it);
        });

        this.filterForm.patchValue({
            linkStatus: 'active'
        });
    }

    spacePickFoo: SearchFunction = (term) => {
        return this.confluence.searchForSpaces(term).pipe(
            map(results => {
                const keys = results.map(key => key.key);
                return this.confluence.getSpacesByKey(keys).pipe(
                    map(space => {
                        const mappedResults = space.map(s => ({id: s.id, text: s.name}));
                        return {
                            results: mappedResults,
                            pagination: {more: false}
                        };
                    })
                );
            }),
            mergeAll()
        );
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    updateMoreThanOneItemSelected(moreThanOneItemSelected: boolean) {
        this.moreThanOneItemIsSelected = moreThanOneItemSelected;
    }

    exportCSV(): void {
        this.displayErrorCsvMessage = false;
        this.loader.fullscreen(true);
        const app: AppService = this.app;
        const header: string[] = [
            'Page name', 'Page', 'Link name', 'Link', 'Created by',
            'Created', 'Last updated by', 'Last updated', 'Opened',
            'Password', 'Expiration', 'Emails', 'Domains', 'Link status'
        ];

        getAllSharesArray(this.filterForm).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.displayErrorCsvMessage = true;
                this.loader.fullscreen(false);
                return false;
            }
            of(sharesArray).pipe(
                switchMap((shares) => {
                    const userIds = shares.map(el => el.createdBy)
                        .concat(shares.filter(el => el.updatedBy !== null).map(el => el.updatedBy));
                    const users: Observable<AtlassianUser[]> = this.confluence.requestUsers(userIds);

                    const contentIds = new Set(shares.map(el => el.contentId + ''));
                    const pageIdsWithName$: Observable<Map<any, any>> = this.confluence.fetchPageTitlesForExport(contentIds);

                    return zip(of(shares), users, pageIdsWithName$);
                }),
                map(([shares, usersArray, titles]) => {
                    const users: Map<any, any> = new Map(usersArray.map(item => [item.accountId, item.displayName]));
                    const modifiedArray: Share[] = shares.map(el => {
                        el.pageName = titles.get(el.contentId.toString());
                        el.createdBy = users.get(el.createdBy);
                        el.updatedBy = users.get(el.updatedBy);
                        return el;
                    });
                    return modifiedArray;
                })
            ).subscribe((a) => {
                const rows = getRows(a);
                const csvData: CsvData = {rows, header};
                ExportCsv.exportToCsv('global_share_list', csvData);
                this.loader.fullscreen(false);
            });
        });

        function getAllSharesArray(formValue): Promise<Array<Share>> {
            return new Promise(resolve => {
                const query = {spaceId: '', usersQuery: getUsersQuery(formValue)};
                app.getAllShares(query).then(elements => {
                    let sharesArray: Array<Share> = [];
                    for (const array of elements) {
                        sharesArray = sharesArray.concat(array);
                    }
                    resolve(sharesArray);
                });
            });
        }

        function getUsersQuery(filterForm) {
            const filterValues = filterForm.value;
            const keys = Object.keys(filterValues);
            const searchQuery = [];
            keys.forEach((key, index) => {
                if (typeof filterValues[key] === 'string') {
                    if (filterValues[key] !== '') {
                        if (key === 'linkStatus') {
                            searchQuery.push('status=' + filterValues[key]);
                        } else if (key === 'selectedUsersQuery' || key === 'searchByUuid' || key === 'searchByLinkName') {
                            searchQuery.push(`${key}=${filterValues[key]}`);
                        }
                    }
                } else if (Array.isArray(filterValues[key])) {
                    for (const item of filterValues[key]) {
                        if (filterValues[key].length !== 0) {
                            searchQuery.push(`${key}=${item}`);
                        }
                    }
                }
            });
            return searchQuery.join('&');
        }

        const datePipe = this.datePipe;

        function getRows(sharesArray: Share[]): string[][] {
            const rows: string[][] = [];
            for (const data of sharesArray) {
                const row: string[] = [
                    data.pageName,
                    `${environment.host}/wiki/spaces/${data.spaceKey}/pages/${data.contentId}`,
                    data.name,
                    data.contentUrl + (data.namedShareLink ? data.id + '/' + data.linkName : data.uuid),
                    data.createdBy,
                    Utils.toIsoDate(data.created, datePipe),
                    data.updatedBy,
                    Utils.toIsoDate(data.updated, datePipe),
                    data.opened.toString(),
                    data.password,
                    Utils.toIsoDate(data.expiration, datePipe),
                    data.selectedUsersConfig ? data.selectedUsersConfig.emails : '',
                    data.selectedUsersConfig ? data.selectedUsersConfig.domains : '',
                    data.status
                ];
                rows.push(row);
            }
            return rows;
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    edit(link: Share) {
        this.confluence.showDialog({
                key: 'share-edition-popup',
                customData: {
                    uuid: link.uuid,
                    contentId: link.contentId
                }
            }
        );
    }

    searchValue(data) {
        return data.id;
    }

    spaceTemplateResult(item) {
        return item.name;
    }

    contentsSearch = (term) => {
        if (!term) {
            return of();
        }

        const query = `title~%27${term}*%27`;

        return this.confluence.searchForContent(query || '').pipe(
            map(it => it.results || []),
            map(it => {
                const results = it
                    .filter(i => i.content && i.content.type === 'page')
                    .map(i => ({
                        text: Utils.replaceSearchText(i.content.title),
                        title: Utils.replaceSearchTitle(i.content.title),
                        id: i.content.id
                    }));

                return {results};
            })
        );
    }

    templateResult(data) {
        if (data.iconUrl) {
            return $(
                '<span class="avatar-container">' +
                '<img src="' + data.iconUrl + '" class="avatar" /><span>' + Utils.sanitize(data.title) + '</span>' +
                '</span>'
            );
        }
        return data.title;
    }

    userSearch = (term) => {
        return this.confluence.searchUsers(term || '_');
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }

    doDeleteSelected(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Share[];
        this.linkActions.deleteMultiple(toDelete).then((result: { deleted: boolean }) => {
            this.moreThanOneItemIsSelected = !result.deleted;
        });
    }

    doEditSelected(elements: any[]) {
        const toEdit = elements.filter(it => it._delete) as Share[];
        this.linkActions.bulkEdit(toEdit, this.source.filter);
    }

    filterTable(value) {
        if (value !== this.selectedUserFilterValue) {
            this.selectedUserFilterValue = value;

            const it = this.filterForm.value;
            const spaces = (it.spaces || []).map(space => space.id);
            this.source.updateFilter({
                contents: it.contents || [],
                spaces,
                status: it.linkStatus,
                createdBy: it.createdBy,
                updatedBy: it.updatedBy,
                selectedUsersQuery: it.selectedUsersQuery,
                searchByUuid: it.searchByUuid,
                searchByLinkName: it.searchByLinkName
            });
        }
    }

    private fetchPageTitles(contentIds) {
        const query = `id%20in%20(${contentIds})`;
        return this.confluence.searchForContent(query || '');
    }

    private filterFormSelectValuesChanged(value): boolean {
        return !this.filterValue ||
            (this.filterValue.contents !== value.contents) ||
            (this.filterValue.spaces !== value.spaces) ||
            (this.filterValue.linkStatus !== value.linkStatus) ||
            (this.filterValue.createdBy !== value.createdBy) ||
            (this.filterValue.updatedBy !== value.updatedBy);
    }
}
