import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TreeItemNode} from '../../../../components/link-tree/link-tree.component';

@Component({
    selector: 'app-page-customization-footer',
    templateUrl: './page-customization-footer.component.html',
    styleUrls: ['./page-customization-footer.component.css']
})
export class PageCustomizationFooterComponent {
    @Input() form: FormGroup;

    onTreeDataChange(treeData: TreeItemNode[]) {
        this.form.get('pageFooter.customFooterLinks').setValue(treeData);
    }
}
