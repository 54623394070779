import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Config} from '../share-form-config';
import {SelectedUsersConfig} from '../../base/selected-users-config';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-selected-users',
    templateUrl: './selected-users.component.html',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectedUsersComponent), multi: true}
    ],
    styleUrls: ['./selected-users.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedUsersComponent implements OnInit, ControlValueAccessor {

    @Input() form: FormGroup;
    @Input() config: Config;

    users: string[] = [];

    constructor() {
    }

    ngOnInit(): void {
        this.selectedUsersEmailDomains.valueChanges.subscribe(v => {
            if (v) {
                let value = v.toLowerCase();
                if (v.includes(' ')) {
                    value = value.replace(/\s/g, '');
                }
                this.selectedUsersEmailDomains.setValue(value, {emitEvent: false});
            }
        });
    }

    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    get value() {
        return this.users;
    }

    set value(val) {
        this.users = val;
        this.onChange(val);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
        }
    }

    remove(email: string) {
        this.users = this.users.filter(u => !(u === email));
        this.onChange(this.users);
    }

    refresh(users: string[]) {
        this.users = users;
        this.onChange(users);
    }

    shouldDisplayError(): boolean {
        return this.getSelectedUsers().invalid && this.errorCouldBeDisplayed();
    }

    errorCouldBeDisplayed(): boolean {
        const control: AbstractControl = this.getSelectedUsers();
        if (this.recipientsDisabled()) {
            return false;
        }
        return control.dirty || control.touched;
    }

    getSelectedUsers(): AbstractControl {
        return this.form.get('selectedUsers');
    }

    recipientsDisabled(): boolean {
        return !this.form.get('allowSelectedUsers').value;
    }

    readOnly(): boolean {
        return this.form.disabled;
    }

    hasParentRestrictions(config: SelectedUsersConfig): boolean {
        return Utils.isNotEmptyArray(config.allowedEmails)
            || Utils.isNotEmptyArray(config.allowedDomains);
    }

    getParentRestrictions(config: SelectedUsersConfig): string {
        let result = '';
        let isSingle = false;
        if (this.isNotEmptyArray(config.allowedEmails)) {
            isSingle = this.hasSize(config.allowedEmails, 1);
            result += 'Only email address' + (this.hasSize(config.allowedEmails, 1) ? '' : 'es') + ': '
                + config.allowedEmails.join(', ');
        }
        if (this.isNotEmptyArray(config.allowedDomains)) {
            isSingle = false;
            if (result.length > 0) {
                result += ' or ';
            } else {
                result += 'Only ';
            }
            result += 'emails from domain' + (this.hasSize(config.allowedDomains, 1) ? '' : 's') + ': '
                + config.allowedDomains.join(', ');
        }
        result += (isSingle ? ' is' : ' are') + ' allowed';
        return result;
    }

    get allowedDomains() {
        return this.config.share.selectedUsersConfig.allowedDomains;
    }

    emailDomainsAllowed(): boolean {
        const selectedUsersConfig = this.config.share.selectedUsersConfig;
        return selectedUsersConfig.allowedDomains.length > 0
            || selectedUsersConfig.allowedEmails.length === 0;
    }

    get selectedUsersEmailDomains() {
        return this.form.get('selectedUsersEmailDomains');
    }

    private isNotEmptyArray(items): boolean {
        return Utils.isNotEmptyArray(items)
            && !Utils.isEmpty(items[0]);
    }

    private hasSize(items: [], size: number): boolean {
        return Utils.isNotEmptyArray(items)
            && items.length === size;
    }
}
