import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-data-options',
    templateUrl: './data-options.component.html',
    styleUrls: ['./data-options.component.css']
})
export class DataOptionsComponent implements OnInit {

    features = window.getToken().features;

    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;

    content = [];
    namedShareLink = [];
    comments = [];
    attachments = [];
    childPages = [];
    labels = [];
    unsupportedMacros = [];
    history = [];
    status = [];

    ngOnInit(): void {
        const opt = this.enabled.dataOptions;

        this.namedShareLink = [
            ['off', 'Disable', null, opt.namedShareLink.off],
            ['enable', 'Enable', 'Allows public link to be used when accessing the shared link', opt.namedShareLink.enable],
        ];

        this.content = [
            ['off', 'Disable', null, opt.contentEdit.off],
            ['enable', 'Enable', 'Anyone with access to the link can edit page content, no Confluence account or license required!',
                opt.contentEdit.enable],
        ];

        this.comments = [
            ['off', 'Disable', null, opt.comments.off],
            ['show', 'Allow View', 'All comments will be visible on the shared link', opt.comments.show],
            ['add', 'View & Add', 'Anyone with access to the link can add comments, no Confluence account or license required!',
                opt.comments.add]
        ];

        this.attachments = [
            ['off', 'Disable', null, opt.attachments.off],
            ['show', 'Allow View', 'All attachments will be visible on the shared link', opt.attachments.show],
            ['add', 'View & Add', 'Anyone with access to the link can add attachments, no Confluence account or license required!',
                opt.attachments.add]
        ];

        this.childPages = [
            ['off', 'Disable', null, opt.childPages.off],
            ['share', 'Share child pages', 'Child pages will be accessible with the same permissions as parent link', opt.childPages.share]
        ];

        this.labels = [
            ['off', 'Disable', null, opt.labels.off],
            ['show', 'Share labels', 'All labels will be visible on the shared link', opt.labels.show]
        ];

        this.unsupportedMacros = [
            ['off', 'Disable', null, opt.unsupportedMacros.off],
            ['remove', 'Remove', 'All unsupported macros will be removed from the shared link', opt.unsupportedMacros.remove],
            ['placeholder', 'Show placeholders', 'Removed unsupported macro placeholders will be displayed on the shared link',
                opt.unsupportedMacros.placeholder]];

        this.history = [
            ['off', 'Disable', null, opt.history.off],
            ['show', 'Allow View', 'Page history will be accessible on the shared link', opt.history.show],
            ['delete', 'View & Delete', 'Anyone with access to the link can delete page version,' +
                ' no Confluence account or license required!', opt.history.delete],
            ['restore', 'View & Restore', 'Anyone with access to the link can restore page version,' +
                ' no Confluence account or license required!', opt.history.restore],
            ['deleterestore', 'View & Delete & Restore', 'Anyone with access to the link can delete and restore page version,' +
                ' no Confluence account or license required!', opt.history.deleterestore],
        ];

        this.status = [
            ['off', 'Disable', null, opt.status.off],
            ['show', 'Enable', 'Page status will be visible on the shared link', opt.status.show]
        ];
    }
}
