import {Component, Input, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {AppService} from '../../../app.service';
import {ConfluenceService} from '../../../confluence.service';
import {GlobalViewSettingsComponentConfig} from '../global-settings.component';
import {FormGroup} from '@angular/forms';
import {ViewItem} from '../../../view-item';

@Component({
    selector: 'app-global-permissions',
    templateUrl: './global-permissions.component.html',
    styleUrls: ['./global-permissions.component.css']
})
export class GlobalPermissionsComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() config: GlobalViewSettingsComponentConfig;

    viewOwnSharesOnPageUserGroups: ViewItem[];
    viewShareOnPageUserGroups: ViewItem[];
    createNewShareUserGroups: ViewItem[];
    editShareUserGroups: ViewItem[];
    deleteShareUserGroups: ViewItem[];
    emailShareUserGroups: ViewItem[];
    viewSharesFromSpaceUserGroups: ViewItem[];

    constructor(private app: AppService,
                private confluence: ConfluenceService) {
    }

    ngOnInit(): void {
        this.viewOwnSharesOnPageUserGroups = this.initializeGroups('viewOwnSharesOnPageUserGroups');
        this.viewShareOnPageUserGroups = this.initializeGroups('viewShareOnPageUserGroups');
        this.createNewShareUserGroups = this.initializeGroups('createNewShareUserGroups');
        this.editShareUserGroups = this.initializeGroups('editShareUserGroups');
        this.deleteShareUserGroups = this.initializeGroups('deleteShareUserGroups');
        this.emailShareUserGroups = this.initializeGroups('emailShareUserGroups');
        this.viewSharesFromSpaceUserGroups = this.initializeGroups('viewSharesFromSpaceUserGroups');
    }

    groupSearch = (term) => {
        return this.searchForGroup(term || '_')
            .pipe(
                map(groups => {
                    groups.results.forEach(it => {
                        it.id = it.name;
                        it.text = it.name;
                    });
                    return {results: groups.results};
                })
            );
    }

    searchForGroup(term) {
        return this.confluence.searchForGroup(term);
    }

    groupSearchValue(item) {
        return item.id;
    }

    groupTemplateResult(item) {
        return item.id;
    }

    private initializeGroups(groupName) {
        const userGroups = this.form.get(groupName);
        if (!!userGroups.value) {
            return userGroups.value.map(v => ({id: v, text: v, selected: true}));
        }
        return undefined;
    }
}
