import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-section-form',
    templateUrl: './section-form.component.html',
    styleUrls: ['./section-form.component.css']
})
export class SectionFormComponent implements OnInit {
    form: FormGroup;
    @Input() initialSectionName: string | null = null;
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            newSectionName: ['', [Validators.required, Validators.maxLength(80)]]
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            newSectionName: this.initialSectionName
        });
    }

    isInvalid(controlName: string): boolean {
        const control = this.form.get(controlName);
        return control && control.invalid && (control.dirty || control.touched);
    }

    saveSection() {
        if (this.form.valid) {
            this.save.emit(this.form.value);
            this.form.reset();
        }
    }

    cancelSection() {
        this.cancel.emit();
        this.form.reset();
    }
}
