import {Component, ElementRef, forwardRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true
        }
    ]
})
export class ToggleComponent implements OnInit, ControlValueAccessor {

    @ViewChild('inputElement', {static: true})
    inputElement: ElementRef;

    get isFocusable() {
        return this.inputElement;
    }

    /**
     * @description
     * The registered callback function called when a change event occurs on the input element.
     */
    onChange = (_: any) => {
    }

    /**
     * @description
     * The registered callback function called when a blur event occurs on the input element.
     */
    onTouched = () => {
    }

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    /* ControlValueAccessor START */

    /**
     * Sets the 'checked' property on the input element.
     *
     * @param value The checked value
     */
    writeValue(value: any): void {
        this.renderer.setProperty(this.inputElement.nativeElement, 'checked', value);
    }

    /**
     * @description
     * Registers a function called when the control value changes.
     *
     * @param fn The callback function
     */
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }

    /**
     * @description
     * Registers a function called when the control is touched.
     *
     * @param fn The callback function
     */
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Sets the 'disabled' property on the input element.
     *
     * @param isDisabled The disabled value
     */
    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.inputElement.nativeElement, 'disabled', isDisabled);
    }

    /* ControlValueAccessor END*/
}
