export interface AtlassianUser {
    displayName: string;
    accountId: string;
    username?: string;
    profilePicture?: {
        height: number;
        isDefault: boolean;
        path: string;
        width: number;
    };
    _links?: {
        base: string;
        context: string;
        self: string;
    };
}

export const EMPTY_USER = {
    displayName: undefined,
    accountId: undefined
} as AtlassianUser;
