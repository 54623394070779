import {Component, OnInit} from '@angular/core';
import {ConfluenceService, Space} from '../confluence.service';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import Utils from '../utils/utils';
import {namedZip} from '../utils/rxjs.util';

interface SpaceViewComponentConfig {
    space: Space;
    userIsAdmin: boolean;
    userIsSpaceAdmin: boolean;
    isLite: boolean;
    title: string;
    isAppsView: boolean;
}

@Component({
    selector: 'app-space-view',
    templateUrl: './space-view.component.html',
    styleUrls: ['./space-view.component.css']
})
export class SpaceViewComponent implements OnInit {

    private title = 'External Share for Confluence';

    config$: Observable<SpaceViewComponentConfig>;

    constructor(private confluence: ConfluenceService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.confluence.onEvent('link-update', () => {
        });

        this.config$ = namedZip({
            data: this.route.data,
            isLite: Utils.isLiteApp$(this.route.queryParamMap)
        }).pipe(
            map(({data, isLite}) => {
                const title = isLite ? this.title + ' - Lite' : this.title;
                return Object.assign({}, data.config, {
                    isLite,
                    title,
                    space: data.space
                });
            }));

        this.confluence.resize('es-expand');
    }

    navigateToSpaces() {
        AP.navigator.go('site', {
            relativeUrl: '/spaces'
        });
    }

    navigateToSpace(spaceKey: string) {
        AP.navigator.go('spaceview', {spaceKey});
    }
}
