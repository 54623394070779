import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfluenceService} from '../../confluence.service';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppService} from '../../app.service';
import {ApiKeyConfigView} from '../../base/apikey.config';
import {AtlassianUser} from '../../base/atlassian-user';
import {ConfigData, CustomData} from '../data';

@Component({
    selector: 'app-api-key-history',
    templateUrl: './api-key-history.component.html',
    styleUrls: ['./api-key-history.component.css']
})
export class ApiKeyHistoryComponent implements OnInit {
    @Output() viewConfig = new EventEmitter<ConfigData>();
    apiKeyConfigViews: ApiKeyConfigView[] = [];
    users: Map<string, AtlassianUser>;

    constructor(private confluence: ConfluenceService,
                private app: AppService) {
    }

    ngOnInit() {
        this.confluence.getCustomData<CustomData>()
            .pipe(
                switchMap(data => {
                    if (data.id) {
                        return this.app.getApiKeyConfigHistory(data.id);
                    } else {
                        return of([]);
                    }
                })
            )
            .subscribe(apiKeyConfigViews => {
                this.apiKeyConfigViews = apiKeyConfigViews;
                this.loadUsers(apiKeyConfigViews);
            });
    }

    private loadUsers(list: ApiKeyConfigView[]) {
        this.confluence.fetchUsersMap(
            new Set(list.map(el => el.config.createdById)),
        ).subscribe(usersMap => {
            this.users = usersMap;
        });
    }

    close() {
        this.confluence.closeDialog();
    }

    view(apiKey: ApiKeyConfigView, user: AtlassianUser) {
        this.viewConfig.emit({apiKey, user});
    }

    getUser(accountId) {
        return this.users ? this.users.get(accountId) : undefined;
    }
}
