import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from './environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Utils from './app/utils/utils';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private http: HttpClient) {
    }

    handleError(error) {
        atlas.log('Angular Error Handler', error);

        const errorObject = {
            columnNumber: error.columnNumber,
            fileName: error.fileName,
            lineNumber: error.lineNumber,
            message: error.message,
            stack: error.zoneAwareStack || error.stack,
            url: window.location.toString(),
            browser: Utils.detectBrowser(),
            fullError: this.getFullError(error)
        };

        this.http.post('/api/report-error', errorObject, {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        }).subscribe();
    }

    private getFullError(error) {
        try {
            return JSON.stringify(this.decycle(error));
        } catch (e) {
            return undefined;
        }
    }

    private decycle(obj, stack = []): any {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }
        if (stack.includes(obj)) {
            return null;
        }
        const s = stack.concat([obj]);

        return Array.isArray(obj)
            ? obj.map(x => this.decycle(x, s))
            : this.fromEntries(
                Object.entries(obj)
                    .map(([k, v]) => [k, this.decycle(v, s)]));
    }

    private fromEntries(xs) {
        return xs.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
    }
}

const provider = [];

if (environment.production) {
    provider.push({provide: ErrorHandler, useClass: GlobalErrorHandler});
}

export const GlobalErrorHandlerProvider = provider;
