import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorComponent} from './components/error/error.component';
import {DummyComponent} from './utils/dummy.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BaseModule} from './base/base.module';
import {ShareFormModule} from './share-form/share-form.module';
import {EmailFormModule} from './email-form/email-form.module';
import {SpaceViewModule} from './space-view/space-view.module';
import {GlobalViewModule} from './global-view/global-view.module';
import {ServerInterceptor} from './base/server-interceptor.service';
import {TokenInterceptor} from './base/token.interceptor';
import {ErrorInterceptor} from './base/error.interceptor';
import {GlobalErrorHandlerProvider} from '../global-error-handler';
import {NoAccessContentViewComponent} from './no-access-content-view/no-access-content-view.component';
import {SpaceSchemaFormModule} from './space-schema-form/space-schema-form.module';
import {ApiKeyFormModule} from './api-key-form/api-key-form.module';
import {PersonalViewModule} from './personal-view/personal-view.module';
import {PageViewModule} from './page-view/page-view.module';
import {DatePipe} from '@angular/common';
import {IconsModule} from './icons/icons.module';
import {ShareBulkEditModule} from './share-bulk-edit/share-bulk-edit.module';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        AppComponent,
        DummyComponent,
        ErrorComponent,
        NoAccessContentViewComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BaseModule,
        PageViewModule,
        ShareFormModule,
        EmailFormModule,
        SpaceViewModule,
        GlobalViewModule,
        ShareBulkEditModule,
        SpaceSchemaFormModule,
        ApiKeyFormModule,
        PersonalViewModule,
        IconsModule,
        DragDropModule
    ],
    exports: [],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        ...GlobalErrorHandlerProvider],
    bootstrap: [AppComponent]
})
export class AppModule {
}
