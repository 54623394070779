import {Injectable} from '@angular/core';
import {Claims} from '../environments/claims';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ShareService} from './base/share.service';
import {DefaultConfigView, GlobalConfigView, SpaceConfigView} from './base/config';
import {ApiKeyConfigView, ApiKeyUsage} from './base/apikey.config';
import {Share} from './base/share';
import {Activity} from './base/activity';
import {User} from './base/user';

export interface MigrationResult {
    message: string;
    success: boolean;
}

export interface GlobalSettings {
    allowedIPs: string;
    emailSettings: EmailSettings;
    emailTemplate: EmailTemplate;
    customDomain: CustomDomain;
    pageSettings: PageSettings;
    removeDirectLinks: boolean;
    customCsp?: string;
    sameSiteCookieRestriction?: string;
    samlConfig: SamlConfig;
}

export interface HeaderLinkStyles {
    color: string;
    colorDarkMode: string;
    fontSize: string;
    padding: string;
    margin: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    hoverColor: string;
    hoverColorDarkMode: string;
}

export interface HeaderButtonStyles {
    fontSize: string;
    color: string;
    colorDarkMode: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
}

export interface CustomBody {
    showBackgroundImage: boolean;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    backgroundImage: string;
    backgroundImageDarkMode: string;
}

export interface PageHeader {
    customHeader: string;
    color: string;
    colorDarkMode: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    locationSelect: string;
    headerLinkStyles: HeaderLinkStyles;
    headerButtonStyles: HeaderButtonStyles;
    logoHeight: string;
    logoWidth: string;
    hideHeaderLinks: boolean;
    showHeader: boolean;
    customHeaderLinks: string[];
}

export interface PageFooter {
    customFooter: string;
    color: string;
    colorDarkMode: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    customFooterLinks: string[];
    showFooter: boolean;
    customFooterLinkFontSize: string;
}

export interface MainFrame {
    width: string;
    height: string;
    margin: string;
    padding: string;
    buttonColor: string;
    svgColor: string;
    buttonTextColor: string;
    boxShadow: string;
    borderWidth: string;
    borderStyle: string;
    borderColor: string;
    buttonColorDarkMode: string;
    buttonTextColorDarkMode: string;
    svgColorDarkMode: string;
    boxShadowColor: string;
    boxShadowColorDarkMode: string;
    borderColorDarkMode: string;
    borderRadius: string;
}

export interface Page {
    fontFamily: string;
    genericFontFamily: string;
    fontSize: string;
    creatorDataFontSize: string[];
    contentFontSize: string[];
}

export interface PageNavigation {
    backgroundColor: string;
    backgroundColorDarkMode: string;
    backgroundImage: string;
    backgroundImageDarkMode: string;
    textColor: string;
    textColorDarkMode: string;
    fontSize: string;
    showBackgroundImage: boolean;
    hover: string;
    hoverDarkMode: string;
    linkColor: string;
    linkColorDarkMode: string;
}

export interface PasswordPage {
    fontFamily: string;
    genericFontFamily: string;
}

export interface Table {
    borderWidth: string;
    borderStyle: string;
    borderColor: string;
    borderColorDarkMode: string;
    fontSize: string;
}

export interface PageSettings {
    darkModeEnabled: boolean;
    pageHeader: PageHeader;
    customBody: CustomBody;
    pageFooter: PageFooter;
    mainFrame: MainFrame;
    page: Page;
    pageNavigation: PageNavigation;
    passwordPage: PasswordPage;
    table: Table;
    showLogo: boolean;
    showSubscribe: boolean;
    showMyAccount: boolean;
    analyticsID: string;
    logo: Image;
    logoDark: Image;
    favicon: Image;
    showSearchForm: boolean;
    disableVideoDownload: boolean;
    titleAsLink: boolean;
}

export interface Image {
    base64: string;
    mime: string;
}

export interface CustomDomain {
    enabled: boolean;
    domain: string;
    status?: UpdateCustomDomainResult;
    enableRedirectUrl: boolean;
    redirectUrl: string;
}

export interface SamlConfig {
    samlEnabled: boolean;
    loginUrl: string;
    identifier: string;
    logoutUrl: string;
    certificate: string;
    workspace: string;
    identityProvider: string;
    defaultRelayState?: string;
    assertionConsumerUrl?: string;
    issuerId?: string;
    domains: SamlDomain[];
}

export interface SamlDomain {
    uuid: string;
    domain: string;
    txtRecord: string;
    status: 'VERIFIED' | 'UNVERIFIED';
}

export class UpdateCustomDomainResult {
    status?: 'ERROR' | 'INVALID' | 'MODIFIED' | 'NOT_MODIFIED';
}

export interface EmailSettings {
    custom: boolean;
    host: string;
    port: string;
    username: string;
    from?: string;
    personalName?: string;
    password: string;
}

export interface EmailTemplate {
    title: string;
    content: string;
    template: string;
}

export interface UserGroup {
    name: string;
}

export interface UserGroupResult {
    results: UserGroup[];
}

export interface ActivityList {
    items: Activity[];
    totalCount: number;
}

export interface SharesList {
    items: Share[];
    totalCount: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(private http: HttpClient) {
    }

    getAllActivity(query: { spaceKey: string, usersQuery: string }): Promise<Activity[]> {
        const activityLimit = 200;
        return new Promise<Activity[]>((resolve) => {
            this.getAllActivityAggregated([], 0, activityLimit, query, ((val: Activity[]) => {
                    resolve(val);
                })
            );
        });
    }

    getAllActivityAggregated(aggregated: Activity[][], page: number,
                             activityLimit: number, query: { spaceKey: string, usersQuery: string }, cb): void {
        const offset: number = activityLimit * page;
        this.http.get<ActivityList>(
            '/api/share/activity?' + query.usersQuery + '&spaceKey=' + query.spaceKey + '&limit=' + activityLimit + '&offset=' + offset
        ).subscribe(value => {
            aggregated.push(value.items);
            if (value.items.length === activityLimit) {
                this.getAllActivityAggregated(aggregated, page + 1, activityLimit, query, cb);
            } else {
                cb(aggregated);
            }
        });
    }

    getAllShares(query: { spaceId: string, usersQuery: string }): Promise<Share[]> {
        const sharesLimit = 200;
        return new Promise<Share[]>((resolve) => {
            this.getAllSharesAggregated([], 0, sharesLimit, query, ((val: Share[]) => {
                    resolve(val);
                })
            );
        });
    }

    getAllSharesAggregated(aggregated: Share[][], page: number, sharesLimit: number,
                           query: { spaceId: string, usersQuery: string }, cb): void {
        const offset: number = sharesLimit * page;
        this.http.get<SharesList>(
            '/api/share?' + query.usersQuery + '&spaces=' + query.spaceId + '&limit=' + sharesLimit + '&offset=' + offset
        ).subscribe(value => {
            aggregated.push(value.items);
            if (value.items.length === sharesLimit) {
                this.getAllSharesAggregated(aggregated, page + 1, sharesLimit, query, cb);
            } else {
                cb(aggregated);
            }
        });
    }

    migrate(): Observable<MigrationResult> {
        return this.http.post<MigrationResult>('/api/share-lite/migrate', undefined, {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        });
    }

    searchForGroup(query: string): Observable<UserGroupResult> {
        const q = `query=${query}`;
        return this.http.get<UserGroupResult>('/api/user-groups?' + q);
    }

    getGlobalSettings(): Observable<GlobalSettings> {
        const options = ShareService.getOptions('Get global settings');
        return this.http.get<GlobalSettings>('/api/global-settings', options);
    }

    updateGlobalSettings(body: GlobalSettings) {
        const options = ShareService.getOptions('Update global settings');
        return this.http.put<GlobalSettings>('/api/global-settings', body, options);
    }

    restoreDefaultEmailTemplate() {
        const options = ShareService.getOptions('Restore default email template global settings');
        return this.http.get<GlobalSettings>('/api/global-settings/restore-email-template', options);
    }

    restoreDefaultPageSettings() {
        const options = ShareService.getOptions('Restore default email template global settings');
        return this.http.get<GlobalSettings>('/api/global-settings/page-settings-defaults', options);
    }

    get token() {
        return window.getToken().token;
    }

    claims(): Claims {
        return window.getToken().claims;
    }

    getDefaultConfig(): Observable<DefaultConfigView> {
        const options = ShareService.getOptions('Get default space config');
        return this.http.get<DefaultConfigView>('/api/config/default', options);
    }

    updateDefaultConfig(settings: DefaultConfigView): Observable<DefaultConfigView> {
        const options = ShareService.getOptions('Get default space config');
        return this.http.put<DefaultConfigView>('/api/config/default', settings, options);
    }

    createGlobalConfig(): Observable<GlobalConfigView> {
        const options = ShareService.getOptions('Create space config');
        return this.http.post<GlobalConfigView>('/api/config/global', {item: {config: {}}}, options);
    }

    getGlobalConfig(id: string): Observable<GlobalConfigView> {
        const options = ShareService.getOptions('Get space config');
        return this.http.get<GlobalConfigView>('/api/config/global/' + id, options);
    }

    updateGlobalConfig(settings: GlobalConfigView) {
        const options = ShareService.getOptions('Update space config');
        return this.http.put('/api/config/global', settings, options);
    }

    deleteGlobalConfig(settings: string[]) {
        const options = ShareService.getOptions('Delete space config');
        return this.http.post('/api/config/global-delete', settings, options);
    }

    getSpaceConfig(projectId: string): Observable<SpaceConfigView> {
        const options = ShareService.getOptions('Get space config');
        return this.http.get<SpaceConfigView>('/api/config/space?spaceId=' + projectId, options);
    }

    updateSpaceConfig(settings: SpaceConfigView): Observable<SpaceConfigView> {
        const options = ShareService.getOptions('Update space config');
        return this.http.put<SpaceConfigView>('/api/config/space', settings, options);
    }

    getApiKeyConfig(id: string): Observable<ApiKeyConfigView> {
        return this.http.get<ApiKeyConfigView>('/api-key/config/' + id,
            ShareService.getOptions('Get Api key config'));
    }

    getApiKeyConfigHistory(id: string): Observable<ApiKeyConfigView[]> {
        return this.http.get<ApiKeyConfigView[]>('/api-key/config/history/' + id,
            ShareService.getOptions('Get Api key config'));
    }

    getApiKeyUsage(id: string): Observable<ApiKeyUsage[]> {
        return this.http.get<ApiKeyUsage[]>('/api-key/config/usage/' + id,
            ShareService.getOptions('Get Api key usage'));
    }

    createApiKeyConfig(): Observable<ApiKeyConfigView> {
        return this.http.post<ApiKeyConfigView>('/api-key/config', ShareService.getOptions('Create api key config'));
    }

    updateApiKeyConfig(settings: ApiKeyConfigView) {
        return this.http.put<ApiKeyConfigView>('/api-key/config', settings,
            ShareService.getOptions('Update api key config'));
    }

    deleteApiKeyConfig(id: number) {
        return this.http.delete('/api-key/config/' + id,
            ShareService.getOptions('Delete api key config'));
    }

    checkWorkspace(workspace: string): Observable<boolean> {
        return this.http.post<boolean>(
            '/api/workspace/validity?workspace=' + workspace, ShareService.getOptions('Check workspace'));
    }

    deleteWorkspaceForUsers(userIds: number[]) {
        return this.http.post<User[]>(
            '/api/workspace/remove', userIds, ShareService.getOptions('Remove workspace for users'));
    }

    getUsersInWorkspace(): Observable<User[]> {
        return this.http.get<User[]>('/api/workspace/users', ShareService.getOptions('Get all users in a workspace'));
    }

    addSamlDomain(domain: string) {
        return this.http.post<SamlDomain[]>(
            '/api/saml/domain', domain, ShareService.getOptions('Create saml domain'));
    }

    verifySamlDomain(uuid: string) {
        return this.http.post<SamlDomain[]>(
            '/api/saml/domain/verify', uuid, ShareService.getOptions('Verify saml domain'));
    }

    deleteSamlDomain(uuid: string) {
        return this.http.delete<SamlDomain[]>(
            '/api/saml/domain/' + uuid, ShareService.getOptions('Delete saml domain'));
    }
}
