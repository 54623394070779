import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../../utils/utils';
import {ImageUploadComponent} from '../../../../components/image-upload/image-upload.component';

@Component({
    selector: 'app-page-customization-general',
    templateUrl: './page-customization-general.component.html',
    styleUrls: ['./page-customization-general.component.css']
})
export class PageCustomizationGeneralComponent {
    @Input() form: FormGroup;
    @ViewChildren(ImageUploadComponent) imageUploadComponents: QueryList<ImageUploadComponent>;

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    private resetImageUploadComponentInput(formControlName: string) {
        const imageUploadComponent = this.imageUploadComponents.find(
            (uploadComponent: ImageUploadComponent) => uploadComponent.formControlName === formControlName
        );
        imageUploadComponent?.resetInput();
    }

    removeFavicon() {
        this.form.patchValue({favicon: null});
        this.resetImageUploadComponentInput('favicon');
    }
}
