import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ShareResult} from './share-result';
import {Share} from './share';
import {EmailContent, EmailSendResponse} from '../email-form/email-content';
import Utils from '../utils/utils';
import {Permissions} from '../confluence.service';
import {ShareBulkUpdateRequest} from './bulk-edit';

export interface AppState {
    userIsAdmin?: boolean;
    permissions?: Permissions;
    isLite: boolean;
    lic: 'active' | 'none';
    hasCreatePermission?: boolean;
    hasEditPermission?: boolean;
    hasSendEmailPermission?: boolean;
    hasDeletePermission?: boolean;
}

@Injectable({providedIn: 'root'})
export class ShareService {

    public static getOptions(operationName: string) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Operation-Name': operationName,
            })
        };
    }

    constructor(private http: HttpClient) {
    }

    get(uuid: string): Observable<Share> {
        return this.http.get<Share>(`/api/share/${uuid}`, ShareService.getOptions('Fetch share details'));
    }

    create(contentId: string, name = '', title = ''): Observable<Share> {
        const url = '/api/share';
        const body = {contentId, name, title};
        const options = ShareService.getOptions('Create share');
        return this.http.post<Share>(url, body, options);
    }

    update(share: Share): Observable<ShareResult> {
        const url = '/api/share';
        const body = {
            // ID
            id: share.id,
            uuid: share.uuid,
            // Updatable fields
            name: share.name,
            description: share.description,
            password: share.password,
            expiration: share.expiration,
            namedShareLink: share.namedShareLink,
            linkName: share.linkName,
            editContent: share.editContent,
            showComments: share.showComments,
            allowAddComment: share.allowAddComment,
            showAttachments: share.showAttachments,
            allowAddAttachment: share.allowAddAttachment,
            allowShareChildPages: share.allowShareChildPages,
            restrictChildPagesWithLabels: share.restrictChildPagesWithLabels,
            restrictChildPagesWithoutLabels: share.restrictChildPagesWithoutLabels,
            showLabels: share.showLabels,
            allowRemoveUnsupportedMacros: share.allowRemoveUnsupportedMacros,
            showMacrosPlaceholder: share.showMacrosPlaceholder,
            showPageHistory: share.showPageHistory,
            allowPageHistoryDelete: share.allowPageHistoryDelete,
            allowPageHistoryRestore: share.allowPageHistoryRestore,
            showPageStatus: share.showPageStatus,
            showPageTitle: share.showPageTitle,
            showPageAuthor: share.showPageAuthor,
            showUpdateDate: share.showUpdateDate,
            subscribeButtonPlaceholder: share.subscribeButtonPlaceholder,
            showAuthorAvatar: share.showAuthorAvatar,
            selectedUsersConfig: share.selectedUsersConfig,
            samlLoginRequired: share.samlLoginRequired,
            unlockSecret: share.unlockSecret,
        };
        const options = ShareService.getOptions('Update share');
        return this.http.put<ShareResult>(url, body, options);
    }

    regenerateUnlockSecret(uuid: string): Observable<string> {
        const url = `/api/share/${uuid}/regenerateUnlockSecret`;
        const options = ShareService.getOptions('Regenerate Unlock Secret');
        return this.http.post<string>(url, null, options);
    }

    bulkUpdate(request: ShareBulkUpdateRequest): Observable<number> {
        const url = '/api/share-update';
        const options = ShareService.getOptions('Update share');
        return this.http.post<number>(url, request, options);
    }

    delete(shares: Share[]): Observable<DeleteStatus> {
        const url = '/api/share-delete/id';
        const body = shares.map(it => it.uuid);
        const options = ShareService.getOptions('Delete share');
        return this.http.post<DeleteStatus>(url, body, options);
    }

    deleteAllFromSpace(spaceId: number) {
        const url = '/api/share-delete/space?spaceId=' + spaceId;
        const options = ShareService.getOptions('Delete all shares from space');
        return this.http.post<number>(url, options);
    }

    deleteAll() {
        const url = '/api/share-delete/all';
        const options = ShareService.getOptions('Delete all shares');
        return this.http.post<number>(url, options);
    }

    sendMail(email: EmailContent): Observable<EmailSendResponse> {
        const url = `/api/mail`;
        const body = email;
        const options = ShareService.getOptions('Send Mail');
        return this.http.post<any>(url, body, options);
    }

    getMail(uuid: string,
            title: string,
            sender: string) {
        let url = `/api/mail?uuid=${uuid}`;
        if (!Utils.isEmpty(title)) {
            url += `&title=${encodeURIComponent(title)}`;
        }
        if (!Utils.isEmpty(sender)) {
            url += `&sender=${encodeURIComponent(sender)}`;
        }
        const options = ShareService.getOptions('Get mail data');
        return this.http.get<any>(url, options);
    }
}
