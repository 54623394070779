export class Constants {
    static readonly DATE_FMT = 'd MMMM yyyy';
    static readonly SHORT_DATE_FMT = 'd MMM yyyy';
    static readonly TIME_FMT = 'HH:mm';
    static readonly INPUT_DATE_FMT = 'yyyy-MM-dd';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT}, ${Constants.TIME_FMT}`;
    static readonly SHORT_NICE_DATE_TIME_FMT = `${Constants.SHORT_DATE_FMT}, ${Constants.TIME_FMT}`;
    static readonly ISO_DATE_TIME_FORMAT = `yyyy-MM-ddTHH:mm:ss.SSS`;
    static readonly COMMA = ',';
    static readonly SHARE_STATUSES = [
        {text: 'All', value: 'all'},
        {text: 'Active', value: 'active'},
        {text: 'Expired', value: 'expired'}
    ];
    static readonly ACTIVITY_SOURCES = [
        {text: 'All', value: ''},
        {text: 'Page', value: 'Page'},
        {text: 'Comment', value: 'Comment'},
        {text: 'Attachment', value: 'Attachment'}
    ];
    static readonly ACTIVITY_ACTIONS = [
        {text: 'All', value: ''},
        {text: 'Added', value: 'Added'},
        {text: 'Edited', value: 'Edited'},
        {text: 'Viewed', value: 'Viewed'},
        {text: 'Updated', value: 'Updated'}
    ];
    static readonly AVAILABLE_FONTS = [
        'Arial', 'Arial Black', 'Helvetica', 'Verdana', 'Tahoma', 'Trebuchet MS',
        'Impact', 'Gill Sans', 'Times New Roman', 'BlinkMacSystemFont', 'Georgia',
        'Baskerville', 'Palatino', 'Segoe UI', '-apple-system', 'Oxygen', 'Fira Sans',
        'Droid Sans', 'Roboto', 'Noto Sans', 'Ubuntu', 'Helvetica Neue', 'Beletrio',
        'Eesti', 'Source Sans Pro', 'Gotham Book', 'Inter', 'Lato', 'Courier',
        'Andalé Mono', 'Lucida', 'Monaco', 'Bradley Hand', 'Brush Script MT',
        'Luminari', 'Comic Sans MS'
    ];
    static readonly GENERIC_FONT_FAMILY = [
        {text: 'None', value: 'none'},
        {text: 'serif', value: 'serif'},
        {text: 'sans-serif', value: 'sans-serif'},
        {text: 'monospace', value: 'monospace'},
        {text: 'cursive', value: 'cursive'},
        {text: 'fantasy', value: 'fantasy'}
    ];
    static readonly MAIN_FRAME_BORDER_STYLES = [
        {text: 'None', value: 'none'},
        {text: 'Hidden', value: 'hidden'},
        {text: 'Dotted', value: 'dotted'},
        {text: 'Dashed', value: 'dashed'},
        {text: 'Solid', value: 'solid'},
        {text: 'Double', value: 'double'},
        {text: 'Groove', value: 'groove'},
        {text: 'Ridge', value: 'ridge'},
        {text: 'Inset', value: 'inset'},
        {text: 'Outset', value: 'outset'}
    ];
}
