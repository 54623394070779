import {Injectable} from '@angular/core';
import {ConfluenceService, Permissions} from './confluence.service';
import {Share, ShareFilterCriteria} from './base/share';
import {ShareService} from './base/share.service';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
import {LoaderService} from './loader.service';
import {of} from 'rxjs';
import {AppService} from './app.service';
import {ShareBulkUpdateFilterType, ShareBulkUpdateRequest} from './base/bulk-edit';

@Injectable({
    providedIn: 'root'
})
export class ShareActionService {
    constructor(
        private confluence: ConfluenceService,
        private shareService: ShareService,
        private loader: LoaderService,
        private app: AppService) {
    }

    update(share: Share) {
        return new Promise((resolve) => {
            this.loader.fullscreen(true);

            this.shareService.update(share)
                .pipe(finalize(() => this.loader.fullscreen(false)))
                .subscribe(() => {
                    this.confluence.emitEvent('link-update', JSON.stringify(share));
                    resolve();
                });
        });
    }

    edit(share: Share, permissions: Permissions, readOnly = false) {
        return this.editShare({
            uuid: share.uuid,
            contentId: share.contentId,
            readOnly,
            permissions
        });
    }

    view(share: Share, permissions: Permissions) {
        return this.edit(share, permissions, true);
    }

    editShare(customData) {
        return new Promise((resolve) => {
            this.confluence.showDialog({
                    key: 'share-edition-popup',
                    customData
                }, result => {
                    if (result.executeCallback !== false) {
                        this.confluence.emitEvent('link-update');
                    }
                    resolve(result);
                }
            );
        });
    }

    bulkEdit(shares: Share[], criteria?: ShareFilterCriteria, permissions?: Permissions) {
        let customData = {
            filter: {
                type: ShareBulkUpdateFilterType.SELECTED,
                uuids: shares.map(s => s.uuid),
                criteria
            }
        } as ShareBulkUpdateRequest & { permissions: Permissions };
        if (permissions) {
            customData = Object.assign(customData, {permissions});
        }
        return this.bulkEditShare(customData);
    }

    bulkEditShare(customData: ShareBulkUpdateRequest & { permissions: Permissions }) {
        return new Promise((resolve, reject) => {
            this.confluence.showDialog({
                    key: 'bulk-edition-popup',
                    customData
                }, () => {
                    resolve();
                }
            );
        });
    }

    deleteSingle(share: Share) {
        return new Promise((resolve, reject) => {
            this.confluence.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete this External Share link?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);

                        this.shareService.delete([share])
                            .pipe(
                                finalize(() => this.loader.fullscreen(false))
                            )
                            .subscribe((status) => {
                                    if (status.deleted > 0) {
                                        this.confluence.emitEvent('link-update');
                                        resolve(result);
                                    } else {
                                        AP.flag.create({
                                            title: `External Share | Access denied`,
                                            body: `${status.reason}`,
                                            type: 'error',
                                            close: 'auto'
                                        });
                                        resolve(result);
                                    }
                                },
                                error => {
                                    reject(result);
                                }
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    deleteMultiple(shares: Share[]) {
        return new Promise((resolve, reject) => {
            this.confluence.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete selected External Share links?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);

                        this.shareService.delete(shares)
                            .pipe(
                                finalize(() => this.loader.fullscreen(false))
                            )
                            .subscribe((status) => {
                                    if (status.deleted > 0) {
                                        this.confluence.emitEvent('link-update');
                                        resolve(result);
                                    } else {
                                        AP.flag.create({
                                            title: `External Share | Access denied`,
                                            body: `${status.reason}`,
                                            type: 'error',
                                            close: 'auto'
                                        });
                                        resolve(result);
                                    }
                                },
                                error => {
                                    reject(result);
                                }
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    deleteAllSharesFromSpace(spaceId: number) {
        return new Promise((resolve, reject) => {
            this.confluence.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete all External Share links?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);

                        this.shareService.deleteAllFromSpace(spaceId)
                            .pipe(
                                finalize(() => this.loader.fullscreen(false))
                            )
                            .subscribe(
                                (count) => {
                                    this.confluence.emitEvent('link-update');

                                    AP.flag.create({
                                        title: `External Share | Deleted ${count} shares`,
                                        body: this.operationSuccessfulMessage(count),
                                        type: 'success',
                                        close: 'auto'
                                    });
                                    resolve(result);
                                },
                                error => reject(error)
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    deleteAll() {
        return new Promise((resolve, reject) => {
            this.confluence.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete all External Share links?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);

                        this.shareService.deleteAll()
                            .pipe(
                                finalize(() => this.loader.fullscreen(false))
                            )
                            .subscribe((count) => {
                                    this.confluence.emitEvent('link-update');

                                    AP.flag.create({
                                        title: `External Share | Deleted ${count} shares`,
                                        body: this.operationSuccessfulMessage(count),
                                        type: 'success',
                                        actions: {
                                            actionkey: 'Close'
                                        },
                                        close: 'auto'
                                    });
                                    resolve(result);
                                },
                                error => {
                                    reject(result);
                                }
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    confirmRestore(restoreDefaultPageSettings) {
        this.confluence.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'restore',
                    title: 'Restore all default values?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);
                    restoreDefaultPageSettings();
                    this.loader.fullscreen(false);
                } else {
                    this.loader.fullscreen(false);
                }
            }
        );
    }

    confirmDelete(type, deleteAction) {
        const title = type === 'group' ? 'Delete group?' : type === 'section' ? 'Delete section?' : '';

        if (!title) {
            return;
        }

        this.confluence.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'delete',
                    title,
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);
                    deleteAction();
                    this.loader.fullscreen(false);
                } else {
                    this.loader.fullscreen(false);
                }
            }
        );
    }

    operationSuccessfulMessage(count) {
        return `Operation was successful. ${count} share links were removed.`;
    }

    migrateLite() {
        const migration = this.app.migrate().pipe(
            tap(it => {
                if (it.success) {
                    AP.flag.create({
                        title: `External Share | Migration Completed`,
                        body: `Operation was successful. ` + it.message + '.',
                        type: 'success',
                        close: 'auto'
                    });
                } else {
                    AP.flag.create({
                        title: `External Share | Migration Failed`,
                        body: it.message,
                        type: 'error',
                        close: 'auto'
                    });
                }
            }),
            catchError(error => {
                atlas.log('Error', error);
                AP.flag.create({
                    title: `External Share | Migration Failed`,
                    body: (error || 'Unknown error').toString(),
                    type: 'error',
                    close: 'auto'
                });
                throw error;
            }),
            finalize(() => this.loader.fullscreen(false))
        );

        const loader = of(undefined).pipe(tap(() => this.loader.fullscreen(true)));
        return loader.pipe(switchMap(() => migration));
    }
}
