import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-link-form',
    templateUrl: './link-form.component.html',
    styleUrls: ['./link-form.component.css']
})
export class LinkFormComponent implements OnInit {
    form: FormGroup;
    @Input() initialLinkName: string | null = null;
    @Input() initialLinkUrl: string | null = null;
    @Input() parentNode: any;
    @Input() existingLinks: string[] = [];
    @Input() currentLinkName: string | null = null;
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            newLinkName: ['', [Validators.required, Validators.maxLength(80),
                Validators.pattern(/^[\p{L}\p{N}.,!? \-]*$/u), this.duplicateLinkValidator.bind(this)]],
            newLinkUrl: ['', [Validators.required, Validators.pattern(/^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/)]]
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            newLinkName: this.initialLinkName || '',
            newLinkUrl: this.initialLinkUrl || ''
        });
    }

    isInvalid(controlName: string): boolean {
        const control = this.form.get(controlName);
        return control && control.invalid && (control.dirty || control.touched);
    }

    saveLink() {
        if (this.form.valid) {
            this.save.emit(this.form.value);
            this.form.reset();
        }
    }

    cancelLink() {
        this.cancel.emit();
        this.form.reset();
    }

    duplicateLinkValidator(control) {
        const value = control.value ? control.value.trim() : '';

        if (this.currentLinkName && value === this.currentLinkName.trim()) {
            return null;
        }

        if (this.parentNode && this.parentNode.children) {
            const duplicate = this.parentNode.children.some(child => child.name === value && child.type === 'link');
            if (duplicate) {
                return {duplicateLink: true};
            }
        }

        if (!this.parentNode && this.existingLinks.includes(value)) {
            return {duplicateLink: true};
        }

        return null;
    }
}
