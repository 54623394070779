import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, switchMap} from 'rxjs/operators';
import {LoaderService} from '../../loader.service';
import {ServerDataSource} from '../../components/app-table.component';
import {ApiKeyConfigView, ApiKeyConfigViewFilterCriteria} from '../../base/apikey.config';
import {merge, Observable, of, Subscription} from 'rxjs';
import AppUtils from '../../utils/app-utils';
import {HttpHeaders} from '@angular/common/http';
import {AppState} from '../../base/share.service';
import {ActivatedRoute} from '@angular/router';
import {AppService} from '../../app.service';
import {ConfluenceService} from '../../confluence.service';
import Utils from '../../utils/utils';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-global-view-api-keys',
    templateUrl: './global-view-api-keys.component.html',
    styleUrls: ['./global-view-api-keys.component.css']
})
export class GlobalViewApiKeysComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private refreshEventName = 'config-update';

    filterForm = new FormGroup({
        filterByUser: new FormControl(false)
    });

    config$: Observable<AppState>;

    constructor(
        private route: ActivatedRoute,
        private confluence: ConfluenceService,
        private loader: LoaderService,
        private source: ServerDataSource<ApiKeyConfigView, ApiKeyConfigViewFilterCriteria>,
        private app: AppService) {
    }

    users = new Map();
    elements;

    ngOnInit(): void {
        const refresh = this.confluence.observeEvent(this.refreshEventName);
        refresh.subscribe(() => {
            this.source.refresh();
        });
        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(() => Utils.combineParams(this.route.parent)),
            switchMap((params) => AppUtils.getAppState(params))
        );

        this.source.reload({
            endPoint: `/api-key/config`,
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching schemas',
            })
        });
        this.subscription = this.source.get().subscribe(apiKeyConfigViews => {
            this.elements = apiKeyConfigViews;
            this.loadUsers(apiKeyConfigViews);
        });

        this.filterForm.valueChanges.subscribe(it => {
            this.source.updateFilter({
                filterByUser: it.filterByUser
            });
        });
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private loadUsers(list: ApiKeyConfigView[]) {
        this.confluence.fetchUsersMap(
            new Set(list.map(el => el.config.createdById).concat(list.map(el => el.config.updatedById))),
        ).subscribe(usersMap => {
            this.users = usersMap;
        });
    }

    createNew() {
        this.confluence.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    isNew: true,
                    editable: true
                }
            }
        );
    }

    edit(el: ApiKeyConfigView) {
        this.confluence.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    id: el.id,
                    isNew: false,
                    editable: true
                }
            }
        );
    }

    view(el: ApiKeyConfigView) {
        this.confluence.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    id: el.id,
                    isNew: false,
                    editable: false
                }
            }
        );
    }

    confirmDeletion(el: ApiKeyConfigView) {
        this.confluence.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'archive',
                    title: 'Archive selected API Key?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);
                    this.app.deleteApiKeyConfig(el.id)
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.confluence.emitEvent(this.refreshEventName);
                        });
                }
            }
        );
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }
}
