import {environment} from '../../environments/environment';

export class DateUtil {

    static addHour(date: Date, hours: number) {
        const result = new Date(date.getTime());
        result.setHours(result.getHours() + hours);
        return result;
    }

    static addDay(date: Date, days: number) {
        const result = new Date(date.getTime());
        result.setDate(result.getDate() + days);
        return result;
    }

    static addWeek(date: Date, weeks: number) {
        return DateUtil.addDay(date, weeks * 7);
    }

    static addMonth(date: Date, months: number) {
        const result = new Date(date.getTime());
        result.setMonth(result.getMonth() + months);
        return result;
    }

    static addYear(date: Date, year: number) {
        const result = new Date(date.getTime());
        result.setFullYear(result.getFullYear() + year);
        return result;
    }

    static convert(val: any) {
        return environment.server ? val.getTime() : val;
    }
}
