// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
import {ApplicationRef, NgModuleRef} from '@angular/core';
import {createNewHosts} from '@angularclass/hmr';
import {envInit} from './env-init';

export const environment = {
    pluginSettingsUrl: '',
    host: '',
    contentUrl: 'http://localhost:38080',
    production: false,
    hmr: (bootstrap) => hmrBootstrap(module, bootstrap),
    server: false,
    init: async () => {
        await envInit(environment);
    }
};

function hmrBootstrap(module: any, bootstrap: () => Promise<NgModuleRef<any>>) {
    let ngModule: NgModuleRef<any>;
    module.hot.accept();
    bootstrap().then(mod => ngModule = mod);
    module.hot.dispose(() => {
        const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
        const elements = appRef.components.map(c => c.location.nativeElement);
        const makeVisible = createNewHosts(elements);
        ngModule.destroy();
        makeVisible();
    });
}

