import {
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationExtras,
    Params,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ConfluenceService, Space} from './confluence.service';
import {ShareService} from './base/share.service';
import {Share} from './base/share';
import Utils from './utils/utils';

interface ShareCustomData {
    uuid?: string;
    contentId?: string;
    spaceId?: string;
    action?: string;
    jwtToken?: string;
    source?: string;
}

interface UrlRedirect {
    commands: any[];
    navigationExtras?: NavigationExtras;
}

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

    constructor(private confluence: ConfluenceService,
                private shareService: ShareService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const location = next.params.location;
        let view: Observable<UrlRedirect> = of({commands: []});
        switch (location) {
            case 'global-view':
                view = this.globalView(next);
                break;
            case 'space-view':
                view = this.spaceView(next);
                break;
            case 'share-list':
                view = this.shareList(next);
                break;
            case 'share-view':
                view = this.shareView(next);
                break;
            case 'send-via-mail':
                view = this.sendViaMailView(next);
                break;
            case 'confirm-delete':
                view = this.confirmDelete(next);
                break;
            case 'bulk-edit':
                view = this.bulkEdit(next);
                break;
            case 'schema':
                view = this.schemaView(next);
                break;
            case 'api-key':
                view = of({commands: ['api-key']});
                break;
        }

        return view.pipe(
            map(it => {
                if (!it.navigationExtras) {
                    it.navigationExtras = {};
                }
                if (!it.navigationExtras.queryParams) {
                    it.navigationExtras.queryParams = {};
                }
                const currentUrlHasAppName = next.queryParamMap.has('n');
                const finalUrlDoesNotHaveAppName = !it.navigationExtras.queryParams.n;
                if (currentUrlHasAppName && finalUrlDoesNotHaveAppName) {
                    it.navigationExtras.queryParams.n = next.queryParamMap.get('n');
                }
                it.navigationExtras.queryParams.lic = next.queryParamMap.get('lic');
                return this.createUrlTree(it.commands, it.navigationExtras);
            }),
            catchError((err) => {
                atlas.log('Error while loading view', err);
                return of(this.createUrlTree(['error']));
            })
        );
    }

    private globalView(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        return this.confluence.getLocation().pipe(
            switchMap(location => {
                const userIsAdmin = next.queryParamMap.get('user_is_admin') || false;
                const navigationExtras = {
                    queryParams: {
                        user_is_admin: userIsAdmin,
                        appsView: next.queryParamMap.get('appsView') || false
                    }
                };

                if (isFalse(userIsAdmin)) {
                    return of({commands: ['browse/global/user-list'], navigationExtras});
                } else if (location.searchParams.has('settingsView')) {
                    return of({commands: ['browse/global/settings'], navigationExtras});
                }
                return of({commands: ['browse/global'], navigationExtras});
            })
        );

        function isFalse(value) {
            return value === 'false' || !value;
        }
    }

    private spaceView(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        return this.spaceData(next).pipe(
            map(space => {
                return {
                    commands: ['browse', 'space', space.key, space.id],
                    navigationExtras: {
                        queryParams: {
                            user_is_space_admin: next.queryParamMap.get('user_is_space_admin')
                                || next.queryParamMap.get('user_is_admin') || false,
                            user_is_admin: next.queryParamMap.get('user_is_admin') || false,
                            appsView: next.queryParamMap.get('appsView') || false,
                            permissionsEnabled: next.queryParamMap.get('permissionsEnabled') || false,
                            hasCreatePermission: next.queryParamMap.get('hasCreatePermission') || false,
                            hasEditPermission: next.queryParamMap.get('hasEditPermission') || false,
                            hasDeletePermission: next.queryParamMap.get('hasDeletePermission') || false,
                            hasSendEmailPermission: next.queryParamMap.get('hasSendEmailPermission') || false,
                        }
                    }
                };
            })
        );
    }

    private spaceData(next: ActivatedRouteSnapshot): Observable<Space> {
        if (next.queryParams.spaceKey && next.queryParams.spaceId) {
            return of({
                id: next.queryParams.spaceId,
                key: next.queryParams.spaceKey
            });
        }

        return this.confluence.getContext().pipe(
            map(it => {
                return it.confluence.space;
            })
        );
    }

    private shareList(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        console.log('View own shares', next.queryParamMap.get('hasViewOwnSharesOnPagePermission'));
        return of({
            commands: ['browse', 'list', 'share', next.queryParams.spaceKey, next.queryParams.contentId],
            navigationExtras: {
                queryParams: {
                    user_is_admin: next.queryParamMap.get('user_is_admin') || false,
                    permissionsEnabled: next.queryParamMap.get('permissionsEnabled') || false,
                    hasViewSharesOnPagePermission: next.queryParamMap.get('hasViewSharesOnPagePermission') || false,
                    hasCreatePermission: next.queryParamMap.get('hasCreatePermission') || false,
                    hasEditPermission: next.queryParamMap.get('hasEditPermission') || false,
                    hasDeletePermission: next.queryParamMap.get('hasDeletePermission') || false,
                    hasSendEmailPermission: next.queryParamMap.get('hasSendEmailPermission') || false,
                    hasViewOwnSharesOnPagePermission: next.queryParamMap.get('hasViewOwnPermission') || false,
                }
            }
        });
    }

    private isAdminVal(params: Params): boolean {
        return Utils.isTrue(params.user_is_admin)
            || Utils.isTrue(params.user_is_space_admin);
    }

    private shareView(next: ActivatedRouteSnapshot) {
        return this.shareContext(next).pipe(
            switchMap(({uuid, contentId, spaceId, source}) => {
                const params = next.queryParams;
                const usePermissionScheme = Utils.isTrue(params.permissionsEnabled) && !this.isAdminVal(params);

                const permissions = {
                    enabled: usePermissionScheme ? Utils.isTrue(params.permissionsEnabled) : true,
                    hasViewSharesOnPagePermission: usePermissionScheme ? Utils.isTrue(params.hasViewSharesOnPagePermission) : true,
                    hasCreatePermission: usePermissionScheme ? Utils.isTrue(params.hasCreatePermission) : true,
                    hasEditPermission: usePermissionScheme ? Utils.isTrue(params.hasEditPermission) : true,
                    hasSendEmailPermission: usePermissionScheme ? Utils.isTrue(params.hasSendEmailPermission) : true,
                    hasDeletePermission: usePermissionScheme ? Utils.isTrue(params.hasDeletePermission) : true,
                    hasViewOwnSharesOnPagePermission: usePermissionScheme ? Utils.isTrue(params.hasViewOwnSharesOnPagePermission) : true
                };

                if (uuid) {
                    return of({uuid, contentId, spaceId, isNew: false, reason: null, permissions});
                }

                if (source === 'secondary') {
                    if (!permissions.enabled || permissions.hasCreatePermission) {
                        return this.createShare(contentId)
                            .pipe(map(share => ({
                                uuid: share.uuid,
                                contentId,
                                spaceId,
                                isNew: true,
                                reason: null,
                                permissions
                            })));
                    } else {
                        const reason = 'You do not have permission to create new External Share';
                        return of({uuid, contentId, spaceId, isNew: true, reason, permissions});
                    }
                }

                return this.createShare(contentId)
                    .pipe(map(it => ({
                        uuid: it.uuid,
                        contentId,
                        spaceId,
                        isNew: true,
                        reason: null,
                        permissions
                    })));
            }),
            map(({uuid, contentId, spaceId, isNew, reason, permissions}) => {
                if (!!reason) {
                    return {
                        commands: ['no-access-content-view'],
                        navigationExtras: {
                            queryParams: {
                                reason
                            }
                        }
                    };
                } else {
                    return {
                        commands: ['browse', 'share', uuid, contentId],
                        navigationExtras: {
                            queryParams: {
                                frameId: next.queryParamMap.get('frameId') || '',
                                cesPluginKey: next.queryParamMap.get('cesPluginKey') || '',
                                contentId,
                                spaceId,
                                isNew,
                                permissionsEnabled: permissions.enabled,
                                hasViewSharesOnPagePermission: permissions.hasViewSharesOnPagePermission,
                                hasViewOwnSharesOnPagePermission: permissions.hasViewOwnSharesOnPagePermission,
                                hasCreatePermission: permissions.hasCreatePermission,
                                hasEditPermission: permissions.hasEditPermission,
                                hasSendEmailPermission: permissions.hasSendEmailPermission,
                                hasDeletePermission: permissions.hasDeletePermission,
                            }
                        }
                    };
                }
            })
        );
    }

    private sendViaMailView(next: ActivatedRouteSnapshot) {
        return this.shareContext(next).pipe(
            map(({uuid, contentId}) => {
                const permissionsEnabled = next.queryParams.permissionsEnabled === 'true';
                const hasSendEmailPermission = next.queryParams.hasSendEmailPermission === 'true';

                if (!permissionsEnabled || (permissionsEnabled && hasSendEmailPermission)) {
                    return {commands: ['send-via-mail', uuid, contentId]};
                } else {
                    const reason = 'You do not have permission to send External Share via email';
                    return {
                        commands: ['no-access-content-view'],
                        navigationExtras: {
                            queryParams: {
                                reason
                            }
                        }
                    };
                }
            })
        );
    }

    private confirmDelete(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        return of({commands: ['confirm-delete']});
    }

    private bulkEdit(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        return of({commands: ['bulk-edit']});
    }

    private schemaView(next: ActivatedRouteSnapshot): Observable<UrlRedirect> {
        return of({commands: ['schema']});
    }

    private shareContext(next: ActivatedRouteSnapshot): Observable<ShareCustomData> {
        const contentId = next.queryParams.contentId;
        const spaceId = next.queryParams.spaceId;
        const uuid = next.queryParams.uuid;
        const source = next.queryParams.source;

        if (contentId && spaceId) {
            return of({
                contentId, spaceId, uuid, source
            });
        }
        return this.confluence.getCustomData()
            .pipe(
                map(it => (it || {}))
            );
    }

    private createUrlTree(commands: any[], navigationExtras?: NavigationExtras): UrlTree {
        return this.router.createUrlTree(commands, navigationExtras);
    }

    private createShare(contentId: string): Observable<Share> {
        return this.shareService.create(contentId).pipe(
            catchError((err) => {
                this.confluence.closeDialog();
                throw err;
            }),
            tap(it => {
                this.confluence.emitEvent('link-update', JSON.stringify(it));
            }),
        );
    }
}
