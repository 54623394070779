import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-domains-input-errors',
    templateUrl: './domains-input-errors.component.html',
    styleUrls: ['./domains-input-errors.component.css'],
})
export class DomainsInputErrorsComponent {

    @Input() control: AbstractControl;
    @Input() allowedDomains: Array<string>;

    constructor() {
    }

    get displayErrors(): boolean {
        return this.control.invalid && this.control.touched;
    }

    get allowedDomainsErrorMessage() {
        let result = 'Only ';
        result += this.allowedDomains.join(', ');
        result += this.allowedDomains.length > 1 ? ' domains are allowed.' : ' domain is allowed.';
        return result;
    }
}
