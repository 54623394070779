import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import Utils from '../../../../utils/utils';
import {ImageUploadComponent} from '../../../../components/image-upload/image-upload.component';
import {PageCustomizationComponent} from '../page-customization.component';
import {TreeItemNode} from '../../../../components/link-tree/link-tree.component';

@Component({
    selector: 'app-page-customization-header',
    templateUrl: './page-customization-header.component.html',
    styleUrls: ['./page-customization-header.component.css']
})
export class PageCustomizationHeaderComponent implements OnInit {
    @Input() form: FormGroup;

    @ViewChildren(ImageUploadComponent) imageUploadComponents: QueryList<ImageUploadComponent>;

    sides = ['Top', 'Right', 'Bottom', 'Left'];

    selectOptions = [
        {text: 'Pixels', value: 'pixels'},
        {text: 'Auto', value: 'auto'}
    ];

    heightType: string;
    widthType: string;

    headerForm = new FormGroup({
        marginTop: new FormControl(''),
        marginRight: new FormControl(''),
        marginBottom: new FormControl(''),
        marginLeft: new FormControl(''),
        marginTypeTop: new FormControl('pixels'),
        marginTypeRight: new FormControl('pixels'),
        marginTypeBottom: new FormControl('pixels'),
        marginTypeLeft: new FormControl('pixels'),
        paddingTop: new FormControl(''),
        paddingRight: new FormControl(''),
        paddingBottom: new FormControl(''),
        paddingLeft: new FormControl(''),
        paddingTypeTop: new FormControl('pixels'),
        paddingTypeRight: new FormControl('pixels'),
        paddingTypeBottom: new FormControl('pixels'),
        paddingTypeLeft: new FormControl('pixels'),
        heightType: new FormControl('pixels'),
        widthType: new FormControl('pixels')
    });

    ngOnInit(): void {
        const logoHeightControl = this.form.get('pageHeader.logoHeight');
        const logoWidthControl = this.form.get('pageHeader.logoWidth');
        const heightTypeControl = this.headerForm.get('heightType');
        const widthTypeControl = this.headerForm.get('widthType');

        if (logoHeightControl && logoWidthControl) {
            this.initializeTypeControl(logoHeightControl, heightTypeControl);
            this.initializeTypeControl(logoWidthControl, widthTypeControl);

            this.updateInputState(heightTypeControl.value, logoHeightControl);
            this.updateInputState(widthTypeControl.value, logoWidthControl);

            this.heightType = heightTypeControl.value;
            this.widthType = widthTypeControl.value;

            heightTypeControl.valueChanges.subscribe(value => {
                this.updateInputState(value, logoHeightControl);
                this.heightType = value;
            });

            widthTypeControl.valueChanges.subscribe(value => {
                this.updateInputState(value, logoWidthControl);
                this.widthType = value;
            });

        }
        this.initializePaddingValues();
        this.initializeMarginValues();

        this.sides.forEach(side => {
            this.headerForm.get('marginType' + side).valueChanges.subscribe(() => this.onMarginTypeChange(side));
            this.headerForm.get('margin' + side).valueChanges.subscribe(() => this.onMarginChange(side));
        });

        this.sides.forEach(side => {
            this.headerForm.get('paddingType' + side).valueChanges.subscribe(() => this.onPaddingTypeChange(side));
            this.headerForm.get('padding' + side).valueChanges.subscribe(() => this.onPaddingChange(side));
        });
    }

    onTreeDataChange(treeData: TreeItemNode[]) {
        this.form.get('pageHeader.customHeaderLinks').setValue(treeData);
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    removeLogo() {
        this.form.patchValue({logo: null});
        this.resetImageUploadComponentInput('logo');
    }

    private resetImageUploadComponentInput(formControlName: string) {
        const imageUploadComponent = this.imageUploadComponents.find(
            (uploadComponent: ImageUploadComponent) => uploadComponent.formControlName === formControlName
        );
        imageUploadComponent?.resetInput();
    }

    removeLogoDarkMode() {
        this.form.patchValue({logoDark: null});
        this.resetImageUploadComponentInput('logoDark');
    }

    private initializeTypeControl(control: AbstractControl, typeControl: AbstractControl): void {
        const value = control.value;
        if (value === 'auto') {
            typeControl.setValue('auto');
            control.setValue('auto');
        } else if (!isNaN(value)) {
            typeControl.setValue('pixels');
        }
    }

    private updateInputState(value: string, control: AbstractControl): void {
        if (value === 'auto') {
            control.disable();
            control.setValue('auto');
        } else {
            control.enable();
            if (!isNaN(control.value) && control.value !== 'auto') {
                control.setValue(control.value);
            } else {
                control.setValue('');
            }
        }
    }

    onHeightTypeChange(value): void {
        this.headerForm.get('heightType').setValue(value);
        const logoHeightControl = this.form.get('pageHeader.logoHeight');
        if (logoHeightControl) {
            this.updateInputState(value, logoHeightControl);
        }
        this.heightType = value;
    }

    onWidthTypeChange(value): void {
        this.headerForm.get('widthType').setValue(value);
        const logoWidthControl = this.form.get('pageHeader.logoWidth');
        if (logoWidthControl) {
            this.updateInputState(value, logoWidthControl);
        }
        this.widthType = value;
    }

    initializePaddingValues() {
        PageCustomizationComponent.initializeCssValues(
            this.sides,
            'padding',
            this.form.get('pageHeader.headerLinkStyles.padding'),
            this.headerForm);
    }

    updateHeaderPadding() {
        const paddingValues = PageCustomizationComponent.computeCssValues(
            this.sides,
            this.headerForm,
            'padding'
        );
        PageCustomizationComponent.applyCssShorthandRules(paddingValues);

        this.form.get('pageHeader.headerLinkStyles.padding').setValue(paddingValues.join(' '));
    }

    initializeMarginValues() {
        PageCustomizationComponent.initializeCssValues(
            this.sides,
            'margin',
            this.form.get('pageHeader.headerLinkStyles.margin'),
            this.headerForm);
    }

    updateHeaderMargin() {
        const marginValues = PageCustomizationComponent.computeCssValues(
            this.sides,
            this.headerForm,
            'margin'
        );
        PageCustomizationComponent.applyCssShorthandRules(marginValues);

        this.form.get('pageHeader.headerLinkStyles.margin').setValue(marginValues.join(' '));
    }

    onPaddingTypeChange(side: string) {
        const paddingTypeControl = this.headerForm.get('paddingType' + side);
        const paddingControl = this.headerForm.get('padding' + side);
        const value = paddingTypeControl.value;
        if (value === 'auto') {
            paddingControl.setValue('auto', {emitEvent: false});
        } else {
            paddingControl.setValue('', {emitEvent: false});
        }

        this.updateHeaderPadding();
    }

    onPaddingChange(side: string) {
        const paddingTypeControl = this.headerForm.get('paddingType' + side);

        if (paddingTypeControl.value === 'auto') {
            paddingTypeControl.setValue('pixels', {emitEvent: false});
        }

        this.updateHeaderPadding();
    }

    onMarginTypeChange(side: string) {
        const marginTypeControl = this.headerForm.get('marginType' + side);
        const marginControl = this.headerForm.get('margin' + side);
        const value = marginTypeControl.value;
        if (value === 'auto') {
            marginControl.setValue('auto', {emitEvent: false});
        } else {
            marginControl.setValue('', {emitEvent: false});
        }

        this.updateHeaderMargin();
    }

    onMarginChange(side: string) {
        const marginTypeControl = this.headerForm.get('marginType' + side);

        if (marginTypeControl.value === 'auto') {
            marginTypeControl.setValue('pixels', {emitEvent: false});
        }

        this.updateHeaderMargin();
    }
}
