import {FlatTreeControl} from '@angular/cdk/tree';
import {
    Component,
    ElementRef,
    Injectable,
    ViewChild,
    Output,
    EventEmitter,
    OnInit,
    Input
} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs';
import {ShareActionService} from '../../share-action.service';

export class TreeItemNode {
    children?: TreeItemNode[];
    name: string;
    type: string;
    url?: string;
}

export class TreeItemFlatNode {
    name: string;
    type: string;
    level: number;
    expandable: boolean;
}

@Injectable()
export class TreeDatabase {
    dataChange = new BehaviorSubject<TreeItemNode[]>([]);

    get data(): TreeItemNode[] {
        return this.dataChange.value;
    }

    initialize(data: TreeItemNode[]) {
        this.dataChange.next(data);
    }

    insertItemAbove(node: TreeItemNode, moved: TreeItemNode): TreeItemNode {
        const parentNode = this.getParentFromNodes(node);

        if (moved.type === 'group' && parentNode != null) {
            return undefined;
        } else if (moved.type === 'section' && (parentNode == null || parentNode.type !== 'group')) {
            return undefined;
        }

        const newItem = {
            name: moved.name,
            type: moved.type,
            children: moved.children,
            url: moved.url
        } as TreeItemNode;

        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node), 0, newItem);
        } else if (moved.type === 'section') {
            return undefined;
        } else {
            this.data.splice(this.data.indexOf(node), 0, newItem);
        }
        this.dataChange.next(this.data);

        return newItem;
    }

    insertItemBelow(node: TreeItemNode, moved: TreeItemNode): TreeItemNode {
        const parentNode = this.getParentFromNodes(node);

        if (moved.type === 'group' && parentNode != null) {
            return undefined;
        } else if (moved.type === 'section' && (parentNode == null || parentNode.type !== 'group')) {
            return undefined;
        }

        const newItem = {
            name: moved.name,
            type: moved.type,
            children: moved.children,
            url: moved.url
        } as TreeItemNode;

        if (parentNode != null) {
            parentNode.children.splice(parentNode.children.indexOf(node) + 1, 0, newItem);
        } else if (moved.type === 'section') {
            return undefined;
        } else {
            this.data.splice(this.data.indexOf(node) + 1, 0, newItem);
        }
        this.dataChange.next(this.data);

        return newItem;
    }

    insertItem(parent: TreeItemNode | null, toAdd: TreeItemNode): TreeItemNode | undefined {
        if (parent === null) {
            this.data.push(toAdd);
        } else {
            if (parent.type === 'link' || (parent.type === 'section' && toAdd.type !== 'link')) {
                return undefined;
            }
            if (!parent.children) {
                parent.children = [];
            }
            if (parent.children.some(el => el.name === toAdd.name && el.type === toAdd.type)) {
                return undefined;
            }
            parent.children.push({
                name: toAdd.name,
                type: toAdd.type,
                children: toAdd.children,
                url: toAdd.url
            });
        }
        this.dataChange.next(this.data);

        return toAdd;
    }

    getParentFromNodes(node: TreeItemNode): TreeItemNode {
        for (const currentRoot of this.data) {
            const parent = this.getParent(currentRoot, node);
            if (parent != null) {
                return parent;
            }
        }
        return null;
    }

    getParent(currentRoot: TreeItemNode, node: TreeItemNode): TreeItemNode {
        if (currentRoot.children && currentRoot.children.length > 0) {
            for (const child of currentRoot.children) {
                if (child === node) {
                    return currentRoot;
                } else if (child.children && child.children.length > 0) {
                    const parent = this.getParent(child, node);
                    if (parent != null) {
                        return parent;
                    }
                }
            }
        }
        return null;
    }

    updateItem(node: TreeItemNode, name: string) {
        node.name = name;
        this.dataChange.next(this.data);
    }

    deleteItem(node: TreeItemNode) {
        this.deleteNode(this.data, node);
        this.dataChange.next(this.data);
    }

    getLinks(nodes: TreeItemNode[]): TreeItemNode[] {
        let links = [];
        nodes.forEach(node => {
            if (node.type === 'link') {
                links.push(node);
            }
            if (node.children) {
                links = links.concat(this.getLinks(node.children));
            }
        });
        return links;
    }

    copyPasteItem(from: TreeItemNode, to: TreeItemNode): TreeItemNode {
        return this.insertItem(to, from);
    }

    copyPasteItemAbove(from: TreeItemNode, to: TreeItemNode): TreeItemNode {
        return this.insertItemAbove(to, from);
    }

    copyPasteItemBelow(from: TreeItemNode, to: TreeItemNode): TreeItemNode {
        return this.insertItemBelow(to, from);
    }

    deleteNode(nodes: TreeItemNode[], nodeToDelete: TreeItemNode) {
        const index = nodes.indexOf(nodeToDelete, 0);
        if (index > -1) {
            nodes.splice(index, 1);
        } else {
            nodes.forEach(node => {
                if (node.children && node.children.length > 0) {
                    this.deleteNode(node.children, nodeToDelete);
                }
            });
        }
    }
}

@Component({
    selector: 'app-link-tree',
    templateUrl: './link-tree.component.html',
    styleUrls: ['./link-tree.component.css'],
    providers: [TreeDatabase]
})
export class LinkTreeComponent implements OnInit {
    flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();
    nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();
    treeControl: FlatTreeControl<TreeItemFlatNode>;
    treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;
    dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
    dragNode: any;
    dragNodeExpandOverWaitTimeMs = 300;
    dragNodeExpandOverNode: any;
    dragNodeExpandOverTime: number;
    dragNodeExpandOverArea: string;
    @ViewChild('emptyItem') emptyItem: ElementRef;
    editingNode: TreeItemFlatNode | null = null;
    @Output() treeDataChange = new EventEmitter<TreeItemNode[]>();
    isAddingLink = false;
    isAddingGroup = false;
    addingLinkNode: TreeItemFlatNode | null = null;
    @Input() customTreeLinks: TreeItemNode[] = [];
    isAddingSection = false;
    addingSectionNode: TreeItemFlatNode | null = null;
    @Input() showEmptyGroupCheckbox = false;

    constructor(private database: TreeDatabase, private shareActionService: ShareActionService) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit() {
        if (this.customTreeLinks) {
            this.database.initialize(this.customTreeLinks);
        }

        this.database.dataChange.subscribe(data => {
            this.dataSource.data = data;
            this.treeDataChange.emit(data);
        });
    }

    getLevel = (node: TreeItemFlatNode) => node.level;
    isExpandable = (node: TreeItemFlatNode) => node.expandable;
    getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;
    hasChild = (_: number, nodeData: TreeItemFlatNode) => nodeData.expandable;

    transformer = (node: TreeItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.name === node.name
            ? existingNode
            : new TreeItemFlatNode();
        flatNode.name = node.name;
        flatNode.type = node.type;
        flatNode.level = level;
        flatNode.expandable = (node.children && node.children.length > 0);
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    handleDragStart(event, node) {
        event.dataTransfer.setDragImage(this.emptyItem.nativeElement, 0, 0);
        this.dragNode = node;
        this.treeControl.collapse(node);
    }

    handleDragOver(event, node) {
        event.preventDefault();
        const canDrop = this.canDropItem(this.dragNode, node);

        if (node === this.dragNodeExpandOverNode) {
            if (this.dragNode !== node && !this.treeControl.isExpanded(node)) {
                if ((new Date().getTime() - this.dragNodeExpandOverTime) > this.dragNodeExpandOverWaitTimeMs) {
                    this.treeControl.expand(node);
                }
            }
        } else {
            this.dragNodeExpandOverNode = node;
            this.dragNodeExpandOverTime = new Date().getTime();
        }

        const percentageY = event.offsetY / event.target.clientHeight;
        if (percentageY < 0.25) {
            this.dragNodeExpandOverArea = 'above';
        } else if (percentageY > 0.75) {
            this.dragNodeExpandOverArea = 'below';
        } else {
            this.dragNodeExpandOverArea = 'center';
        }

        if (this.dragNodeExpandOverArea === 'center' && !canDrop) {
            event.dataTransfer.dropEffect = 'none';
        }
    }

    canDropItem(draggedNode: TreeItemFlatNode, targetNode: TreeItemFlatNode): boolean {
        if (draggedNode.type === 'link' && targetNode.type === 'link') {
            return false;
        }

        if (draggedNode.type === 'group') {
            if (targetNode.type === 'link' || targetNode.type === 'section' || targetNode.type === 'group') {
                return false;
            }
        }

        if (draggedNode.type === 'section') {
            if (targetNode.type === 'link' || targetNode.type === 'section') {
                return false;
            }
        }

        return true;
    }

    handleDrop(event, node) {
        event.preventDefault();
        if (node !== this.dragNode) {
            let newItem: TreeItemNode;
            if (this.dragNodeExpandOverArea === 'above') {
                newItem = this.database.copyPasteItemAbove(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            } else if (this.dragNodeExpandOverArea === 'below') {
                newItem = this.database.copyPasteItemBelow(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            } else {
                newItem = this.database.copyPasteItem(this.flatNodeMap.get(this.dragNode), this.flatNodeMap.get(node));
            }
            if (newItem) {
                this.database.deleteItem(this.flatNodeMap.get(this.dragNode));
                this.treeControl.expandDescendants(this.nestedNodeMap.get(newItem));
            }
        }
        this.dragNode = null;
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;
    }

    handleDragEnd() {
        this.dragNode = null;
        this.dragNodeExpandOverNode = null;
        this.dragNodeExpandOverTime = 0;
    }

    isNodeEditable(nodeType: string): boolean {
        return nodeType === 'group' || nodeType === 'section' || nodeType === 'link';
    }

    deleteNode(node: TreeItemFlatNode): void {
        const nestedNode: TreeItemNode = this.flatNodeMap.get(node);

        if (node.type === 'section' || node.type === 'group') {
            this.shareActionService.confirmDelete(node.type, () => {
                this.database.deleteItem(nestedNode);
            });
        } else if (node.type === 'link') {
            this.database.deleteItem(nestedNode);
        }
    }

    getTopLevelLinks(): string[] {
        return this.database.data
            .filter(node => node.type === 'link')
            .map(link => link.name);
    }

    getParentNode(node: TreeItemFlatNode): TreeItemNode | null {
        const nestedNode = this.flatNodeMap.get(node);
        if (nestedNode) {
            return this.database.getParentFromNodes(nestedNode);
        }
        return null;
    }

    showLinkForm() {
        this.isAddingLink = true;
        this.isAddingGroup = false;
    }

    showGroupForm() {
        this.isAddingGroup = true;
        this.isAddingLink = false;
    }

    startEditing(node: TreeItemFlatNode) {
        this.editingNode = node;
    }

    saveEditLink(formData: any) {
        if (this.editingNode && this.editingNode.type === 'link') {
            const nestedNode = this.flatNodeMap.get(this.editingNode);

            nestedNode.name = formData.newLinkName;
            nestedNode.url = formData.newLinkUrl;

            this.database.updateItem(nestedNode, nestedNode.name);
            this.editingNode = null;
        }
    }

    addNewGroup(groupData: any) {
        const newGroup: TreeItemNode = {
            name: groupData.newGroupName.trim(),
            type: 'group',
            children: []
        };

        this.database.insertItem(null, newGroup);
        this.isAddingGroup = false;
    }

    cancelGroup() {
        this.isAddingGroup = false;
    }

    saveEditGroup(formData: any) {
        if (this.editingNode && this.editingNode.type === 'group') {
            this.editingNode.name = formData.newGroupName;
            this.database.updateItem(this.flatNodeMap.get(this.editingNode), this.editingNode.name);
        }
        this.editingNode = null;
    }

    addLinkFromForm(linkData: any) {
        const newLink: TreeItemNode = {
            name: linkData.newLinkName.trim(),
            type: 'link',
            url: linkData.newLinkUrl.trim()
        };
        this.database.insertItem(null, newLink);
        this.isAddingLink = false;
    }

    cancelLink() {
        this.isAddingLink = false;
    }

    addNewSection(node: TreeItemFlatNode) {
        this.isAddingSection = true;
        this.addingSectionNode = node;
        this.treeControl.expand(node);
    }

    cancelEdit() {
        this.editingNode = null;
    }

    saveNewSection(sectionData: any) {
        const parentNode = this.flatNodeMap.get(this.addingSectionNode);
        this.database.insertItem(parentNode, {
            name: sectionData.newSectionName.trim(),
            type: 'section'
        });
        this.isAddingSection = false;
        this.addingSectionNode = null;
    }

    cancelNewSection() {
        this.isAddingSection = false;
        this.addingSectionNode = null;
    }

    saveEditSection(formData: any) {
        if (this.editingNode && this.editingNode.type === 'section') {
            this.editingNode.name = formData.newSectionName;
            this.database.updateItem(this.flatNodeMap.get(this.editingNode), this.editingNode.name);
        } else if (this.isAddingSection) {
            const newSection: TreeItemNode = {
                name: formData.newSectionName,
                type: 'section',
                children: []
            };
            this.database.insertItem(null, newSection);
        }

        this.editingNode = null;
        this.isAddingSection = false;
    }

    cancelEditSection() {
        this.editingNode = null;
        this.isAddingSection = false;
    }

    startAddingLink(node: TreeItemFlatNode) {
        this.addingLinkNode = node;
        this.treeControl.expand(node);
    }

    saveNewLink(formData: any): void {
        if (formData.newLinkName && formData.newLinkUrl) {
            const parentNode = this.flatNodeMap.get(this.addingLinkNode);
            this.database.insertItem(parentNode, {
                name: formData.newLinkName.trim(),
                type: 'link',
                url: formData.newLinkUrl.trim()
            });
            this.addingLinkNode = null;
        }
    }

    cancelNewLink() {
        this.addingLinkNode = null;
    }
}
