import {Component, OnInit} from '@angular/core';
import {ActivityParameters} from '../../components/activity/activity.component';
import {Observable, of} from 'rxjs';
import {Space} from '../../confluence.service';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-space-activity',
    templateUrl: './space-activity.component.html'
})
export class SpaceActivityComponent implements OnInit {

    $space: Observable<Space>;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.$space = this.getRoute().data.pipe(
            switchMap((data) => {
                const space = data.space as Space;
                return of(space);
            })
        );
    }

    getActivityParameters(spaceKey: string): ActivityParameters {
        return {
            level: 'SPACE',
            spaceKey,
            hasCreatePermission: true,
            hasEditPermission: true,
            hasSendEmailPermission: true,
            hasDeletePermission: true,
            skipFetchData: false
        };
    }

    getRoute() {
        return this.route.parent;
    }
}
