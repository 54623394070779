import Utils from '../utils/utils';

export enum PeriodEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface Period {
    unit: PeriodEnum;
    count: number;
}

export const PERIODS_FOR_SELECT = Object.keys(PeriodEnum).map(i => {
    return {text: capitalize(i), value: i};
});

export function addToDate(date: Date, increase: number, period: PeriodEnum): Date {
    const result = new Date(date.valueOf());

    if (typeof increase === 'string') {
        increase = parseInt(increase, 0);
    }

    switch (period) {
        case PeriodEnum.HOUR:
            result.setHours(date.getHours() + increase);
            break;
        case PeriodEnum.DAY:
            result.setDate(date.getDate() + increase);
            break;
        case PeriodEnum.WEEK:
            result.setDate(date.getDate() + (increase * 7));
            break;
        case PeriodEnum.MONTH:
            result.setMonth(date.getMonth() + increase);
            break;
        case PeriodEnum.YEAR:
            result.setFullYear(date.getFullYear() + increase);
            break;
    }
    return result;
}

export function capitalize(str: string) {
    if (!Utils.isEmpty(str)) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return str;
}
