import {Component, OnInit} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {ShareActionService} from '../../share-action.service';
import {ConfluenceService, PermissionSettings, Space} from '../../confluence.service';
import Utils from '../../utils/utils';
import {CustomData} from '../../space-schema-form/space-schema-form.component';
import PermissionUtils from '../../utils/permission-utils';

interface SettingsComponentConfig {
    userIsAdmin: boolean;
    permissions: PermissionSettings;
    space: Space;
    lite: boolean;
    customData: CustomData;
    hasViewShareOnPageGroups: boolean;
    hasCreateNewShareGroups: boolean;
    hasEditShareGroups: boolean;
    hasEmailShareGroups: boolean;
    hasDeleteShareGroups: boolean;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    config$: Observable<SettingsComponentConfig>;

    constructor(private route: ActivatedRoute,
                private confluence: ConfluenceService,
                private linkActions: ShareActionService) {
    }

    ngOnInit() {
        this.config$ = zip(
            this.route.parent.queryParamMap,
            this.route.parent.data,
            this.confluence.getAddonProperty('permissions', '{"enabled": false}')
        ).pipe(
            switchMap(([query, data, permissions]) => {
                const space: Space = data.space;
                return zip(
                    of(query),
                    of(space),
                    of(data.config.userIsAdmin),
                    of(PermissionUtils.getPermissionsGroups(permissions))
                );
            }),
            map(([query, space, userIsAdmin, permissions]) => {
                const customData: CustomData = {
                    type: 'SPACE',
                    spaceId: space.id + ''
                };
                return {
                    userIsAdmin,
                    space,
                    permissions,
                    lite: Utils.isLiteApp(query),
                    customData,
                    hasViewShareOnPageGroups: this.hasUserGroups(permissions.viewAllSharesOnPage),
                    hasCreateNewShareGroups: this.hasUserGroups(permissions.createNewShare),
                    hasEditShareGroups: this.hasUserGroups(permissions.editShare),
                    hasEmailShareGroups: this.hasUserGroups(permissions.emailShare),
                    hasDeleteShareGroups: this.hasUserGroups(permissions.deleteShare)
                };
            }),
            shareReplay(1)
        );
    }

    deleteAll() {
        this.config$.subscribe(it => {
                this.linkActions.deleteAllSharesFromSpace(it.space.id);
            }
        );
    }

    hasUserGroups(userGroups: string[]): boolean {
        return Utils.isNotEmptyArray(userGroups);
    }
}
