import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserShareListComponent} from './user-share-list/user-share-list.component';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from '../icons/icons.module';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [UserShareListComponent],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule
    ], exports: [
        UserShareListComponent
    ]
})
export class PersonalViewModule {
}
