import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import Utils from '../../../utils/utils';
import CustomValidators from '../../../utils/custom-validators';
import {UpdateCustomDomainResult} from '../../../app.service';

@Component({
    selector: 'app-custom-domain',
    templateUrl: './custom-domain.component.html',
    styleUrls: ['./custom-domain.component.css']
})
export class CustomDomainComponent {

    @Input() form: FormGroup;
    @Input() documentationLink: string;
    @Input() defaultDomainName: string;

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    onValueChange() {
        this.getControl().setValue(this.getControl().value.trim());

        if (this.getControl().errors && (this.getControl().errors.error || this.getControl().errors.invalid)) {
            this.changeControlValidity({status: 'MODIFIED'});
        }
    }

    changeControlValidity(status: UpdateCustomDomainResult) {
        if (status === 'ERROR') {
            this.getControl().setValidators([Validators.required, CustomValidators.error]);
        } else if (status === 'INVALID') {
            this.getControl().setValidators([Validators.required, CustomValidators.invalid]);
        } else {
            this.getControl().setValidators([Validators.required]);
        }
        this.getControl().updateValueAndValidity();
    }

    private getControl(): AbstractControl {
        return this.form.get('customDomain');
    }
}
