import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Config} from '../share-form-config';
import Utils from '../../utils/utils';
import {Share, ViewShare} from '../../base/share';
import {DateUtil} from '../../utils/date-utils';
import {addToDate} from '../../base/period';

@Component({
    selector: 'app-data-options',
    templateUrl: './data-options.component.html',
    styleUrls: ['./data-options.component.css']
})
export class DataOptionsComponent {

    features = window.getToken().features;

    @Input() form: FormGroup;
    @Input() config: Config;
    @Input() contentUrl: string;

    togglePermissions(val: boolean, shareAllChildPagesDisabled: boolean) {
        this.form.patchValue({
            showComments: this.form.get('showComments').enabled ? val : false,
            allowAddComment: this.form.get('allowAddComment').enabled ? val : false,
            showAttachments: this.form.get('showAttachments').enabled ? val : false,
            allowAddAttachment: this.form.get('allowAddAttachment').enabled ? val : false,
            allowShareChildPages: this.form.get('allowShareChildPages').enabled && !shareAllChildPagesDisabled ? val : false,
            showLabels: this.form.get('showLabels').enabled ? val : false,
            allowRemoveUnsupportedMacros: this.form.get('allowRemoveUnsupportedMacros').enabled ? val : false,
            showMacrosPlaceholder: this.form.get('showMacrosPlaceholder').enabled ? val : false,
            editContent: this.form.get('editContent').enabled ? val : false,
            showPageHistory: this.form.get('showPageHistory').enabled ? val : false,
            showPageStatus: this.form.get('showPageStatus').enabled ? val : false,
            allowPageHistoryDelete: this.form.get('allowPageHistoryDelete').enabled ? val : false,
            allowPageHistoryRestore: this.form.get('allowPageHistoryRestore').enabled ? val : false,
        });
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    linkNameChanged(event) {
        const name = event.target.value;
        if (name) {
            this.form.get('linkName').setValue(Utils.prepareFriendlyLink(name));
        }
    }

    public getLink(): Share {
        const val: ViewShare = this.form.getRawValue();
        const period = () => DateUtil.convert(addToDate(new Date(), val.expirationPeriod.count, val.expirationPeriod.unit));
        const expirationDate = val.expirationEnabled ?
            (val.expirationType === 'DATE_TIME' ? val.expirationDate : period()) : undefined;
        const password = val.hasPassword ? val.password : null;
        return Object.assign(val, {
            expiration: expirationDate,
            password,
            unlockSecret: val.unlockSecret,
            selectedUsersConfig: {
                allowed: val.allowSelectedUsers,
                allowedNotification: val.allowSelectedUsersNotification,
                list: val.selectedUsers,
                domains: val.selectedUsersEmailDomains
            },
            parent: null,
            contentUrl: this.contentUrl,
            samlLoginRequired: val.samlLoginRequired
        });
    }
}
