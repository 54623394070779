import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Utils from '../utils/utils';
import {merge, Observable, of, zip} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import CustomValidators from '../utils/custom-validators';
import {Share, ViewShare} from '../base/share';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfluenceService, Permissions} from '../confluence.service';
import {ShareActionService} from '../share-action.service';
import {addToDate, PeriodEnum} from '../base/period';
import {DateUtil} from '../utils/date-utils';
import {ContentType} from '../base/content';
import {Config} from './share-form-config';
import {ActivityParameters} from '../components/activity/activity.component';
import PermissionUtils from '../utils/permission-utils';

@Component({
    selector: 'app-link-form',
    templateUrl: './share-form.component.html',
    styleUrls: ['./share-form.component.css']
})
export class ShareFormComponent implements OnInit, AfterViewInit {

    features = window.getToken().features;
    config$: Observable<Config>;

    form: FormGroup = this.fb.group({
        id: [''],
        contentId: [''],
        uuid: [''],
        name: ['', [Validators.maxLength(1024)]],
        description: ['', [Validators.maxLength(4096)]],

        expirationEnabled: [false], // Should expire
        expirationType: ['DATE_TIME'], // Expire SELECTABLE, DATE_TIME
        expirationPeriod: [{count: 1, unit: PeriodEnum.WEEK}], // Expiration unit + count (SELECTABLE)
        expirationDate: [DateUtil.convert(DateUtil.addWeek(new Date(), 1))], // Expiration date (DATE_TIME)

        hasPassword: [false],
        password: [{value: '', disabled: true}, [Validators.minLength(8), Validators.maxLength(128)]],
        unlockSecret: [''],

        namedShareLink: [false],
        linkName: [''],

        editContent: [false],

        showComments: [false],
        allowAddComment: [false],
        showAttachments: [false],
        allowAddAttachment: [false],

        allowShareChildPages: [false],
        restrictChildPagesWithLabels: [{value: '', disabled: true}, [Validators.maxLength(1024)]],
        restrictChildPagesWithoutLabels: [{value: '', disabled: true}, [Validators.maxLength(1024)]],

        showLabels: [false],
        allowRemoveUnsupportedMacros: [false],
        showMacrosPlaceholder: [false],

        showPageHistory: [false],
        allowPageHistoryDelete: [false],
        allowPageHistoryRestore: [false],

        showPageStatus: [false],
        showPageTitle: [true],
        showPageAuthor: [true],
        showUpdateDate: [true],
        subscribeButtonPlaceholder: [],
        showAuthorAvatar: [true],

        allowSelectedUsers: [false],
        allowSelectedUsersNotification: [false],
        selectedUsers: [[]],
        selectedUsersEmailDomains: [{value: '', disabled: true}, [CustomValidators.emailDomains()]],
        samlLoginRequired: [false]
    });

    users = new Map();
    tab: string;
    contentUrl: string;
    private selectedUsersValidationKey;
    private allowedEmailDomains;

    @ViewChild('linkNameElement') linkNameElement: ElementRef;

    constructor(private fb: FormBuilder,
                private router: Router,
                private confluence: ConfluenceService,
                private route: ActivatedRoute,
                private linkActions: ShareActionService) {
    }

    ngOnInit(): void {
        document.body.classList.add('share-view');
        this.tab = 'general';
        this.form.get('unlockSecret').disable();

        const data$ = this.route.data;
        const queryParam$ = this.route.queryParamMap;

        this.config$ = zip(data$, queryParam$, this.confluence.getCustomData<any>()).pipe(
            switchMap(([{share, content, restrictions}, queryParam, data]) => {
                const link = share as Share;
                const isNewShare = queryParam.get('isNew') === 'true';

                this.confluence.fetchUsers(
                    new Set([link.createdBy, link.updatedBy]),
                    this.users
                );
                const permissions = data ? data.permissions as Permissions : PermissionUtils.getPermissionsObj(queryParam);

                return zip(
                    of(content),
                    of(link),
                    of(isNewShare),
                    of(restrictions),
                    of(Utils.isLiteApp(queryParam)),
                    of(permissions)
                );
            }),
            map(([content, share, isNewShare, hasRestrictions, isLite, permissions]) => {
                const expirationMaxDateRequired = share.expirationRequired;
                const expirationMaxDatePeriod = {
                    unit: share.expirationUnit,
                    count: share.expirationCount
                };

                const defaultMaxExpirationDate = () => (expirationMaxDateRequired ?
                    addToDate(new Date(), expirationMaxDatePeriod.count, expirationMaxDatePeriod.unit) :
                    DateUtil.addWeek(new Date(), 1));
                const viewLink: ViewShare = Object.assign(share, {
                    hasPassword: !!share.passwordRequired || !!share.password,
                    expirationEnabled: share.expirationRequired || (isNewShare ? false : !!share.expiration),
                    expirationType: isNewShare ? 'SELECTABLE' as const : (share.expiration ? 'DATE_TIME' as const : 'SELECTABLE' as const),
                    expirationPeriod: expirationMaxDateRequired ? expirationMaxDatePeriod : {
                        unit: PeriodEnum.WEEK,
                        count: 1
                    },
                    expirationDate: isNewShare ? DateUtil.convert(defaultMaxExpirationDate()) :
                        share.expiration || DateUtil.convert(DateUtil.addWeek(new Date(), 1)),
                    allowSelectedUsers: share.selectedUsersConfig.allowed,
                    allowSelectedUsersNotification: share.selectedUsersConfig.allowedNotification,
                    selectedUsers: share.selectedUsersConfig.list,
                    selectedUsersEmailDomains: share.selectedUsersConfig.domains,
                    samlLoginRequired: share.samlLoginRequired,
                    unlockSecret: share.unlockSecret
                });
                this.form.patchValue(viewLink, {emitEvent: false});

                if (!permissions.canEdit) {
                    this.form.disable();
                }

                const nameControl = this.form.get('name');
                nameControl.setValidators(Validators.required);

                const hasPasswordControl = this.form.get('hasPassword');
                const passwordControl = this.form.get('password');

                if (share.passwordRequired) {
                    hasPasswordControl.disable({onlySelf: true});
                    passwordControl.setValidators([Validators.required]);
                    passwordControl.updateValueAndValidity();
                }

                // Password
                hasPasswordControl.valueChanges.subscribe(value => {
                    passwordControl.updateValueAndValidity();
                    if (value) {
                        this.form.get('password').enable();

                        passwordControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.compose([
                                Validators.required, Validators.minLength(8), Validators.maxLength(128)
                            ]))
                        ]);
                    } else {
                        this.form.get('password').disable();

                        passwordControl.clearValidators();
                    }
                    passwordControl.updateValueAndValidity();
                });

                const expirationEnabledControl = this.form.get('expirationEnabled');
                const expirationTypeControl = this.form.get('expirationType');
                const expirationPeriodControl = this.form.get('expirationPeriod');
                const expirationDateControl = this.form.get('expirationDate');

                if (share.expirationRequired) {
                    expirationEnabledControl.disable({onlySelf: true});
                }

                if (!this.form.get('expirationEnabled').value) {
                    expirationPeriodControl.disable();
                    expirationTypeControl.disable();
                }
                expirationEnabledControl.valueChanges.subscribe(it => {
                    if (!it) {
                        expirationPeriodControl.disable();
                        expirationTypeControl.disable();
                    } else {
                        expirationPeriodControl.enable({onlySelf: false});
                        expirationTypeControl.enable({onlySelf: false});
                    }
                });

                // Expiration period eg. 1 Month
                expirationPeriodControl.setValidators([
                    Utils.conditionalValidator(() => {
                        return expirationEnabledControl.value && expirationTypeControl.value === 'SELECTABLE';
                    }, Validators.compose([
                        Validators.required,
                        Utils.conditionalValidator(() => expirationMaxDateRequired,
                            CustomValidators.maxPeriod(expirationMaxDatePeriod))
                    ]))
                ]);

                // Expiration date eg. 20-03-2032
                expirationDateControl.setValidators([
                    Utils.conditionalValidator(() => {
                        return expirationEnabledControl.value && expirationTypeControl.value === 'DATE_TIME';
                    }, Validators.compose([
                        Validators.required,
                        CustomValidators.minDateNow(),
                        Utils.conditionalValidator(() => expirationMaxDateRequired,
                            CustomValidators.maxDateFromPeriod(expirationMaxDatePeriod))
                    ]))
                ]);

                merge(expirationEnabledControl.valueChanges, expirationTypeControl.valueChanges)
                    .subscribe(() => {
                        expirationPeriodControl.updateValueAndValidity();
                        expirationDateControl.updateValueAndValidity();
                    });

                if (share.passwordRequired) {
                    hasPasswordControl.disable({onlySelf: true});
                    passwordControl.setValidators([Validators.required]);
                    passwordControl.updateValueAndValidity();
                }

                this.allowedEmailDomains = share.selectedUsersConfig.allowedDomains;

                const allowSelectedUsersControl = this.form.get('allowSelectedUsers');
                if (share.selectedUsersConfig.required) {
                    if (this.allowedEmailDomains.length > 0) {
                        this.form.get('selectedUsersEmailDomains').setValidators(Validators.compose([
                            CustomValidators.emailDomains(),
                            CustomValidators.matchesEmailDomain(this.allowedEmailDomains)
                        ]));
                    } else if (!share.selectedUsersConfig.domains) {
                        this.form.get('selectedUsers').setValidators(Validators.required);
                    }

                    allowSelectedUsersControl.disable({onlySelf: true});
                }

                if (share.selectedUsersConfig.allowed) {
                    this.requireSelectedUsersValidation(true);
                }

                merge(allowSelectedUsersControl.valueChanges,
                    this.form.get('selectedUsers').valueChanges,
                    this.form.get('selectedUsersEmailDomains').valueChanges)
                    .subscribe(() => {
                        this.requireSelectedUsersValidation(allowSelectedUsersControl.value);
                    });

                this.form.get('allowSelectedUsers').valueChanges.subscribe((value) => {
                    if (value) {
                        this.form.get('selectedUsersEmailDomains').enable({emitEvent: false});
                    } else {
                        this.form.get('selectedUsersEmailDomains').disable({emitEvent: false});
                    }
                });

                if (this.form.get('allowSelectedUsers').value) {
                    this.form.get('selectedUsersEmailDomains').enable({emitEvent: false});
                } else {
                    this.form.get('selectedUsersEmailDomains').disable({emitEvent: false});
                }

                if (this.form.get('allowShareChildPages').value) {
                    this.form.get('restrictChildPagesWithLabels').enable({emitEvent: false});
                    this.form.get('restrictChildPagesWithoutLabels').enable({emitEvent: false});
                } else {
                    this.form.get('restrictChildPagesWithLabels').disable({emitEvent: false});
                    this.form.get('restrictChildPagesWithoutLabels').disable({emitEvent: false});
                }

                this.form.get('allowShareChildPages').valueChanges.subscribe((value) => {
                    if (value) {
                        this.form.get('restrictChildPagesWithLabels').enable();
                        this.form.get('restrictChildPagesWithoutLabels').enable();
                    } else {
                        this.form.get('restrictChildPagesWithLabels').disable();
                        this.form.get('restrictChildPagesWithoutLabels').disable();
                    }
                });

                const expirationSettingsMaxDateError = expirationMaxDateRequired ?
                    this.formatExpirationSettingsMaxDateValidationMessage(share) : '';

                const shareAllChildPagesDisabled = ContentType.blogpost === content.type;

                this.setParentRestrictions(share);
                this.contentUrl = share.contentUrl;

                return {
                    editionLink: environment.host + '/content',
                    title: content.title,
                    type: content.type,
                    share: viewLink,
                    expirationSettingsMaxDate: expirationSettingsMaxDateError,
                    hasRestrictions,
                    isLite,
                    shareAllChildPagesDisabled,
                    parent: null,
                    permissions,
                    hasEditPermission: permissions.canEdit,
                    hasSendEmailPermission: permissions.canSendEmail,
                    hasDeletePermission: permissions.canDelete
                };
            })
        );
    }

    ngAfterViewInit(): void {
        if (this.linkNameElement) {
            this.linkNameElement.nativeElement.focus();
        }
    }

    private requireSelectedUsersValidation(allowSelectedUsers) {
        const selectedUsers = this.form.get('selectedUsers');
        const selectedUsersEmailDomains = this.form.get('selectedUsersEmailDomains');

        this.changeSelectedUsersValidationRules(
            allowSelectedUsers,
            selectedUsers.value,
            selectedUsersEmailDomains.value,
            selectedUsers,
            selectedUsersEmailDomains);
    }

    private changeSelectedUsersValidationRules(allowSelectedUsers,
                                               selectedUsers,
                                               selectedUsersEmailDomains,
                                               selectedUsersControl,
                                               selectedUsersEmailDomainsControl) {
        const selectedUsersRequired = this.isRequired(selectedUsersEmailDomainsControl);
        const selectedUsersEmailDomainsRequired = this.isRequired(selectedUsersEmailDomainsControl);
        const key = allowSelectedUsers + selectedUsers + selectedUsersEmailDomains;

        if (key !== this.selectedUsersValidationKey) {
            this.selectedUsersValidationKey = key;

            if (allowSelectedUsers) {
                const hasSelectedUsers = selectedUsers.length > 0;
                const hasSelectedUserDomains = !!selectedUsersEmailDomains
                    && selectedUsersEmailDomains.length > 0
                    && (!selectedUsersEmailDomainsControl.errors || !selectedUsersEmailDomainsControl.errors.emailDomains);

                if (hasSelectedUsers) {
                    selectedUsersEmailDomainsControl.setValidators(Validators.compose([
                        CustomValidators.emailDomains(),
                        CustomValidators.matchesEmailDomain(this.allowedEmailDomains)
                    ]));
                    selectedUsersEmailDomainsControl.updateValueAndValidity({onlySelf: true});
                } else if (!hasSelectedUserDomains && !hasSelectedUsers && !selectedUsersEmailDomainsRequired) {
                    selectedUsersEmailDomainsControl.setValidators(Validators.compose([
                        Validators.required,
                        CustomValidators.emailDomains(),
                        CustomValidators.matchesEmailDomain(this.allowedEmailDomains)
                    ]));
                    selectedUsersEmailDomainsControl.updateValueAndValidity({onlySelf: true});
                }

                if (hasSelectedUserDomains && selectedUsersRequired) {
                    selectedUsersControl.clearValidators();
                    selectedUsersControl.updateValueAndValidity({onlySelf: true});
                } else if (!hasSelectedUsers && !hasSelectedUserDomains && !selectedUsersRequired) {
                    selectedUsersControl.setValidators(Validators.required);
                    selectedUsersControl.updateValueAndValidity({onlySelf: true});
                }
            } else if (selectedUsersRequired && selectedUsersEmailDomainsRequired) {
                selectedUsersControl.clearValidators();
                selectedUsersControl.updateValueAndValidity({onlySelf: true});

                selectedUsersEmailDomainsControl.setValidators(Validators.compose([
                    CustomValidators.emailDomains(),
                    CustomValidators.matchesEmailDomain(this.allowedEmailDomains)
                ]));
                selectedUsersEmailDomainsControl.updateValueAndValidity({onlySelf: true});
            }
        }
    }

    private isRequired(control: AbstractControl): boolean {
        if (control.validator) {
            const validator = control.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        // tslint:disable
        if (control['controls']) {
            for (const controlName in control['controls']) {
                if (control['controls'][controlName]) {
                    if (this.isRequired(control['controls'][controlName])) {
                        return true;
                    }
                }
            }
        }
        // tslint:enable
        return false;
    }

    private formatExpirationSettingsMaxDateValidationMessage(share: Share) {
        return 'Maximum link expiration time: ' + share.expirationCount + ' '
            + share.expirationUnit.toLowerCase();
    }

    private isOptionsFormValid(): boolean {
        Utils.validateAllFormFields(this.form);
        return this.form.valid;
    }

    public getLink(): Share {
        const val: ViewShare = this.form.getRawValue();
        const period = () => DateUtil.convert(addToDate(new Date(), val.expirationPeriod.count, val.expirationPeriod.unit));
        const expirationDate = val.expirationEnabled ?
            (val.expirationType === 'DATE_TIME' ? val.expirationDate : period()) : undefined;
        const password = val.hasPassword ? val.password : null;
        return Object.assign(val, {
            expiration: expirationDate,
            password,
            unlockSecret: val.unlockSecret,
            selectedUsersConfig: {
                allowed: val.allowSelectedUsers,
                allowedNotification: val.allowSelectedUsersNotification,
                list: val.selectedUsers,
                domains: val.selectedUsersEmailDomains
            },
            parent: null,
            contentUrl: this.contentUrl,
            samlLoginRequired: val.samlLoginRequired
        });
    }

    confirmDeletion(link: Share) {
        Utils.hide();

        this.linkActions.deleteSingle(link)
            .then(({deleted}) => {
                if (deleted) {
                    this.confluence.closeDialog({executeCallback: true});
                } else {
                    Utils.show();
                }
            });
    }

    sendViaEmail(link: ViewShare) {
        this.update().then(() => {
            this.router.navigate(['send-via-mail', link.uuid, link.contentId], {queryParamsHandling: 'merge'});
        });
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    update() {
        if (this.isOptionsFormValid()) {
            return this.linkActions.update(this.getLink());
        } else if (this.form.status === 'DISABLED') {
            return Promise.resolve();
        }
        return Promise.reject();
    }

    saveAndExit() {
        this.update().then(() => {
            this.confluence.closeDialog({executeCallback: true});
        });
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            if (e.ctrlKey) {
                this.saveAndExit();
            } else {
                e.preventDefault();
            }
        }
    }

    isSaveDisabled(config: Config) {
        return !this.form.valid || config.hasRestrictions;
    }

    isSendDisabled(config: Config) {
        return !this.form.valid && this.form.status !== 'DISABLED' ||
            config.share.status === 'DISABLED' ||
            config.hasRestrictions;
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    close() {
        this.confluence.closeDialog({executeCallback: false});
    }

    private setParentRestrictions(share: Share) {
        const fc = share.parent.fieldConfig;
        const sc = share.parent.securityConfig;

        if (!fc.namedShareLinkEnabled) {
            this.disable('namedShareLink');
        }
        if (!fc.contentAllowEdit) {
            this.disable('editContent');
        }
        if (!fc.commentsShow) {
            this.disable('showComments');
        }
        if (!fc.commentsAllowAdd) {
            this.disable('allowAddComment');
        }
        if (!fc.attachmentsShow) {
            this.disable('showAttachments');
        }
        if (!fc.attachmentsAllowAdd) {
            this.disable('allowAddAttachment');
        }
        if (!fc.childPagesAllowShare) {
            this.disable('allowShareChildPages');
        }
        if (!fc.labelsShow) {
            this.disable('showLabels');
        }
        if (!fc.unsupportedMacrosAllowRemove) {
            this.disable('allowRemoveUnsupportedMacros');
        }
        if (!fc.macrosPlaceholderShow) {
            this.disable('showMacrosPlaceholder');
        }
        if (this.features?.pageHistory) {
            if (!fc.pageHistoryShow) {
                this.disable('showPageHistory');
            }
            if (!fc.pageHistoryAllowDelete) {
                this.disable('allowPageHistoryDelete');
            }
            if (!fc.pageHistoryAllowRestore) {
                this.disable('allowPageHistoryRestore');
            }
        }
        if (!fc.pageStatusShow) {
            this.disable('showPageStatus');
        }
        if (sc.samlLogin === 'REQUIRED') {
            const samlLogin = this.form.get('samlLoginRequired');
            samlLogin.disable({onlySelf: true});
            samlLogin.setValue(true);
        }
        if (sc.samlLogin === 'DISABLED') {
            this.disable('samlLoginRequired');
        }
    }

    private disable(field: string) {
        this.form.get(field).setValue(false);
        this.form.get(field).disable();
    }

    isFormGroup(control: AbstractControl): control is FormGroup {
        return !!(control as FormGroup).controls;
    }

    collectErrors(control: AbstractControl): any | null {
        if (this.isFormGroup(control)) {
            return Object.entries(control.controls)
                .reduce(
                    (acc, [key, childControl]) => {
                        const childErrors = this.collectErrors(childControl);
                        if (childErrors) {
                            acc = {...acc, [key]: childErrors};
                        }
                        return acc;
                    },
                    null
                );
        } else {
            return control.errors;
        }
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }

    securityOptionsInvalid(): boolean {
        return this.form.get('expirationPeriod').invalid
            || this.form.get('expirationDate').invalid
            || this.form.get('password').invalid;
    }

    changeTab(tab: string) {
        this.tab = tab;
    }

    getActivityParameters(share: ViewShare): ActivityParameters {
        return {
            level: 'SHARE',
            allowShareChildPages: share.allowShareChildPages,
            spaceKey: share.spaceKey,
            uuid: share.uuid
        };
    }
}
