import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {ActivatedRoute, convertToParamMap, ParamMap} from '@angular/router';
import {LITE_APP_NAME} from '../../const';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Constants} from './constants';
import {ElementRef} from '@angular/core';
import {Image} from '../app.service';

export default class Utils {

    public static isEmpty(value) {
        return (!value || 0 === value.length);
    }

    public static toIsoDate(date, datePipe) {
        if (date) {
            return datePipe.transform(date, Constants.ISO_DATE_TIME_FORMAT) + 'Z';
        } else {
            return '';
        }
    }

    public static isNotEmptyArray(items) {
        return Array.isArray(items) && items.length > 0;
    }

    public static isEmptyArray(items) {
        return Array.isArray(items) && items.length === 0;
    }

    public static isTrue(val) {
        return val === 'true';
    }

    public static copyToClipboard(password: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = password;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public static generateRandomString(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.updateValueAndValidity({onlySelf: true, emitEvent: true});
                control.markAsTouched({onlySelf: true});
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {
                control.markAsTouched({onlySelf: true});
                control.markAsDirty({onlySelf: true});
                this.validateAllFormFields(control);
            }
        });
    }

    public static shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    public static hide() {
        document.body.style.display = 'none';
    }

    public static show() {
        document.body.style.display = 'block';
    }

    public static isLiteApp(queryParam: ParamMap) {
        return queryParam.get('n') === LITE_APP_NAME;
    }

    public static isLiteApp$(queryParam: Observable<ParamMap>) {
        return queryParam.pipe(map(it => this.isLiteApp(it)));
    }

    public static splitEmails(emailString): string[] {
        if (emailString) {
            const emailWithAngleBracketsRegExp = new RegExp(/<(.*?)>/gi);
            if (emailWithAngleBracketsRegExp.test(emailString)) {
                const emails = [];
                for (const result of emailString.matchAll(emailWithAngleBracketsRegExp)) {
                    emails.push(result[1]);
                }
                return this.arrayWithoutDuplicates(emails);
            } else if (emailString.includes(';')) {
                return this.arrayWithoutDuplicates(emailString.split(';'));
            } else {
                return this.arrayWithoutDuplicates(emailString.split(Constants.COMMA));
            }
        }
        return [];
    }

    public static splitEmailDomains(emailDomainString): string[] {
        if (emailDomainString) {
            return this.arrayWithoutDuplicates(emailDomainString.split(Constants.COMMA));
        }
        return [];
    }

    private static arrayWithoutDuplicates(input) {
        return this.trimArrayElements(input)
            .sort()
            .filter((item, index, array) => {
                return item ? !index || item.trim() !== array[index - 1] : false;
            });
    }

    private static trimArrayElements(array) {
        const trimmed = [];
        array.forEach(item => {
            if (item) {
                trimmed.push(item.trim());
            }
        });
        return trimmed;
    }

    public static combineParams(route: ActivatedRoute): Observable<ParamMap> {
        return combineLatest([route.params, route.queryParams]).pipe(
            map(([p1, p2]) => {
                return convertToParamMap({...p1, ...p2});
            })
        );
    }

    public static replaceSearchText(input): string {
        return input ? input.replaceAll('@@@hl@@@', '<b>')
            .replaceAll('@@@endhl@@@', '</b>') : input;
    }

    public static replaceSearchTitle(input): string {
        return input ? input.replaceAll('@@@hl@@@', '')
            .replaceAll('@@@endhl@@@', '') : input;
    }

    public static sanitize(input: string): string {
        if (input) {
            const charactersMap = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                '\'': '&#x27;',
                '/': '&#x2F;',
            };
            const reg = /[&<>"'/]/ig;
            return input.replace(reg, (match) => {
                return charactersMap[match];
            });
        }
        return input;
    }

    public static expandTextArea(el: ElementRef<HTMLInputElement>) {
        el.nativeElement.style.height = el.nativeElement.scrollHeight + 2 + 'px';
    }

    public static prepareFriendlyLink(name) {
        return name.replace(/[?\s=+&%\/[\]]/g, '_')
            .toLowerCase();
    }

    public static detectBrowser() {
        const userAgent = navigator.userAgent;
        let tem;
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }

        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }

        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

        const hasVersion = (tem = userAgent.match(/version\/(\d+)/i));
        if (hasVersion != null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest.join(' ');
    }

    public static fileToModel(file: File): Observable<Image> {
        if (!file) {
            return of(null);
        }
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = (event) => {
                subscriber.next({mime: file.type, base64: btoa(event.target.result.toString())});
                subscriber.complete();
            };
        });
    }

    public static modelToFile(image: Image): File {
        if (!image || !image.base64 || !image.mime) {
            return null;
        }
        const bs = atob(image.base64);
        const buffer = new ArrayBuffer(bs.length);
        const ba = new Uint8Array(buffer);
        for (let i = 0; i < bs.length; i++) {
            ba[i] = bs.charCodeAt(i);
        }
        const blob = new Blob([ba], {type: image.mime});
        return new File([blob], 'image', {type: image.mime});
    }

    public static conditionalValidator(predicate: () => boolean, validator: ValidatorFn): ValidatorFn {
        return control => {
            if (predicate()) {
                return validator(control);
            }
            return null;
        };
    }
}
