import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor">' +
    // tslint:disable-next-line:max-line-length
    '   <path d="M4 18h16.008C20 18 20 6 20 6H3.992C4 6 4 18 4 18M2 5.994C2 4.893 2.898 4 3.99 4h16.02C21.108 4 22 4.895 22 5.994v12.012A1.997 1.997 0 0 1 20.01 20H3.99A1.994 1.994 0 0 1 2 18.006z"></path><path d="M7 5v14h2V5z"></path><path d="M7 11h14V9H7z"></path></g>' +
    '</svg>';

@Directive({
    selector: '[appGroupIcon]'
})
export class GroupIconDirective implements OnInit {
    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
