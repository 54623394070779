import {Component, Input} from '@angular/core';
import {Audit} from '../../base/config';
import {AtlassianUser} from '../../base/atlassian-user';

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.css']
})
export class AuditComponent {

    @Input() audit: Audit;
    @Input() users: Map<string, AtlassianUser>;
    @Input() avoidMargin = false;

    getUser(accountId) {
        return this.users.get(accountId);
    }
}
