import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-page-customization',
    templateUrl: './page-customization.component.html',
    styleUrls: ['./page-customization.component.css']
})
export class PageCustomizationComponent {
    @Input() form: FormGroup;

    tab = 'general';

    static initializeCssValues(sides, cssProperty, form, targetForm) {
        const cssValues = form.value.split(' ');

        sides.forEach((side, index) => {
            const value = cssValues[index] || '';
            const isAuto = value === 'auto';
            targetForm.get(`${cssProperty}${side}`).setValue(isAuto ? '' : value.replace('px', ''));
            targetForm.get(`${cssProperty}Type${side}`).setValue(isAuto ? 'auto' : 'pixels');
        });
    }

    static computeCssValues(sides, form, property) {
        return sides.map(side => {
            const value = form.value[property + side];
            const type = form.value[property + 'Type' + side];
            return type === 'auto' ? 'auto' : (value ? `${value}px` : '');
        });
    }

    static applyCssShorthandRules(values) {
        let nonAutoValues = values.map(v => (v !== 'auto' && v !== '') ? parseInt(v, 10) : v);

        if (nonAutoValues.filter(v => v !== 'auto' && v !== '').length === 1) {
            const singleValue = nonAutoValues.find(v => v !== 'auto' && v !== '');
            nonAutoValues = nonAutoValues.map(v => v === 'auto' ? 'auto' : (v === '' ? '' : `${singleValue}px`));
        } else {
            nonAutoValues = nonAutoValues.map((v, idx) => {
                if (v === 'auto') {
                    return 'auto';
                }
                if (v === '') {
                    return '';
                }
                if (idx >= 0 && idx <= 3) {
                    return `${nonAutoValues[idx]}px`;
                }
            });
        }

        for (let i = 0; i < 4; i++) {
            values[i] = nonAutoValues[i];
        }
    }

    changeTab(tab: string) {
        this.tab = tab;
    }
}
