import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, zip} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfluenceService} from '../confluence.service';
import {SendStatus} from './email-content';
import {RecipientsComponent} from './recipients/recipients.component';
import {ShareService} from '../base/share.service';
import {environment} from '../../environments/environment';
import {Content} from '../base/content';
import {AtlassianUser, EMPTY_USER} from '../base/atlassian-user';
import {RecipientType} from './recipient';
import {LoaderService} from '../loader.service';

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit {

    config$: Observable<any>;
    uuid: string;

    form = new FormGroup({
        uuid: new FormControl(null),
        recipients: new FormControl(null, [Validators.required]),
        subject: new FormControl(null, [Validators.required]),
        content: new FormControl(null, [Validators.required]),
        prepareContentToSend: new FormControl(true)
    });

    sendErrorMessage: string;
    status: SendStatus;
    recipientsComponent: RecipientsComponent;

    constructor(private service: ShareService,
                private route: ActivatedRoute,
                private confluence: ConfluenceService,
                private loader: LoaderService,
                private router: Router) {
    }

    @ViewChild(RecipientsComponent)
    set recipients(recipientsComponent: RecipientsComponent) {
        if (recipientsComponent) {
            if (!this.recipientsComponent) {
                recipientsComponent.executeExpand();
            }
            this.recipientsComponent = recipientsComponent;
        }
    }

    ngOnInit(): void {
        document.body.classList.add('send-by-email-view');

        this.config$ = zip(this.confluence.getCustomData<any>(), this.route.params).pipe(
            switchMap(([it, params]) => {
                const uuid = !!it && it.uuid ? it.uuid : (!!params ? params.uuid : undefined);
                const contentId = !!it && it.contentId ? it.contentId : (!!params ? params.contentId : undefined);
                const hasPermissions = !!it && it.permissions;
                const canEdit = hasPermissions ? it.permissions.canEdit : false;
                const canSendEmail = hasPermissions ? it.permissions.canSendEmail : false;

                return zip(
                    of(uuid),
                    this.getContent(contentId),
                    this.getUser(),
                    of(canEdit),
                    of(canSendEmail)
                );
            }),
            switchMap(([uuid, content, user, hasEditPermission, hasSendEmailPermission]) => {
                return zip(
                    of(content.id),
                    of(content.title),
                    this.service.getMail(uuid, content.title, user.displayName),
                    of(hasEditPermission),
                    of(hasSendEmailPermission)
                );
            }),
            switchMap(([contentId, title, config, hasEditPermission, hasSendEmailPermission]) => {
                config.contentId = contentId;
                config.title = title;
                config.hasEditPermission = hasEditPermission;
                config.hasSendEmailPermission = hasSendEmailPermission;
                return of(config);
            }),
            tap((config) => {
                this.form.patchValue(config);
            })
        );
    }

    shouldDisplayError(): boolean {
        return this.getRecipients().invalid && this.isTouchedOrDirty();
    }

    isTouchedOrDirty(): boolean {
        const control: AbstractControl = this.getRecipients();
        return control.dirty || control.touched;
    }

    send() {
        this.executeSend().then(() => {
            this.confluence.closeDialog();
            AP.flag.create({
                title: 'External Share | Success',
                body: 'External Share link has been successfully sent',
                type: 'success',
                close: 'auto'
            });
        });
    }

    executeSend() {
        return new Promise((resolve) => {
            this.loader.fullscreen(true);

            this.service.sendMail(this.form.value)
                .pipe(finalize(() => this.loader.fullscreen(false)))
                .subscribe(() => {
                    resolve();
                }, (response) => {
                    const error = environment.server ? response.body : response.error;
                    this.status = error.status;
                    this.sendErrorMessage = error.message;
                });
        });
    }

    cancel() {
        this.confluence.closeDialog({
            executeCallback: false,
            executeCancelCallback: true
        });
    }

    getRecipients(): AbstractControl {
        return this.form.get('recipients');
    }

    isSendDisabled() {
        return !this.form.valid || this.hasSendError() || this.requiredToRecipientsEmpty();
    }

    hasSendError(): boolean {
        return this.status === SendStatus.CONTENT_EXPIRED ||
            this.status === SendStatus.SPACE_DISABLED;
    }

    requiredToRecipientsEmpty(): boolean {
        return this.getRecipients().value.filter(item => item.type === RecipientType.TO).length === 0;
    }

    isRecipientsRequired() {
        return this.getRecipients()?.errors?.required;
    }

    collapseRecipients(recipientsCollapsed: boolean) {
        this.recipientsComponent.setCollapsed(recipientsCollapsed);
    }

    onKeyUp(event) {
        if (event.keyCode === 13) {
            this.addNewLine(event.target);
        }
    }

    edit(uuid: string, contentId: number) {
        this.router.navigate(['browse', 'share', uuid, contentId], {queryParamsHandling: 'merge'});
    }

    private addNewLine(input) {
        const position = input.selectionStart;
        const content = this.form.get('content').value;
        const result = [content.slice(0, position), '\n', content.slice(position)].join('');
        this.form.get('content').setValue(result);
        input.selectionEnd = position + 1;
    }

    private getUser(): Observable<AtlassianUser> {
        return environment.server ? of(EMPTY_USER) : this.confluence.getUser();
    }

    private getContent(contentId): Observable<Content> {
        return environment.server ? of({
            id: contentId,
            title: undefined,
            container: undefined
        }) : this.confluence.getContent(contentId);
    }
}
