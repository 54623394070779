import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Activity, ActivityFilterCriteria} from '../../base/activity';
import {Constants} from '../../utils/constants';
import {FormControl, FormGroup} from '@angular/forms';
import {map, mergeAll, switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {Observable, of, Subscription, zip} from 'rxjs';
import {ConfluenceService} from '../../confluence.service';
import {environment} from '../../../environments/environment';
import {ServerDataSource} from '../app-table.component';
import {ShareActionService} from '../../share-action.service';
import {AppService} from '../../app.service';
import {CsvData, ExportCsv} from '../exportCsv/exportCsv';
import {LoaderService} from '../../loader.service';
import {DatePipe} from '@angular/common';
import {ViewShare} from '../../base/share';
import {AtlassianUser} from '../../base/atlassian-user';
import {SearchFunction} from '../better-select/better-select.component';

export class ActivityParameters {
    level: 'SHARE' | 'PAGE' | 'SPACE' | 'GLOBAL';
    spaceKey?: string;
    contentId?: string;
    uuid?: string;
    allowShareChildPages?: boolean;
    hasCreatePermission?: boolean;
    hasEditPermission?: boolean;
    hasSendEmailPermission?: boolean;
    hasDeletePermission?: boolean;
    skipFetchData?: boolean;
}

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css'],
})
export class ActivityComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    @Input() parameters: ActivityParameters;
    @Input() viewType: string;
    @Input() share: ViewShare;
    @Input() users: Map<string, AtlassianUser>;

    titles = new Map();

    filterVisible = false;

    sources = Constants.ACTIVITY_SOURCES;
    actions = Constants.ACTIVITY_ACTIONS;

    filterForm = new FormGroup({
        spaces: new FormControl(''),
        contents: new FormControl(''),
        source: new FormControl(''),
        action: new FormControl(''),
        userName: new FormControl(''),
        userEmail: new FormControl('')
    });
    filterValue;
    userFilterValue;
    emailFilterValue;

    displayErrorCsvMessage = false;

    spacePick: SearchFunction;

    constructor(public source: ServerDataSource<Activity, ActivityFilterCriteria>,
                private confluence: ConfluenceService,
                private shareActionService: ShareActionService,
                private loader: LoaderService,
                private app: AppService,
                private datePipe: DatePipe) {
        this.spacePick = this.spacePickFoo.bind(this);
    }

    ngOnInit(): void {
        this.setupDatasource();

        this.subscription = this.source.get().pipe(
            switchMap((list) => {
                const contentIds = [...new Set(list.map(el => el.contentId))].join(', ');
                if (!Utils.isEmpty(contentIds)) {
                    return this.fetchPageTitles(contentIds);
                }
                return of({results: []});
            })
        ).subscribe((title) => {
            title.results.forEach((el => {
                this.titles.set('' + el.content.id, el.content.title);
            }));
        });

        this.filterForm.valueChanges.subscribe(it => {
            const spaces = (it.spaces || []).map(space => space.id);
            const filterValue = {
                contents: it.contents || [],
                spaces,
                spaceKey: this.getSpaceKey(),
                contentId: this.getContentId(),
                uuid: this.getUuid(),
                source: it.source,
                action: it.action
            };
            if (!this.filterValue || this.filterFormSelectValuesChanged(filterValue)) {
                this.source.updateFilter({
                    contents: it.contents || [],
                    spaces,
                    spaceKey: this.getSpaceKey(),
                    contentId: this.getContentId(),
                    uuid: this.getUuid(),
                    source: it.source,
                    action: it.action
                });
            }
            this.filterValue = filterValue;
        });
        this.filterVisible = this.isGlobalOrSpace();
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    spacePickFoo: SearchFunction = (term) => {
        return this.confluence.searchForSpaces(term).pipe(
            map(results => {
                const keys = results.map(key => key.key);

                return this.confluence.getSpacesByKey(keys).pipe(
                    map(space => {
                        const mappedResults = space.map(s => ({id: s.id, text: s.name}));
                        return {
                            results: mappedResults,
                            pagination: {more: false}
                        };
                    })
                );
            }),
            mergeAll()
        );
    }

    displayExport() {
        return this.viewType === 'global-view' || this.viewType === 'space-view';
    }

    exportCSV(): void {
        this.displayErrorCsvMessage = false;
        this.loader.fullscreen(true);
        const spaceKey: string = this.getSpaceKey();
        const app: AppService = this.app;
        const header: string[] = ['Page name', 'Source', 'Action', 'User', 'Email', 'Ip address', 'Date'];

        getAllActivitiesArray(this.filterForm).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.loader.fullscreen(false);
                this.displayErrorCsvMessage = true;
                return false;
            }
            of(sharesArray).pipe(
                switchMap((array) => {
                    const filtered = array.filter(i => i.contentId != null);
                    const contentIds = new Set(filtered.map(el => el.contentId));
                    const pageIdsWithName$: Observable<Map<any, any>> = this.confluence.fetchPageTitlesForExport(contentIds);

                    return zip(of(filtered), pageIdsWithName$);
                }),
                map(([array, titles]) => {
                    const modifiedArray: Activity[] = array.map(el => {
                        el.contentId = titles.get(el.contentId.toString());
                        return el;
                    });
                    return modifiedArray;
                })
            ).subscribe((a) => {
                const rows = getRows(a);
                const csvData: CsvData = {rows, header};
                downloadFile(csvData);
                this.loader.fullscreen(false);
            });
        });

        function getAllActivitiesArray(filterForm): Promise<Array<Activity>> {
            return new Promise(resolve => {
                const query = {spaceKey, usersQuery: getUsersQuery(filterForm)};
                app.getAllActivity(query).then(elements => {
                    let activitiesArray: Array<Activity> = [];
                    for (const array of elements) {
                        activitiesArray = activitiesArray.concat(array);
                    }
                    resolve(activitiesArray);
                });
            });
        }

        function getUsersQuery(filterForm) {
            const filterValues = filterForm.value;
            const keys = Object.keys(filterValues);
            const searchQuery = [];
            keys.forEach((key, index) => {
                if (typeof filterValues[key] === 'string') {
                    if (filterValues[key] !== '') {
                        searchQuery.push(`${key}=${filterValues[key]}`);
                    }
                } else if (Array.isArray(filterValues[key])) {
                    for (let item of filterValues[key]) {
                        if (filterValues[key].length !== 0) {
                            if (isObjectAndHasProperty(item, 'id')) {
                                item = item.id;
                            }
                            searchQuery.push(`${key}=${item}`);
                        }
                    }
                }
            });
            return searchQuery.join('&');

            function isObjectAndHasProperty(obj, propertyName) {
                return obj !== null && typeof obj === 'object' && obj.hasOwnProperty(propertyName);
            }
        }

        const datePipe = this.datePipe;

        function getRows(activitiesArray: Activity[]): string[][] {
            const rows: string[][] = [];
            for (const activity of activitiesArray) {
                const row: string[] = [
                    activity.contentId,
                    activity.source,
                    activity.action.toUpperCase(),
                    activity.user ? activity.user.firstName + ' ' + activity.user.lastName : activity.anonymousUserName
                        ? activity.anonymousUserName : '',
                    activity.user ? activity.user.email : activity.anonymousUserEmail ? activity.anonymousUserEmail : '',
                    activity.ip,
                    Utils.toIsoDate(activity.created, datePipe)
                ];
                rows.push(row);
            }
            return rows;
        }

        function downloadFile(csvData: CsvData): void {
            if (spaceKey) {
                ExportCsv.exportToCsv('space_' + spaceKey + '_activity-list', csvData);
            } else {
                ExportCsv.exportToCsv('global_activity_list', csvData);
            }
        }
    }

    searchValue(data) {
        return data.id;
    }

    spaceTemplateResult(item) {
        return item.name;
    }

    contentsSearch = (term) => {
        if (!term) {
            return of();
        }

        const query = `title~%27${term}*%27`;

        return this.confluence.searchForContent(query || '').pipe(
            map(it => it.results || []),
            map(it => {
                const results = it
                    .filter(i => i.content && i.content.type === 'page')
                    .map(i => ({
                        text: Utils.replaceSearchText(i.content.title),
                        title: Utils.replaceSearchTitle(i.content.title),
                        id: i.content.id
                    }));

                return {results};
            })
        );
    }

    templateResult(data) {
        if (data.iconUrl) {
            return $(
                '<span class="avatar-container">' +
                '<img src="' + data.iconUrl + '" class="avatar" /><span>' + Utils.sanitize(data.title) + '</span>' +
                '</span>'
            );
        }
        return data.title;
    }

    hasSourceLink(activity: Activity): boolean {
        return !!activity.attachmentId
            || !!activity.commentId;
    }

    isLinkable(activity: Activity): boolean {
        return activity.action !== 'Deleted';
    }

    getSourceLink(activity: Activity): string {
        let href = environment.host + '/wiki/';
        if (activity.attachmentId) {
            href += `pages/viewpageattachments.action?pageId=${activity.contentId}&preview=/${activity.contentId}/${activity.attachmentId}`;
        } else if (activity.commentId) {
            href += `spaces/${activity.spaceKey}/pages/${activity.contentId}?focusedCommentId=${activity.commentId}`;
        }
        return href;
    }

    filterByUserName(user) {
        if (user !== this.userFilterValue) {
            this.userFilterValue = user;
            this.filterTable();
        }
    }

    filterByUserEmail(email) {
        if (email !== this.emailFilterValue) {
            this.emailFilterValue = email;
            this.filterTable();
        }
    }

    filterTable() {
        const it = this.filterForm.value;

        this.source.updateFilter({
            spaceKey: this.getSpaceKey(),
            contentId: this.getContentId(),
            uuid: this.getUuid(),
            source: it.source,
            action: it.action,
            userName: it.userName,
            userEmail: it.userEmail
        });
    }

    getTitle(contentId) {
        return this.titles.has('' + contentId) ? this.titles.get('' + contentId) : ' ';
    }

    showPageName() {
        return this.parameters.allowShareChildPages
            || this.isNotShare();
    }

    showShareEdition(): boolean {
        return this.isNotShare();
    }

    edit(activity: Activity) {
        const customData = {
            uuid: activity.uuid,
            contentId: +activity.contentId,
            readOnly: false,
            permissions: {
                canCreate: this.parameters.hasCreatePermission,
                canEdit: this.parameters.hasEditPermission,
                canSendEmail: this.parameters.hasSendEmailPermission,
                canDelete: this.parameters.hasDeletePermission
            }
        };
        this.shareActionService.editShare(customData);
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }

    public isGlobal() {
        return this.parameters.level === 'GLOBAL';
    }

    public isGlobalOrSpace() {
        return this.isGlobal()
            || this.parameters.level === 'SPACE';
    }

    private fetchPageTitles(contentIds) {
        const query = `id%20in%20(${contentIds})`;
        return this.confluence.searchForContent(query || '');
    }

    private filterFormSelectValuesChanged(value): boolean {
        return !this.filterValue ||
            (this.filterValue.contents !== value.contents) ||
            (this.filterValue.spaces !== value.spaces) ||
            (this.filterValue.source !== value.source) ||
            (this.filterValue.action !== value.action);
    }

    private setupDatasource() {
        this.source.reload({
            endPoint: '/api/share/activity',
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: this.getLimit(),
            filter: {
                spaceKey: this.getSpaceKey(),
                contentId: this.getContentId(),
                uuid: this.getUuid()
            },
            skipFetchData: this.parameters.skipFetchData
        });
    }

    private getLimit() {
        return this.isGlobalOrSpace() ? 20 : 10;
    }

    private isNotShare() {
        return this.parameters.level !== 'SHARE';
    }

    private getSpaceKey() {
        return !!this.parameters.spaceKey ? this.parameters.spaceKey : '';
    }

    private getContentId() {
        return !!this.parameters.contentId ? this.parameters.contentId : '';
    }

    private getUuid() {
        return !!this.parameters.uuid ? this.parameters.uuid : '';
    }
}
