import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoaderService} from '../loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    notClosableDialogOperations: Array<string> = [
        'Send Mail',
        'Update custom domain'];

    constructor(private loader: LoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.has('X-Operation-Name')) {
            const operationName = request.headers.get('X-Operation-Name');
            const headers = request.headers.delete('X-Operation-Name');
            const directRequest = request.clone({headers});
            return next.handle(directRequest).pipe(
                catchError((it: any) => {
                    let reportErrorDisabled = false;

                    if (it && (it.type === HttpEventType.Response || it.error)) {

                        let errorMessage = 'An unexpected error occurred';
                        if (it.error) {
                            errorMessage = it.error.message;
                            reportErrorDisabled = it.error.reportErrorDisabled;
                        }

                        if (isJsonResponse(headers)) {
                            errorMessage = message(it.body, errorMessage);
                            const detailsInfo = details(it.body, null);
                            atlas.log(`${errorMessage} \n [Details] ${detailsInfo}\n`);
                        }

                        AP.flag.create({
                            title: 'External Share | ' + operationName,
                            body: errorMessage,
                            type: 'error',
                            close: 'manual'
                        });

                        if (!this.notClosableDialogOperations.includes(operationName)) {
                            AP.dialog.close();
                        }
                        this.loader.fullscreen(false);
                    }
                    if (reportErrorDisabled) {
                        return EMPTY;
                    }
                    throw it;
                }));
        }
        return next.handle(request);
    }
}

function isJsonResponse(head: HttpHeaders) {
    if (head.has('Content-Type')) {
        const contentType = head.get('Content-Type').toLowerCase();
        if (contentType.startsWith('application/json')) {
            return true;
        }
    }
    return false;
}

function message(body: any, defaultMessage: string): string {
    if (body && body.message) {
        return body.message;
    }
    return defaultMessage;
}

function details(body: any, defaultMessage: string): string {
    if (body && body.details) {
        return body.details;
    }
    return defaultMessage;
}
