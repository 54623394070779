import {Component, Input} from '@angular/core';
import {Share} from '../../base/share';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-share-link',
    templateUrl: './share-link.component.html'
})
export class ShareLinkComponent {
    href: string;
    name: string;
    description: string;

    private data: Share;
    private namedShare: boolean;

    @Input() limit: string;
    @Input() showDescriptionIcon = false;

    @Input() set share(share: Share) {
        this.data = share;
        this.update();
        this.description = share.description;
    }

    @Input() set showName(showName: boolean) {
        this.namedShare = showName;
        this.update();
    }

    private update() {
        this.href = this.data.contentUrl + (this.data.namedShareLink ? this.data.id + '/' + this.data.linkName : this.data.uuid);
        if (this.namedShare) {
            this.name = this.data.name;
        } else {
            this.name = this.href;
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }
}
