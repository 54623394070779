import {Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FilterCriteria, ServerDataSource} from './app-table.component';

interface SortInfo {
    key: string;
    sortOrder: string;
}

@Directive({
    selector: '[appTableSortHeader]'
})
export class TableSortHeaderDirective implements OnChanges {

    @Input('appTableSortHeader') public dataSource: ServerDataSource<any, FilterCriteria>;
    @Input() sortKey: string;
    @HostBinding('class.sort') sortClass = true;

    private sortAscVariable = false;
    @HostBinding('class.sort-asc') get sortAsc() {
        return this.sortAscVariable;
    }

    private sortDescVariable = false;
    @HostBinding('class.sort-desc') get sortDesc() {
        return this.sortDescVariable;
    }

    constructor(el: ElementRef) {
        const parent = (el.nativeElement as Element).parentElement;
        parent.addEventListener('click', (e) => this.onClick(e));
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {

        if (this.dataSource.sort.key === this.sortKey) {
            if (this.sortAscVariable === false && this.sortDescVariable === false) {
                this.sortAscVariable = true;
            } else if (this.sortAscVariable === true) {
                this.sortAscVariable = false;
                this.sortDescVariable = true;
            } else {
                this.sortAscVariable = true;
                this.sortDescVariable = false;
            }
        }

        this.dataSource.sortChange.next({
            key: this.sortKey,
            sortOrder: this.sortAscVariable ? 'asc' : 'desc'
        });

        event.preventDefault();
        event.stopPropagation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource.currentValue) {
            const dataSource = changes.dataSource.currentValue as ServerDataSource<any, FilterCriteria>;
            const update = sortInfo => {
                if (sortInfo.key === this.sortKey) {
                    this.sortAscVariable = sortInfo.sortOrder === 'asc';
                    this.sortDescVariable = !this.sortAscVariable;
                } else {
                    this.sortAscVariable = this.sortDescVariable = false;
                }
            };
            dataSource.sortChange.subscribe(update);
            update(dataSource.sort);
        }
    }
}
